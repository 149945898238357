import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Flex } from "antd-mobile";
import { Icon, Avatar } from "components/basic";
import { timestamp2time } from "utils/util";
import "./index.scss";
import LazyLoad from "react-lazyload";
import playIcon from "../../../common/assets/images/play.png";

const Article: React.FC<{
  article: DDHY.Article;
  type?: "recommend" | "default" | "one" | "three";
  hideBody?: boolean;
  hideAuthor?: boolean;
  purchased?: boolean; // 购买的文章， footer替换为购买时间
  collection?: boolean; // 收藏的文章， footer替换为收藏时间 + 文章价格
}> = ({ article, type, hideBody, hideAuthor, purchased, collection }) => {
  const history = useHistory();

  // 渲染文章内容
  var renderArticleBody = useCallback(() => {
    if (!article || hideBody) return null;
    let covers = article.article_image_all_url || [];

    // 处理视频文章
    if(article.article_type === 1 && article.video_image){
      return (
        <div className="article-card__bd">
          <div className="article-video" style={{
            background: `url(${article.video_image}) center center no-repeat`,
            backgroundSize: "cover",
          }}>
            <i style={{
              position:"absolute",
              top:"45%",
              left:"48%",
              color:"#fff",
            }}>
              <img src={playIcon} alt=""/>
            </i>
          </div>
        </div>
      );
    }

    // type: 文章类型
    // [recommend] 推荐: 不显示 article-card__bd 内容
    // [default]   默认: article-card__bd 内只显示副标题
    // [one]    单张封面: article-card__bd 内左侧显示副标题， 右侧显示封面图片
    // [three]  三张封面: article-card__bd 内只显示三张封面图片
    let _type = type;
    if (!_type) {
      _type =
        covers.length <= 0
          ? "default"
          : covers.length === 1 || covers.length === 2
          ? "one"
          : "three";
    }
    let content = article.article_title_short;
    // content = "有些观点认为，女性的阴蒂高潮和阴道高潮是不同的，但是也有一些观点认为，阴道高潮其实也是通过刺激阴蒂产生的，本质上还是阴蒂高潮。看到这里，你可"
    if (_type === "default") {
      return (
        <div className="article-card__bd">
          {article.article_essence_flag === 1 && (
          <section
            style={{
              display: "inline-block",
              height: "18px",
              width: "18px",
              lineHeight: "18px",
              textAlign: "center",
              fontSize: "14px",
              margin: "3px 5px 0 0",
              borderRadius: "2px",
              background: "red",
              color: "#fff",
            }}
          >
            精
          </section>)}
          <div className="article-default">{content}</div>
        </div>
      );
    } else if (_type === "one") {
      return (
        <div className="article-card__bd">
          <Flex className="article-one" align="start">
            <Flex.Item>
              <div className="left">
              {article.article_essence_flag === 1 && (
                <section
                  style={{
                    display: "inline-block",
                    height: "18px",
                    width: "18px",
                    lineHeight: "18px",
                    textAlign: "center",
                    fontSize: "14px",
                    margin: "3px 5px 0 0",
                    borderRadius: "2px",
                    background: "red",
                    color: "#fff",
                  }}
                >
                  精
                </section>
              )}
                {content}
              </div>
            </Flex.Item>
            <div className="right">
              <LazyLoad height={60}>
                <img src={covers[0]} alt="文章封面" style={{maxHeight:"60px"}} />
              </LazyLoad>
            </div>
          </Flex>
        </div>
      );
    } else if (_type === "three") {
      return (
        <div className="article-card__bd">
          <div className="article-three">
            <div className="content-wrap" style={{ width: "100%", color: "black" }}>
              {article.article_essence_flag === 1 && (
              <section
                style={{
                  display: "inline-block",
                  height: "18px",
                  width: "18px",
                  lineHeight: "18px",
                  textAlign: "center",
                  fontSize: "14px",
                  margin: "3px 5px 0 0",
                  borderRadius: "2px",
                  background: "red",
                  color: "#fff",
                }}
              >
                精
              </section>)}
              {content}
            </div>
            {/* 图片 */}
            <LazyLoad>
              <Flex className="image-wrap">
                {covers.map((item: any, index: number) => {
                  return (
                    <Flex.Item
                      key={`item${index}`}
                      className="image-item"
                      style={{
                        height: "4.5rem",
                        background: `url(${item}) center center no-repeat`,
                        backgroundSize: "cover",
                      }}
                      ></Flex.Item>
                  );
                })}
              </Flex>
            </LazyLoad>
          </div>

        </div>
      );
    }
    return null;
  }, [article, hideBody, type]);

  /**
   * 渲染文章底部
   */
  const renderFooter = useCallback(() => {
    if (purchased) {
      return (
        <div style={{ lineHeight: "24px", color: "#999" }}>
          <span>购买于：{timestamp2time(article.pay_time, "yyyy-MM-dd")}</span>
        </div>
      );
    } else if (collection) {
      return (
        <Flex justify="between" style={{ lineHeight: "24px", color: "#999" }}>
          <span>
            收藏于：{timestamp2time(article.collection_time, "yyyy-MM-dd")}
          </span>
          <span style={{ color: "red" }}>
            {article.article_is_free ? "" : "¥" + article.article_price}
          </span>
        </Flex>
      );
    } else {
      let viewCount = 0;
      if(article.article_click){
        viewCount = article.article_click;
      }
      if(article.content_view_num){
        viewCount = article.content_view_num;
      }

      let commentCount = 0;
      if(article.article_comment_count){
        commentCount = article.article_comment_count;
      }
      if(article.content_comment_num){
        commentCount = article.content_comment_num;
      }
      return (
        <Flex className="article-card__ft">
          <Flex.Item style={{
            marginRight:"10px",
          }}>
            <Flex className="click">
              <Flex.Item>
                <Icon size="16">eye</Icon>
                <span style={{ paddingLeft: "2px" }}>
                  {viewCount}
                </span>
              </Flex.Item>
              <Flex.Item>
                <Icon size="16">liuyan</Icon>
                <span style={{ paddingLeft: "2px" }}>
                  {commentCount}
                </span>
              </Flex.Item>
              <Flex.Item>
                <Icon size="16">zantong</Icon>
                <span style={{ paddingLeft: "2px" }}>
                  {article.content_love_num || 0}
                </span>
              </Flex.Item>
            </Flex>
          </Flex.Item>
          {article && article.article_is_free === 0 && (
            <div className="price">¥{article.article_price}</div>
          )}
          <div className="topic-name Text-Line__1" title={article.content_topic_name} onClick={(e) =>{
            e.stopPropagation();
            history.push(`/topic/${article.content_topic_id}`);
          }}>
            #{article.content_topic_name}
          </div>
        </Flex>
      );
    }
  }, [article, collection, history, purchased]);

  /**
   * 渲染文章标题
   */
  const renderTitle = useCallback(() => {
    if (!article) return null;
    let title = article.article_title;

    return (
      <div className="article-card__hd">
        <span>{title}</span>
      </div>
    );
  }, [article]);

  /**
   * 渲染作者信息
   */
  const renderAuthor = useCallback(() => {
    if (!article || !article.author_info || hideAuthor) return null;
    const author_info = article.author_info;
    return (
      <Flex className="article-doctor-info">
        <Avatar
          src={author_info.author_image}
          style={{ height: "20px", width: "20px" }}
        />
        <div className="name">{author_info.author_name}</div>
        <Flex.Item>
          <div className="hospital">{author_info.hospital_name}</div>
        </Flex.Item>
      </Flex>
    );
  }, [article, hideAuthor]);

  return useMemo(() => {
    if (!article) return null;
    return (
      <div
        className="article-card"
        onClick={() => {
          history.push(`/article/${article.article_id}`);
        }}
      >
        {renderTitle()}
        {renderAuthor()}
        {renderArticleBody()}
        {renderFooter()}
      </div>
    );
  }, [
    article,
    history,
    renderArticleBody,
    renderAuthor,
    renderFooter,
    renderTitle,
  ]);
};
export default Article;
