import service, { getResult } from "utils/request";
import { fileResizetoFile } from "utils/file";
import { Toast } from "antd-mobile";
const mainApi = {

  /**
   * 获取推荐主页内容列表
   * @param params
   * @returns
   */
   queryContentList(): Promise<any[]>{
    return getResult({
      url: `/v2/index/list`,
    });
  },

  /**
   * 获取帖子、文章列表数据
   * @param params
   * @returns
   */
  queryHomeContentList(params: {
    type: "follow" | "recommend" | "article" | "posts" | "topic" | "other" | "hot",
    page?: number;
    topic_id?: string | number;
    category_id?: string | number;
    timestamp?: number,
  }){
    return getResult({
      url: `/v2/community/list`,
      params
    });
  },

  // 获取兴趣标签
  getSysHobbyList() {
    return getResult({
      url: `/v2/member/hobby`,
    });
  },

  // 提交已选择的兴趣标签
  submitHobby(ids: string){
    return getResult({
      url: `/v2/member/hobby/add_hobby_member`,
      params: {
        hobby_ids: ids
      }
    })
  },

  /**
   * 获取广告内容
   */
  getAdvInfoByCode(code: string){
    return getResult({
      url: `/v2/adv/get_adv`,
      params: {
        code,
        use_place: 3,
      },
    });
  },

  /**
   * 获取平台分类
   * @returns
   */
  getPlatCateList(){
    return getResult({
      url: `/v2/cate`,
    });
  },

  /**
  * 获取标签树
  */
  getCateTree() {
    return getResult({
      url: `/v2/tagTree`,
    });
 },

  /**
   * 获取地区树
   */
  getRegionsTree() {
    return getResult({
      url: `/v2/area/tree`,
    });
  },

  /**
   * 获取有医生的地区树
   */
  getRegionsTreeWithDoctor() {
    return getResult({
      url: `/v2/hasDoctorPlace`,
    });
  },
  // 获取有医院的地区树
  getGloableRegionsTree() {
    return getResult({
      url: `/v2/hasHospitalPlace`,
    });
  },

  /**
   * 获取注册微信jssdk参数
   */
  jssdk(params: {
    url: string; // 当前页面url
  }) {
    return getResult({
      url:`/v2/access/get_js_config`,
      params,
    });
  },

  /**
   * 热门搜索词
   */
  topSearch() {
    return getResult({
      url: `/v2/search/hot_keyword`,
    });
  },

  /**
   * 搜索
   */
  search(params: {
    keywords?: string;
    type: "article" | "post" | "topic" | "reserve" | "doctor" | "hospital"; // 搜索类型 article:文章  topic:帖子  reserve:服务项目 doctor:医生
    page?: number;
    limit?: number;
  }) {
    let _url = `/v2/search`;
    return getResult({
      url: _url,
      params,
    });
  },

  // 分享积分处理
  shareGiftHandler(params : {
    custom_invite_code: string;
    type: 1|2;
    obj_id: number;
  }){
    return getResult({
      url: '/v2/share/get_point',
      params,
    });
  },

  /**
   *  上传文件
   * @param {File} file
   * {"msg":"上传成功","code":200,"result":"\/picture\/202107\/ddhaoyi_1627473398859.jpg"}
   */
  uploadFile: async function (file: File, field='file', url = `/up`) {
    if (file.size > 10 * 1024 * 1024) {
      Toast.info("单张图片不能超过10M");
      return;
    }
    try {
      const _file = await fileResizetoFile(file);
      let form = new FormData(); //创建form对象
      form.append(field, _file, _file.name);
      return service.post(url, form);
    } catch (error) {
      Toast.offline(error);
    }
  },

  // 用户头像上传
  memberAvatarUpload: async function(file: File) {
    let field = 'member_img';
    let url = `/v2/upload/avatar/member`;
    return this.uploadFile(file, field, url);
  },

  // 帖子图片上传
  postsImageUpload: async function(file: File) {
    let field = 'post_img';
    let url = `/v2/upload/post`;
    return this.uploadFile(file, field, url);
  },

  // 训练打卡图片上传
  trainingImageUpload: async function(file: File) {
    let field = 'note_img';
    let url = `/v2/upload/health`;
    return this.uploadFile(file, field, url);
  },

  // 咨询图片上传
  consultImageUpload: async function(file: File) {
    let field = 'ask_img';
    let url = `/v2/upload/ask`;
    return this.uploadFile(file, field, url);
  },

  // 预约图片上传
  reserveImageUpload: async function(file: File) {
    let field = 'reservation_img';
    let url = `/v2/upload/reservation`;
    return this.uploadFile(file, field, url);
  },

  // 医生头像上传
  doctorAvatarUpload: async function(file: File) {
    let field = 'doctor_img';
    let url = `/v2/upload/avatar/doctor`;
    return this.uploadFile(file, field, url);
  },

  // 获取AppInfo
  getAppInfo(os = 'android') {
    return getResult({
      url: `/v2/version`,
      params: {
        type: 'user',
        os
      },
    });
  }
};

export default mainApi;

