import DDHY from "ddhy";
import * as actionTypes from "./constants";

// 付费训练详情页当前索引
export const changeTabIndex = (data: number) => ({
  type: actionTypes.SET_TAB_INDEX,
  data: data,
});

// 付费训练详情
export const changeTraining = (data?: DDHY.Training) => ({
  type: actionTypes.SET_TRAINING,
  data: data,
});

// 付费训练详情页滚动
export const changeTrainingSlider = (data?: DDHY.SaleInfo[]) => ({
  type: actionTypes.SET_TRAINING_SLIDER,
  data: data,
});

// 付费训练详情页目录
export const changeTrainingItems = (data?: DDHY.TrainingItem[]) => ({
  type: actionTypes.SET_TRAINING_ITEMS,
  data: data,
});

// 付费训练详情页讨论
export const changeTrainingDiscuss = (data?: DDHY.NoteInfo[]) => ({
  type: actionTypes.SET_TRAINING_DISCUSS,
  data: data,
});
export const changeTrainingDiscussPage = (data: number) => ({
  type: actionTypes.SET_TRAINING_DISCUSS_PAGE,
  data: data,
});
export const changeTrainingDiscussHasMore = (data: boolean) => ({
  type: actionTypes.SET_TRAINING_DISCUSS_HASMORE,
  data: data,
});

/**
 * 清除付费训练详情页讨论数据
 */
export const cleanTrainingDiscuss = () => {
  return (d: any) => {
    d(changeTrainingDiscuss());
    d(changeTrainingDiscussPage(1));
    d(changeTrainingDiscussHasMore(true));
  };
};


/**
 * 清除付费训练详情页数据
 */
export const cleanTraining = () => {
  return (d: any) => {
    d(changeTabIndex(0));
    d(changeTraining());
    d(changeTrainingSlider([]));
    d(changeTrainingItems([]));
    d(cleanTrainingDiscuss());
  };
};
