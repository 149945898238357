import React, { useCallback } from "react";
import { Flex } from "antd-mobile";
import { Icon } from "components/basic";
// import { Icon, Avatar } from "components/basic";
import "./index.scss";
// import { richTextFilter, getImageUrl } from "utils/util";
import { getImageUrl } from "utils/util";
import { formatNumber } from "utils/number";
import { useHistory } from "react-router-dom";
import LazyLoad from "react-lazyload";

const TopicItem: React.FC<{
  topic: DDHY.SNSTopic;
  onClick?(topic: DDHY.SNSTopic): void;
}> = ({ topic, onClick }) => {
  const history = useHistory();
  const LabelWithIcon = (props: any) => {
    return (
      <Flex align="center" style={{ marginRight: 12 }}>
        <Icon style={{ fontSize: "1.4em", marginRight: 2 }}>{props.icon}</Icon>
        <span>{props.children}</span>
      </Flex>
    );
  };

  const renderFirstPost = useCallback(() => {
    if (topic.one_posts_info && topic.one_posts_info.user_info) {
      // const post = topic.one_posts_info;
      // const nickName = post.user_info.nick_name;
      // let content = "";
      // if (post.post_type === 3) {
      //   // let _content: DDHY.SharePost = post.post_content;
      //   // content = _content.service_description;
      // } else {
      //   // let _content: string = post.post_content;
      //   // content = richTextFilter(_content);
      // }
      return (
        <div className="Post-Info">
          {/* <div className="User-Info">
            <Avatar src={post.user_info.headimgurl} />
            <div className="User-Name">{nickName}：</div>
          </div>
          <div className="Post-Content Text-Line__1">{content}</div> */}
        </div>
      );
    }
    return null;
  }, [topic.one_posts_info]);

  return (
    <div
      className="Component-Topic"
      onClick={() => {
        if (onClick) {
          onClick(topic);
        } else {
          history.push(`/topic/${topic.topic_id}`);
        }
      }}
    >
      <Flex align="stretch">
        <div className="Topic-Image">
          <LazyLoad>
            <img src={getImageUrl(topic.topic_icon)} alt="" />
          </LazyLoad>
        </div>
        <Flex.Item>
          <Flex
            direction="column"
            justify="between"
            align="start"
            style={{ height: "100%" }}
          >
            <div className="Topic-Title">{topic.topic_name}</div>
            <Flex className="Topic-Footer" justify="start">
              <LabelWithIcon icon="liuyan">
                {topic.post_count}个回答
              </LabelWithIcon>
              {topic.hots_num && (
                <LabelWithIcon icon="activity">
                  {formatNumber(topic.hots_num)}热度
                </LabelWithIcon>
              )}
            </Flex>
          </Flex>
        </Flex.Item>
      </Flex>
      {renderFirstPost()}
    </div>
  );
};

export default TopicItem;
