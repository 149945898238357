import * as consts from "./constants";
import { MyOrderState } from "./reducer";
import orderApi, { QueryParams } from "api/order";
import { Toast } from "antd-mobile";

export const changeCurrentState = (data?: number) => ({
  type: consts.SET_CURRENT_STATE,
  data: data,
});
export const changeOrderList = (data?: DDHY.Order[]) => ({
  type: consts.SET_ORDER_LIST,
  data: data,
});
export const changePage = (data: number) => ({
  type: consts.SET_ORDER_PAGE,
  data: data,
});
export const changeHasMore = (data: boolean) => ({
  type: consts.SET_ORDER_HASMORE,
  data: data,
});

export const cleanMyOrder = () => {
  return (dispatch: any) => {
    dispatch(changeOrderList());
    dispatch(changePage(1));
    dispatch(changeHasMore(true));
  };
};

export const loadOrderList = (callback: Function) => {
  return async (dispatch: any, getState: any) => {
    const limit = 10;
    try {
      const state: MyOrderState = getState().getIn(["profile", "myOrder"]);
      const { currentState, orderList, page = 1 } = state;

      const queryParams: QueryParams = {
        page,
        limit,
        order_state: currentState,
      };
      const { data, current_page, last_page } = await orderApi.query(queryParams);
      if (Array.isArray(data)) {
        if (page === 1 || !orderList || orderList.length === 0) {
          dispatch(changeOrderList(data));
        } else {
          dispatch(changeOrderList([...orderList, ...data]));
        }
        dispatch(changeHasMore(current_page < last_page));
      } else {
        Toast.info("数据格式错误");
      }
    } catch (error) {
      Toast.info(error);
    } finally {
      callback();
    }
  };
};
