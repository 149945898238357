import { getResult } from "utils/request";
import { getInviteCodeFromCache } from "screens/Account/actions";

// 修改个人信息所需参数
export interface ModifyPersonalInfo {
  real_name?: string; // 用户姓名
  nick_name?: number; //	昵称
  headimgurl?: string; //	头像
  phone?: string; //	手机号
  idcard_code?: string; //	身份证号
  medicare_card_code?: string; //	医保卡号
  user_age?: string; // 年龄
  user_sex?: string; //	性别 用户性别 -1保密 1男 2女
  user_weight?: string; //	体重
  user_height?: string; //	身高
  is_married?: number; //	是否结婚 婚姻状况 -1保密 1未婚 2已婚
}

// 修改病史信息所需参数
export interface ModifyMedical {
  log_id?: number; // 主键 ID 如修改则传log_id
  allergy?: string; // 过敏信息
  contagion?: string; // 传染病历史
  diseases?: string; // 疾病信息
  operation?: string; // 手术历史
  living_habit?: string; // 生活习惯
  sex_mate?: string; // 性伴侣
  sex_activity?: string; // 性生活频率
  disease_area?: string; // 疾病区域
  genital_problem?: string; // 生殖器问题
  sex_problem?: string; // 性功能问题
}

const accountApi = {
  /**
   * 获取当前用户可用积分
   */
  getAvailablIntegral(): Promise<number> {
    return getResult({
      // url: `/api/uCenter/points`,
      url: `/v2/member/user_points`,
      needLogin: true,
    });
  },

  /**
   * 微信授权登陆
   * @param code 微信网页授权返回Code
   */
  wxAuth(code: string): Promise<DDHYAccount.AccountInfo> {
    // 医生邀请码
    const inviteCode = getInviteCodeFromCache();
    return getResult({
      url: `/v2/access/wx_login`,
      params: {
        code,
        invite_code: inviteCode,
      },
    });
  },

  /**
   * 使用token获取用户账号信息
   */
  getAccountInfoByToken(): Promise<DDHYAccount.AccountInfo> {
    return getResult({
      url: `/v2/member/info`,
    });
  },

  /**
   * 修改个人信息
   *
   * @param
   */
  update(params: ModifyPersonalInfo): Promise<void> {
    return getResult({
      needLogin: true,
      url: `/v2/member/info`,
      params,
      method: "post",
    });
  },

};

export default accountApi;
