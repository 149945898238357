import React, { memo } from "react";
import { dateFormat } from "../../../utils/util";
import "./index.scss";

const Notice: React.FC<{ data: any; onClick?(): void }> = (props) => {
  const { data, onClick } = props;

  const formatDate = (timestamp: number | string) => {
    let newTime = +new Date();
    let showTime;
    if (isSameDay(timestamp, newTime)) {
      showTime = "今天" + dateFormat(new Date(timestamp), "hh:mm");
    } else {
      showTime = dateFormat(new Date(timestamp), "yyyy/MM/dd");
    }
    return showTime;

    function isSameDay(day1: any, day2: any) {
      day1 = new Date(day1);
      day2 = new Date(day2);
      let isSame =
        day1.getFullYear() === day2.getFullYear() &&
        day1.getMonth() === day2.getMonth() &&
        day1.getDate() === day2.getDate();
      return isSame;
    }
  };

  return (
    <div className="Component-Notice" onClick={() => onClick && onClick()}>
      <div className={`Notice-Header ${data.is_read === 0 ? "UnRead" : ""}`}>
        <h4 className="Title Text-Line__1">{data.message_title}</h4>
      </div>
      <div className="Notice-Body">
        <p
          className="Text-Line__3 Pre-Text"
          dangerouslySetInnerHTML={{ __html: data.message_content }}
        />
      </div>
      <div className="Notice-Time">
        {formatDate(data.send_time ? data.send_time * 1000 : + new Date())}
      </div>
    </div>
  );
};

export default memo(Notice);
