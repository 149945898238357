import { lazy } from "react";

const Chat = lazy(() => import("./Chat"));
const CommentConsult = lazy(() => import("./CommentConsult"));
const ConsultDoctor = lazy(() => import("./ConsultDoctor"));
const OnLook = lazy(() => import("./OnLook"));

const routes: DDHY.Route[] = [
  {
    path: "/doctor/:id/consult",
    title: "咨询医生",
    component: ConsultDoctor,
    private: true,
  },

  {
    path: "/consult/:consult_id/chat",
    title: "咨询",
    component: Chat,
    private: true,
  },
  {
    path: "/consult/:consult_id/onlooker",
    title: "围观",
    component: OnLook,
    private: true,
  },
  {
    path: "/consult/:consult_id/comment",
    title: "评论",
    component: CommentConsult,
    private: true,
  },
];

export { routes };
