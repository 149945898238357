import * as actionTypes from "./constants";

export interface communityState {
  tabIndex: number;
  postList?: DDHY.SNSPost[];
  postPage?: number;
  postHasMore?: boolean;

  memberList?: DDHY.SNSUserInfo[];
  memberPage?: number;
  memberHasMore?: boolean;

  topicList?: DDHY.SNSTopic[];
  topicPage?: number;
  topicHasMore?: boolean;
}

const defaultState: communityState = {
  tabIndex: 0,
  postPage: 1,
  postHasMore: true,
  memberPage: 1,
  memberHasMore: true,
  topicPage: 1,
  topicHasMore: true,
};

export default (state = defaultState, action: any): communityState => {
  switch (action.type) {
    case actionTypes.SET_TAB_INDEX:
      return { ...state, tabIndex: action.data };

    // MyPost
    case actionTypes.SET_POST_LIST:
      return { ...state, postList: action.data };
    case actionTypes.SET_POST_PAGE:
      return { ...state, postPage: action.data };
    case actionTypes.SET_POST_HASMORE:
      return { ...state, postHasMore: action.data };

    // 关注的用户
    case actionTypes.SET_MEMBER_LIST:
      return { ...state, memberList: action.data };
    case actionTypes.SET_MEMBER_PAGE:
      return { ...state, memberPage: action.data };
    case actionTypes.SET_MEMBER_HASMORE:
      return { ...state, memberHasMore: action.data };

    // 关注的圈子
    case actionTypes.SET_TOPIC_LIST:
      return { ...state, topicList: action.data };
    case actionTypes.SET_TOPIC_PAGE:
      return { ...state, topicPage: action.data };
    case actionTypes.SET_TOPIC_HASMORE:
      return { ...state, topicHasMore: action.data };

    default:
      return state;
  }
};
