import { combineReducers } from "redux-immutable";
import { reducer as SquareReducer } from "../Square/store";
import { reducer as PostReducer } from "../Post/store";
import { reducer as TopicReducer } from "../Topic/store";
import { reducer as MemberReducer } from "../Member/store";

export default combineReducers({
  square: SquareReducer,
  post: PostReducer,
  topic: TopicReducer,
  member: MemberReducer,
});
