import * as actionTypes from "./constants";

export const changeAccountInfo = (data: DDHYAccount.AccountInfo) => ({
  type: actionTypes.SET_ACCOUNT_INFO,
  data: data,
});
export const changeCommunityAdmin = (data: boolean) => ({
  type: actionTypes.SET_COMMUNITY_ADMIN,
  data: data,
});
export const changeCommunityBanned = (data: boolean) => ({
  type: actionTypes.SET_COMMUNITY_BANNED,
  data: data,
});
