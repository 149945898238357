// HospitallRule
export const SET_USE_STATE = "hospital/SET_USE_STATE";
export const SET_HOSPIATLS = "hospital/SET_HOSPIATLS";
export const SET_PAGE = "hospital/SET_PAGE";
export const SET_HASMORE = "hospital/SET_HASMORE";
export const SET_RECOMMEND = "hospital/SET_RECOMMEND";
export const SET_SKILLS = "hospital/SET_SKILLS";
export const SET_REGIONS = "hospital/SET_REGIONS";
export const SET_FILTER = "hospital/SET_FILTER";

