import * as actionTypes from "./constants";
import Account from "../../../typings/account";
import { setToken } from "../../../utils/storage";

export interface AccountState {
  accountInfo?: Account.AccountInfo; // 账号信息
  communityAdmin: boolean; // 是否为社区管理员
  communityBanned: boolean; // 社区禁言状态
}

const defaultState: AccountState = {
  accountInfo: undefined,
  communityAdmin: false,
  communityBanned: false,
};

export default (state = defaultState, action: any): AccountState => {
  switch (action.type) {
    /**
     * 更新用户信息并更新相关状态
     *
     * * 如果有token则更新token信息
     * * 更新当前用户的社区管理员状态信息
     * * 更新当前用户的社区禁言状态信息
     */
    case actionTypes.SET_ACCOUNT_INFO:
      const accountInfo: DDHYAccount.AccountInfo = action.data;
      if (accountInfo) {
        const isAdmin = accountInfo.sns_manager === 1;
        const isBanned = accountInfo.sns_banned === 1;
        if (accountInfo.token) setToken(accountInfo.token);
        return {
          ...state,
          accountInfo: action.data,
          communityAdmin: isAdmin,
          communityBanned: isBanned,
        };
      }
      return state;
    case actionTypes.SET_COMMUNITY_ADMIN:
      return { ...state, communityAdmin: action.data };
    case actionTypes.SET_COMMUNITY_BANNED:
      return { ...state, communityBanned: action.data };
    default:
      return state;
  }
};
