import React from "react";
import { getImageUrl } from "../../../utils/util";
import "./index.scss";

function handleLinkUrl(url: string): string {
  if (url) {
    var reg = RegExp(/^(http|https)/);
    if (reg.test(url)) {
      return url;
    }
    return `https://${url}`;
  }
  return "";
}

const Goods: React.FC<{
  data: any;
}> = ({ data }) => {
  if (data) {
    return (
      <div
        className="Component-Goods"
        onClick={() => {
          if (data.goods_url) {
            window.location.href = handleLinkUrl(data.goods_url);
          }
        }}
      >
        <div className="Cover">
          <img src={getImageUrl(data.goods_images)} alt="" />
        </div>
        <div className="Name">{data.goods_title}</div>
        <div className="Price">¥{data.goods_price}</div>
      </div>
    );
  }
  return null;
};

export default Goods;
