import React, { memo, useCallback, useMemo } from "react";
import { Flex } from "antd-mobile";
import { useHistory } from "react-router-dom";
import "./index.scss";
import { timestamp2time } from "utils/util";

/**
 * 订单列表项
 */
const Order: React.FC<{ order: DDHY.Order }> = ({ order }) => {
  const history = useHistory();

  const goods = order.goods_info;
  // 订单状态：0(已取消) 10(默认):未付款; 20:已完成;
  const state = order.order_state;

  // 订单类型 1咨询 2咨询对话次数 3围观 4预约 5预约对话次数 6文章 7专题 8训练
  const type = order.order_type;

  // 订单类型 1咨询 2咨询对话次数 3围观 4预约 5预约对话次数 6文章 7专题  8训练课程
  const renderGoodsType = useCallback(() => {
    if (!type) return null;
    const map: { [key: number]: string } = {
      1: "咨询",
      3: "围观",
      4: "预约",
      5: "次数",
      6: "文章",
      7: "专题",
      8: "训练",
      10: "课程",
      12: "会员",
    };
    return <div className="Goods-Type">{map[type]}</div>;
  }, [type]);


  const renderOrderStatus = useCallback(() => {
    let orderState = <span>已取消</span>;
    switch (state) {
      case 1:
        orderState = <span className="red">待付款</span>;
        break;
      case 2:
        orderState = <span className="green">进行中</span>;
        break;
      case 3:
        orderState = <span>已完成</span>;
        break;
    }
    return orderState;
  }, [state]);

  return useMemo(() => {
    if (!order) return null;
    return (
      <div className="Component-Order" onClick={() => {
        if ((type === 8 || type === 10) && state === 1) {
          let paramStr = `order_id=${order.order_id}&training_id=${goods.goods_id}`;
          if(goods.version_id){
            paramStr += `&sku_id=${goods.version_id}`;
          }
          history.push(`/training/order?${paramStr}`);
        }else if(type === 12){
          let paramStr = `order_id=${order.order_id}&id=${goods.goods_id}`;
          history.push(`/vip/order?${paramStr}`);
        } else {
          history.push("/order/" + order.order_id);
        }
      }}>
        {/* 订单顶部 */}
        <Flex className="Order-Title">
          <Flex.Item>
            {renderGoodsType()}
          </Flex.Item>
          <div className="Order-Status">
            <span>{timestamp2time(order.add_time, 'yyyy-MM-dd')} &nbsp; | &nbsp;</span>
            {renderOrderStatus()}
          </div>
        </Flex>

        {/* 订单主体 */}
        <div className="Order-Body">
          <Flex>
            <div className="label">名称</div>
            <Flex.Item>{goods.goods_name}</Flex.Item>
          </Flex>
          <Flex>
            <div className="label">价格</div>
            <Flex.Item className="Goods-Price">{goods.goods_price}</Flex.Item>
          </Flex>
        </div>

        {/* 订单底部 */}
        <Flex className="Order-Footer">
          <Flex.Item>&nbsp;</Flex.Item>
          <div style={{
            border: '1px solid #666',
            borderRadius: '20px',
            fontSize: '13px',
            padding: '2px 8px',
          }}>
            查看详情
          </div>
        </Flex>
      </div>
    );
  }, [order, renderGoodsType, renderOrderStatus, goods, type, state, history]);
};

export default memo(Order);
