import { useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { getContainerMetrics } from "../utils/browser";

/**
 * 页面缓存缓存数据
 */
interface CacheData {
  scrollTop: number; // 滚动高度
}

/**
 * 保存页面缓存数据到sessionStorage
 *
 * @param page 页面
 * @param data 数据
 */
function setPageCacheData(page: string, data: CacheData) {
  sessionStorage.setItem(page, JSON.stringify(data));
}

/**
 * 获取页面的缓存数据
 *
 * @param page
 */
function getPageCacheData(page?: string): CacheData | null {
  if (!page) return null;
  const dataStr = sessionStorage.getItem(page);
  if (dataStr) return JSON.parse(dataStr);
  return null;
}

/**
 * 页面缓存
 *
 * * 存储/更新页面的缓存内容
 */
export default function usePageCache(key?: string) {
  const { pathname } = useLocation();

  useEffect(() => {
    // 如果未传入Key则使用当前路由path作为key
    const pageKey = key || pathname;
    let pageCacheData = getPageCacheData(pageKey);
    setTimeout(() => {
      if (pageCacheData) {
        window.scrollTo({ top: pageCacheData.scrollTop });
      } else {
        window.scrollTo({ top: 0 });
      }
    });
  }, [key, pathname]);

  // 滚动结束后更新当前页面缓存数据信息中的scrollTop
  const cachePage = useCallback(() => {
    const pageKey = key || pathname;
    const metrics = getContainerMetrics();
    const newPageCacheData: CacheData = { scrollTop: metrics.topOffset };
    setPageCacheData(pageKey, newPageCacheData);
  }, [key, pathname]);

  useEffect(() => {
    return () => {
      cachePage();
    };
  }, [cachePage]);
}
