import * as actionTypes from "./constants";
import { Toast } from "antd-mobile";
import memberApi from "api/member";
import { PostState } from "./reducer";
import postApi from "api/post";
import { handleResponseError } from "utils/request";

export const changeDetails = (data?: DDHY.SNSPostDetails) => ({
  type: actionTypes.SET_DETAILS,
  data: data,
});
export const changeComments = (data?: Array<DDHY.SNSPostComment>) => ({
  type: actionTypes.SET_COMMENTS,
  data: data,
});
export const changePage = (data: number) => ({
  type: actionTypes.SET_PAGE,
  data: data,
});
export const changeHasMore = (data: boolean) => ({
  type: actionTypes.SET_HASMORE,
  data: data,
});
/**
 * 清除帖子详细页面数据
 */
export const cleanPost = () => {
  return (dispatch: any) => {
    dispatch(changeDetails());
    dispatch(changeComments());
    dispatch(changePage(1));
    dispatch(changeHasMore(true));
  };
};

/**
 * 初始化帖子详情页数据
 * @param id 帖子ID
 */
export const initPost = (id: number, callback?: Function) => {
  return async (dispatch: any) => {
    try {
      const data = await postApi.getPostDetails(id);
      dispatch(changeDetails(data));
      callback && callback();
    } catch (error) {
      callback && callback(error);
    } finally {
      setTimeout(() => {
        dispatch(loadComments());
      });
    }
  };
};

/**
 * /post
 * 加载帖子评论
 */
export const loadComments = (callback?: Function) => {
  return async (dispatch: any, getState: any) => {
    const state: PostState = getState().getIn(["community", "post"]);
    const { details, comments, page } = state;

    const limit = 10;
    if (!details) {
      throw Error("loadComments Error: post is undefined");
    }
    try {
      const { data } = await postApi.getComments({
        posts_id: details.post_id,
        page,
        limit,
      });

      if (page === 1 || !comments) {
        dispatch(changeComments(data));
      } else {
        dispatch(changeComments([...comments, ...data]));
      }
      dispatch(changeHasMore(data.length === limit));
    } catch (error) {
      Toast.info(error);
    } finally {
      callback && callback();
    }
  };
};

/**
 * 重新加载帖子评论列表
 */
export const reloadComments = () => {
  return (dispatch: any) => {
    dispatch(changePage(1));
    dispatch(changeComments());
    setTimeout(() => {
      dispatch(loadComments());
    });
  };
};

/**
 * /post
 * 关注用户
 */
export const handleSubscriptionUser = () => {
  return (dispatch: any, getState: any) => {
    const post = getState().getIn(["community", "post", "details"]);
    if (!post) {
      throw Error("handleSubscriptionUser Error: undefined postDetails");
    }
    memberApi
      .operation(post.user_id, "subscribe")
      .then(() => {
        Toast.success("操作成功", 2, undefined, false);
        const user_info = post.user_info;
        const newUserInfo = {
          ...user_info,
          is_subscribe_user: user_info.is_subscribe_user === 1 ? 0 : 1,
        };
        const newPost = {
          ...post,
          user_info: newUserInfo,
        };
        dispatch(changeDetails(newPost));
      })
      .catch((error) => {
        handleResponseError(error, "error");
      });
  };
};
