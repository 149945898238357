import React, { memo, useCallback, useMemo } from "react";
import { Flex } from "antd-mobile";
import { Icon, Avatar } from "components/basic";
import { richTextFilter, timeInference } from "utils/util";
import { handleShowContent } from "screens/Community/Post/action";
import { useHistory } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { getImageUrl } from "utils/util";
import "./index.scss";

interface PostProps {
  post: DDHY.SNSPost;
  hideTitle?: boolean; // 隐藏圈子标题
  hideAuthor?: boolean; // 隐藏作者信息
  hideTime?: boolean; // 隐藏时间显示
  onBanned?(member: DDHY.SNSUserInfo): void;
  onDelete?(post: DDHY.SNSPost): void;
  onClick?(post: DDHY.SNSPost): void;
}

/**
 * 帖子列表项
 */
const Post: React.FC<PostProps> = (props) => {
  const { post, hideTitle, hideAuthor, hideTime } = props;
  const { onBanned, onDelete, onClick } = props;
  const history = useHistory();

  /**
   * 渲染顶部标题
   */
  const renderTitleBlock = useCallback(() => {
    return (
      <>
        {!hideTitle && <div className="Topic">
          {post.post_type === 2 && post.record_info ? post.record_info.item_name : post.post_title }
        </div>}
      </>
    )
  }, [post, hideTitle]);

  // 渲染作者信息
  const renderAuthor = useCallback(() => {
    if (!post || !post.user_info || hideAuthor) return null;
    let user = post.user_info;
    let post_time = post.content_uptime ? post.content_uptime : post.post_uptime;
    return (
      <Flex className="UserInfo">
        <Avatar src={user.member_avatar} style={{ width: 20, height: 20 }} />
        <div className="Nickname">{user.nick_name}</div>
        {user.level_info && (
          <div className="member-level">{user.level_info.level_name}</div>
        )}
        {!hideTime && <Flex.Item><div style={{textAlign: "right"}}>{timeInference(post_time)}</div></Flex.Item>}
      </Flex>
    );
  }, [hideAuthor, hideTime, post]);

  // 渲染帖子内容
  const renderContent = useCallback(() => {
    if (!post) return null;
    let isTop = post.is_top === 1;
    const content = handleShowContent(post);
    const showImages = post.normal_post_imgs; // 可展示的图片列表
    if (showImages && showImages.length > 0) {
      const image = getImageUrl(showImages[0].post_image);
      return (
        <Flex align="start">
          <Flex.Item>
            <div className="Text-Line__3 content">
              {isTop ? (
                <div
                  style={{
                    display: "inline-block",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  <Icon size="20" color="#0099ff">
                    top
                  </Icon>
                </div>
              ) : null}
              {content}
            </div>
          </Flex.Item>
          <div
            style={{
              height: 60,
              width: 80,
              overflow: "hidden",
              marginLeft: 6,
            }}
          >
            <LazyLoad height={60}>
              <img
                src={image}
                alt=""
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </LazyLoad>
          </div>
        </Flex>
      );
    } else {
      const hasSensitiveImage = post.post_imgs; // 帖子内是否有敏感图片
      return (
        <div className="Text-Line__3 content">
          {isTop ? (
            <div
              style={{
                display: "inline-block",
                position: "relative",
                top: "2px",
              }}
            >
              <Icon size="20" color="#0099ff">
                top
              </Icon>
            </div>
          ) : null}
          {hasSensitiveImage && (
            <>
            <Icon
              size={16}
              color="#0099ff"
              style={{ left: 2, position: "relative", top: "1px" }}
            >
              picture
            </Icon>
            &nbsp;&nbsp;
            </>
          )}
          {content}
        </div>
      );
    }
  }, [post]);

  // 帖子最新评论 || 训练信息
  const renderComments = useCallback(() => {
    if (!post) return null;
    let showTrain = false;
    if(post.active_info){
      showTrain = true;
    }else{
      if (!post || !post.comment_list || post.comment_list.length <= 0){
        return null;
      }
    }


    // 渲染评论块
    function renderCommentBlock(comment_list){
      return (
        <div className="Comments">
        {comment_list.map((item, index) => {
          return (
            <div
              key={index}
              className="Comment"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <span className="Nickname">{item.user_info.nick_name}</span>
              <span>：</span>
              <span className="Comment__Body">
                {richTextFilter(item.comment_content)}
              </span>
            </div>
          );
        })}
      </div>
      );
    }

    // 渲染训练块
    function renderTrainingBlock(active_info){
      return (
        <Flex className="training-info" onClick={(e) =>{
          e.stopPropagation();
          history.push(`/training/${active_info.active_id}`);
        }}>
          <div className="training-img" style={{
            backgroundImage: `url(${active_info.detail_image})`,
          }}></div>
          <Flex.Item>
            <div>{active_info.active_name}</div>
            <div className="study-num">{active_info.join_member_num}人在学习</div>
          </Flex.Item>
        </Flex>
      );
    }

    return (
      <div className="Comment-Wrap">
        {showTrain ? renderTrainingBlock(post.active_info): renderCommentBlock(post.comment_list)}
      </div>
    );
  }, [history, post]);

  // 渲染帖子状态栏
   const renderStateBar = useCallback(() => {
    if (!post) return null;
    let topic_id = post.content_topic_id ? post.content_topic_id : post.topic_id;
    let topic_name = post.content_topic_name ? post.content_topic_name : post.topic_name;
    return (
      <Flex className="StateBar" align="center" justify="center">
        <Flex.Item>
          <Flex className="click">
            <Flex.Item>
              <Icon size="16">eye</Icon>
              <span style={{ paddingLeft: "2px" }}>
                {post.content_view_num || 0}
              </span>
            </Flex.Item>
            <Flex.Item>
              <Icon size="16">liuyan</Icon>
              <span style={{ paddingLeft: "2px" }}>
                {post.content_comment_num || 0}
              </span>
            </Flex.Item>
            <Flex.Item>
              <Icon size="16">zantong</Icon>
              <span style={{ paddingLeft: "2px" }}>
                {post.content_love_num || 0}
              </span>
            </Flex.Item>
          </Flex>
        </Flex.Item>
        <div className="topic-name Text-Line__1" title={topic_name} onClick={(e) =>{
          e.stopPropagation();
          history.push(`/topic/${topic_id}`);
        }}>
          #{topic_name}
        </div>

        {/* 管理员操作 */}
        <Flex>
          {/* 禁言发帖人 */}
          {onBanned && (
            <Flex
              style={{ padding: "0 5px" }}
              align="center"
              onClick={(e) => {
                e.stopPropagation();
                onBanned(post.user_info);
              }}
            >
              <Icon
                size="18"
                color={post.user_info.is_banned === 1 ? "#0099ff" : "#999"}
              >
                micDisable
              </Icon>
            </Flex>
          )}

          {/* 删除帖子 */}
          {onDelete && (
            <Flex
              style={{ padding: "0 5px" }}
              align="center"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(post);
              }}
            >
              <Icon size="18">empty_fill</Icon>
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  }, [history, onBanned, onDelete, post]);

  const classNames =
    post && post.is_essence === 1 ? "Component-Post elite" : "Component-Post";
  return useMemo(
    () =>
      post ? (
        <div
          className={classNames}
          onClick={() => {
            if (onClick) {
              onClick(post);
            } else {
              history.push(`/post/${post.post_id}`);
            }
          }}
        >
          {renderTitleBlock()}
          {renderAuthor()}
          {renderContent()}
          {/* 最新评论 || 训练信息 */}
          {renderComments()}
          {renderStateBar()}
        </div>
      ) : null,
    [classNames, history, onClick, post, renderAuthor, renderComments, renderContent, renderStateBar, renderTitleBlock]
  );
};

export default memo(Post);
