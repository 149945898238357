import { lazy } from "react";

const Register = lazy(() => import("./Register"));
const RegisterSuccess = lazy(() => import("./RegisterSuccess"));
const AddHospital = lazy(() => import("./AddHospital"));
const DoctorProfile = lazy(() => import("./DoctorProfile"));
const DownloadAPP = lazy(() => import("./DownloadAPP"));
const routes: DDHY.Route[] = [
  {
    path: "/register",
    title: "账号注册",
    component: Register,
  },
  {
    path: "/register/success",
    title: "账号注册",
    component: RegisterSuccess,
  },
  {
    path: "/register/add-hospital",
    title: "账号注册",
    component: AddHospital,
  },
  {
    path: "/register/doctor",
    title: "账号注册",
    component: DoctorProfile,
  },
  {
    path: "/register/downloadapp",
    title: "账号注册",
    component: DownloadAPP,
  },
];

export { routes };
