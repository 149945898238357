import * as actionTypes from "./constants";
let location: any = localStorage.getItem("location");
if (location) {
  location = JSON.parse(location);
}
const defaultState = {
  // Register
  verifyInterval: 60, //见证吗发送间隔
  countDown: 0, // 获取验证码倒计时

  // Hospitals
  hospitals: [],
  hospitalsLoading: false,
  hospitalsHasMore: true,
  hospitalsPage: 1,
  location,
  regions: undefined,
  regionValue: undefined
};

export default (state = defaultState, action: any) => {
  switch (action.type) {
    // Register
    case actionTypes.SET_COUNT_DOWN:
      return { ...state, countDown: action.data };

    // Hospitals
    case actionTypes.SET_HOSPITALS:
      return { ...state, hospitals: action.data };
    case actionTypes.SET_HOSPITALS_LOADING:
      return { ...state, hospitalsLoading: action.data };
    case actionTypes.SET_HOSPITALS_HASMORE:
      return { ...state, hospitalsHasMore: action.data };
    case actionTypes.SET_HOSPITALS_PAGE:
      return { ...state, hospitalsPage: action.data };
    case actionTypes.SET_REGIONS:
      return { ...state, regions: action.data };
    case actionTypes.SET_REGION_VALUE:
      return { ...state, regionValue: action.data };
    case actionTypes.SET_LOCATION:
      return { ...state, location: action.data };

    default:
      return state;
  }
};
