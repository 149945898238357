import React from "react";
import Loading from "../Animation/Loading";
import "./index.scss";

/**
 * 进场动画页面
 *
 * @param props
 */
const EnterLoading: React.FC = (props) => {
  return (
    <div className="Component-EnterLoading">
      <Loading />
    </div>
  );
};

export default EnterLoading;
