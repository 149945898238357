import { getResult } from "utils/request";
import { getInviteCodeFromCache } from "screens/Account/actions";

/**
 * 获取图文验证码
 * @param phoneNumber
 * @param type
 * @returns
 */
function fetchVerifyCodeImg() {
  return getResult({
    url: `/v2/verify/code_img`,
  });
}

/**
 * 获取手机验证码
 *
 * @param phoneNumber
 * @param type 验证码类型
 */
function fetchVerifyCode(
  phoneNumber: string,
  code: string,
  key: string,
  type: "login" | "doctor_register" | "mobile_bind"
): Promise<any> {
  return getResult({
    //url: `/verify/sms_captcha`,
    url: `/v2/verify/create`,
    params: {
      verify_type: type,
      code,
      key,
      phone_num: phoneNumber,
    },
    method: "post",
  });
}

/**
 * 校验验证码有效性
 *
 * @param phoneNumber
 * @param verityCode
 */
function verifyCode(phoneNumber: string, verityCode: string): Promise<string> {
  return getResult({
    //url: `/verify/check_sms_captcha`,
    url: `/v2/verify/check`,
    data: {
      verify_code: verityCode,
      phone_num: phoneNumber,
    },
    method: "post",
  });
}

/**
 * 通过手机号码登陆
 *
 * @param phoneNumber 手机号码
 * @param verityCode 验证码
 */
function loginByVerifyCode(phoneNumber: string, verityCode: string) {
  // 获取邀请码
  const inviteCode = getInviteCodeFromCache() || undefined;

  return getResult({
    // url: `/verify/check_sms_captcha`,
    url: `/v2/verify/check`,
    data: {
      verify_code: verityCode,
      phone_num: phoneNumber,
      verify_type: "login",
      invite_code: inviteCode,
    },
    method: "post",
  });
}
const verifyApi = {
  fetchVerifyCodeImg,
  fetchVerifyCode,
  verifyCode,
  loginByVerifyCode,
};
export default verifyApi;
