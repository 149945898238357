import React, { useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { Modal, Flex, Button, List, Checkbox } from "antd-mobile";
import "./index.scss";
import { Icon } from "../../basic";
const COMPONENT_ID = "MultipleChoice";

interface MultipleChoiceOperation {
  title?: string;
  data: any[]; // 选项内容
  defaultValue?: string; // 默认选中项
  onComplete?: Function; // 完成回调
}
interface MultipleProps
  extends React.FC<{ operation: MultipleChoiceOperation }> {
  create(operation: MultipleChoiceOperation): void;
  destory(): void;
}
/**
 * 全局多选组件
 */
const MultipleChoice: MultipleProps = props => {
  const { operation } = props;
  const { data, defaultValue, title, onComplete } = operation;
  const [show, setShow] = React.useState(true);
  const [value, setValue] = useState(() =>
    defaultValue ? defaultValue.split(",") : []
  );

  const destroy = () => {
    setTimeout(() => {
      const div = document.getElementById(COMPONENT_ID);
      if (div) {
        ReactDOM.unmountComponentAtNode(div);
        document.body.removeChild(div);
      }
    }, 300);
  };
  const handleCancle = () => {
    setShow(false);
    destroy();
  };
  const handleComplete = () => {
    setShow(false);
    onComplete && onComplete(value);
    destroy();
  };

  const handleValueChange = useCallback(
    (v: any) => {
      const index = value.findIndex(val => val === v);
      if (index >= 0) {
        value.splice(index, 1);
      } else {
        value.push(v);
      }
      setValue([...value]);
    },
    [value]
  );
  const handleInputValueChange = useCallback(
    (label: string, values: any[]) => {
      const index = value.findIndex(v => v.includes(label));
      if (index >= 0) {
        if (values.length > 0) {
          value.splice(index, 1);
          value.push(`${label}:${values.join("、")}`);
        } else {
          value.splice(index, 1);
        }
      } else {
        value.push(`${label}:${values.join("、")}`);
      }
      setValue([...value]);
    },
    [value]
  );

  return (
    <Modal
      popup
      visible={show}
      onClose={() => setShow(false)}
      animationType="slide-up"
    >
      <div className={`GlobalComponent-${COMPONENT_ID}`}>
        <List className="Custom-List" renderHeader={title}>
          {data.map(i => {
            if (i.input) {
              const currentValue = value.find(v => v.includes(i.label));
              let valuesStr: string | undefined = currentValue
                ? currentValue.split(":")[1]
                : undefined;
              let values = valuesStr ? valuesStr.split("、") : [];

              return (
                <Checkbox.CheckboxItem
                  checked={currentValue ? true : false}
                  key={i.value}
                >
                  <Flex align="center" justify="between">
                    <div className="CheckLabel">{i.label}</div>
                    <Button
                      size="small"
                      type="primary"
                      icon={
                        <div>
                          <Icon size="16">jiahao</Icon>
                        </div>
                      }
                      style={{ zIndex: 20 }}
                      onClick={e => {
                        e.preventDefault();
                        Modal.prompt(`添加${i.label}`, "", [
                          { text: "取消" },
                          {
                            text: "确定",
                            onPress: value => {
                              if (value) {
                                values.push(value);
                                handleInputValueChange(i.label, values);
                              }
                            }
                          }
                        ]);
                      }}
                    >
                      添加
                    </Button>
                  </Flex>

                  <Flex wrap="wrap" style={{ paddingTop: "8px" }}>
                    {values.length > 0 &&
                      values.map((item: string, index: number) => {
                        if (item) {
                          return (
                            <Tag
                              key={`tag-${index}`}
                              onClose={() => {
                                const index = values.findIndex(
                                  (v: string) => item === v
                                );
                                values.splice(index, 1);
                                handleInputValueChange(i.label, values);
                              }}
                            >
                              {item}
                            </Tag>
                          );
                        }
                        return null;
                      })}
                  </Flex>
                </Checkbox.CheckboxItem>
              );
            } else {
              const checked = value.includes(i.label);
              return (
                <Checkbox.CheckboxItem
                  key={i.value}
                  checked={checked}
                  onChange={() => handleValueChange(i.label)}
                >
                  {i.label}
                </Checkbox.CheckboxItem>
              );
            }
          })}
        </List>
        <Flex align="end" justify="end" className="Actions">
          <Button size="small" type="ghost" onClick={() => handleCancle()}>
            取消
          </Button>
          <Button
            size="small"
            type="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => handleComplete()}
          >
            完成
          </Button>
        </Flex>
      </div>
    </Modal>
  );
};

interface TagProps {
  children: any;
  onClose(): void;
}
const Tag: React.FC<TagProps> = props => {
  const { children, onClose } = props;
  return (
    <div className="Tag">
      <div
        className="Tag-Close"
        onClick={() => {
          onClose();
        }}
      >
        <Icon size="14" color="#bbb">
          guanbi1
        </Icon>
      </div>
      {children}
    </div>
  );
};

MultipleChoice.create = (operation: MultipleChoiceOperation) => {
  let div = document.createElement("div");
  div.id = COMPONENT_ID;
  document.body.appendChild(div);
  let instance = ReactDOM.render(
    React.createElement(MultipleChoice, { operation }),
    div
  );
  return {
    instance,
    destroy() {
      ReactDOM.unmountComponentAtNode(div);
      document.body.removeChild(div);
    }
  };
};

MultipleChoice.destory = () => {
  const div = document.getElementById(COMPONENT_ID);
  if (div) {
    ReactDOM.unmountComponentAtNode(div);
    document.body.removeChild(div);
  }
};

export default MultipleChoice;
