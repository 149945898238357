import * as actionTypes from "./constants";

export interface AuthorState {
  authorDetails?: any;
  articles?: Array<DDHY.Article>;
  articlesPage: number;
  articlesHasMore: boolean;
}

const defaultState: AuthorState = {
  articlesPage: 1,
  articlesHasMore: true,
};

export default (state = defaultState, action: any): AuthorState => {
  switch (action.type) {
    case actionTypes.SET_DETAILS:
      return { ...state, authorDetails: action.data };
    case actionTypes.SET_ARTICLES:
      return { ...state, articles: action.data };
    case actionTypes.SET_PAGE:
      return { ...state, articlesPage: action.data };
    case actionTypes.SET_HASMORE:
      return { ...state, articlesHasMore: action.data };

    default:
      return state;
  }
};
