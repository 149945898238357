import * as actionTypes from "./constants";

// 我收藏的专栏与文章
export interface MyCollectedState {
  specialList?: DDHY.Special[];
  articleList?: DDHY.Article[];
  articlePage?: number;
  articleHasMore?: boolean;
}

// 我购买的专栏与文章
export interface MyPurchasedState {
  specialList?: DDHY.Special[];
  articleList?: DDHY.Article[];
  articlePage?: number;
  articleHasMore?: boolean;
}
export interface MyReadState {
  tabIndex: number;
  collected: MyCollectedState;
  purchased: MyPurchasedState;
}

const defaultState: MyReadState = {
  tabIndex: 0,
  collected: {
    articlePage: 1,
    articleHasMore: true,
  },
  purchased: {
    articlePage: 1,
    articleHasMore: true,
  },
};

export default (state = defaultState, action: any): MyReadState => {
  switch (action.type) {
    case actionTypes.SET_TAB_INDEX:
      return { ...state, tabIndex: action.data };

    case actionTypes.SET_COLLECTED_SPECIAL_LIST:
      return {
        ...state,
        collected: { ...state.collected, specialList: action.data },
      };
    case actionTypes.SET_COLLECTED_ARTICLE_LIST:
      return {
        ...state,
        collected: { ...state.collected, articleList: action.data },
      };
    case actionTypes.SET_COLLECTED_ARTICLE_PAGE:
      return {
        ...state,
        collected: { ...state.collected, articlePage: action.data },
      };
    case actionTypes.SET_COLLECTED_ARTICLE_HASMORE:
      return {
        ...state,
        collected: { ...state.collected, articleHasMore: action.data },
      };

    // MyPurchased
    case actionTypes.SET_PURCHASED_SPECIAL_LIST:
      return {
        ...state,
        purchased: { ...state.purchased, specialList: action.data },
      };
    case actionTypes.SET_PURCHASED_ARTICLE_LIST:
      return {
        ...state,
        purchased: { ...state.purchased, articleList: action.data },
      };
    case actionTypes.SET_PURCHASED_ARTICLE_PAGE:
      return {
        ...state,
        purchased: { ...state.purchased, articlePage: action.data },
      };
    case actionTypes.SET_PURCHASED_ARTICLE_HASMORE:
      return {
        ...state,
        purchased: { ...state.purchased, articleHasMore: action.data },
      };
    default:
      return state;
  }
};
