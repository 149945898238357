import React from "react";
import "./index.scss";

const Comparison: any = {
  0: "middle",
  1: "right",
};

class Slide extends React.Component<{
  height?: number; // 组件高度，需要根据内容高度来设定，默认246px
  data: any[];
  renderItem(item: any, index: any): React.ReactElement<any>;
}> {
  state: any;
  startX: number = 0;
  startY: number = 0;
  endX: number = 0;
  threshold = 80; // 滑动切换的阈值
  rootRef?: React.RefObject<HTMLDivElement> = undefined;
  constructor(props: any) {
    super(props);
    this.state = {
      dir: this.loopDir(props.data),
    };
    this.rootRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount() {
    if (this.rootRef && this.rootRef.current) {
      const rootDom = this.rootRef.current;
      rootDom.addEventListener("touchstart", this.handleTouchStart, false);
      rootDom.addEventListener("touchend", this.handleTouchEnd, false);
      rootDom.addEventListener("touchmove", this.handleTouchMove, false);
    }
  }
  componentWillUnmount() {
    if (this.rootRef && this.rootRef.current) {
      const rootDom = this.rootRef.current;
      rootDom.removeEventListener("touchstart", this.handleTouchStart, false);
      rootDom.removeEventListener("touchend", this.handleTouchEnd, false);
      rootDom.removeEventListener("touchmove", this.handleTouchMove, false);
    }
  }

  handleTouchStart = (e: TouchEvent) => {
    this.startX = e.touches[0].clientX;
    this.startY = e.touches[0].clientY;
    this.endX = e.touches[0].clientX;
  };

  handleTouchMove = (e: TouchEvent) => {
    this.endX = e.touches[0].clientX;
    const diffX = this.startX - this.endX;
    this.handleSlideAnimation(diffX);

    // 禁用默认的滚动事件，即拖动时禁止页面垂直滚动
    const y = Math.abs(e.touches[0].clientY - this.startY);
    const x = Math.abs(e.touches[0].clientX - this.startX);

    // 简单判断滑动方向是倾向于 y 还是 x
    // 禁止 x 方向的默认滚动，因为 x 方向的滚动会通过 Touchmove 或者 css 动画 实现
    if (y < x) {
      e.preventDefault();
    }
  };

  handleTouchEnd = () => {
    const diffX = this.startX - this.endX;
    const leftSlide = diffX > 0; // 是否是左滑动
    // console.log(
    //   "滑动方向：",
    //   leftSlide ? "左滑" : "右滑",
    //   "，滑动距离:",
    //   Math.abs(diffX)
    // );
    // 滚动超过阈值，触发切换
    if (Math.abs(diffX) > this.threshold) {
      this.handleArray(leftSlide ? "right" : "left");
    } else {
      this.handleSlideAnimationCancel();
    }
  };

  /**
   * 滑动动画处理
   * @param slide
   */
  handleSlideAnimation(slide: number) {
    const middleDom: HTMLDivElement | null = document.querySelector(
      ".slideBox .middle"
    );

    if (middleDom) {
      const offsetL = middleDom.offsetLeft;
      // 防止拖出页面外
      if (offsetL > Math.abs(slide)) {
        middleDom.style.transform = `translate(${-slide}px, 0px) scale(1)`;
      }
    }
  }

  // 取消滑动动画处理
  handleSlideAnimationCancel() {
    const middleDom: HTMLDivElement | null = document.querySelector(
      ".slideBox .middle"
    );
    const leftDom: HTMLDivElement | null = document.querySelector(
      ".slideBox .left"
    );
    const rightDom: HTMLDivElement | null = document.querySelector(
      ".slideBox .right"
    );

    if (middleDom) {
      middleDom.style.removeProperty("transform");
    }
    if (leftDom) {
      leftDom.style.transform = `scale(0.9)`;
    }
    if (rightDom) {
      rightDom.style.transform = `scale(0.9)`;
    }
  }

  loopDir(data: any[]) {
    const _data = [...data];
    const dirs = _data.map((item, idx) => {
      let name;
      if (idx + 1 === _data.length) {
        name = "left";
      } else {
        name = Comparison[idx] || "normal";
      }
      return { name };
    });
    return dirs;
  }

  handleArray(name: any) {
    // 数组处理
    const dirCopy = this.state.dir;
    if (name === "left") {
      // 点击左侧那张
      const shift = dirCopy.shift(); // 从数组头部弹出一个元素
      dirCopy.push(shift); // 添加到数组尾部
    } else if (name === "right") {
      // 点击右侧那张
      const pop = dirCopy.pop(); // 从数组尾部弹出一个元素
      dirCopy.unshift(pop); // 尾部元素添加到数组头部
    }

    this.setState({ dir: dirCopy }); // 保存重新排列的数组 并触发render
    setTimeout(() => {
      this.handleSlideAnimationCancel();
    });
  }

  renderSlideBox() {
    const { dir } = this.state;
    const { renderItem, data, height = 175 } = this.props;
    const bodyComponents = [];

    const totalCount = dir ? dir.length : 0;
    for (let i = 0; i < totalCount; i++) {
      const item = dir[i];
      const cmpt = (
        <div key={i} className={`slide ${item.name}`}>
          {renderItem(data[i], i)}
          {item.name !== "middle" ? (
            <div
              className="masking"
              onClick={() => {
                this.handleArray(item.name);
              }}
            />
          ) : null}
        </div>
      );

      bodyComponents.push(cmpt);
    }
    return React.cloneElement(
      <div className="slideBox" style={{ height }} />,
      {},
      bodyComponents
    );
  }

  render() {
    const { dir } = this.state;

    return (
      <div className="root" ref={this.rootRef}>
        {/* 外部容器*/}

        {this.renderSlideBox()}
        {/* 导航按钮*/}
        <div className="point">
          {dir.map((item: any, index: any) => {
            return (
              <span
                key={index}
                className={item.name === "middle" ? "hover" : ""}
              ></span>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Slide;
