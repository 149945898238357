import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Flex, TextareaItem, Grid, Button, Toast } from "antd-mobile";
import { Icon } from "../../basic";
import { inputBlur } from "utils/util";
import "./index.scss";
import mainApi from "api/main";

const Footer: React.FC<{
  onSend?(input: string): Promise<void>;
  tools?: { icon: string; text: string; onClick?: Function; isUpload?: boolean }[];
  onSendImg?(imgs: string[]): void;
  uptype?: string;
  count?: number;
}> = (props) => {
  const { tools, onSend, onSendImg, uptype, count=1 } = props;
  const hasTools = tools && tools.length > 0;
  const [inputVal, setInputVal] = useState<string>();
  const [toolBarVisible, setToolBarVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [sending, setSending] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // 如果显示工具栏按钮，移除 .am-textarea-control的右边距
  useEffect(() => {
    if (hasTools) {
      const dom: HTMLElement | null = document.getElementById("TextArea");
      if (dom && dom.parentElement) {
        dom.parentElement.style.marginRight = "0px";
      }
    }
  }, [hasTools]);

  // 监听鼠标点击事件： 如果点击的元素不是ChatFooter的子元素，则隐藏工具栏
  useEffect(() => {
    var scheduleBox = ref.current;
    const handleDomClick = (e: MouseEvent) => {
      const clickBox: any = e.target;
      if (scheduleBox && !scheduleBox.contains(clickBox)) {
        setToolBarVisible(false);
      }
    };
    document.addEventListener("click", handleDomClick);
    return () => {
      document.removeEventListener("click", handleDomClick);
    };
  }, []);

  // 清除输入并重置输入框高度
  const cleanInput = useCallback(() => {
    setInputVal("");
    const textareaDom = document.getElementById("TextArea");
    if (textareaDom) {
      textareaDom.style.height = "24px";
    }
  }, []);

  // 处理图片上传
  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      let fileList = event.target.files;
      if (fileList) {
        if (fileList.length <= count) {
          const requestList = [];
          for (let i = 0; i < fileList.length; i++) {
            if (fileList[i]) {
              if(uptype === "consult"){
                requestList.push(mainApi.consultImageUpload(fileList[i]));
              }else if(uptype === "reserve"){
                requestList.push(mainApi.reserveImageUpload(fileList[i]));
              }else if(uptype === "posts"){
                requestList.push(mainApi.postsImageUpload(fileList[i]));
              }else{
                requestList.push(mainApi.uploadFile(fileList[i]));
              }
            }
          }
          try {
            Toast.loading("正在上传", 0);
            const response = await Promise.all(requestList);
            const imageList: any = response.map((item: any) => {
              if (item.code === 200) {
                return '/' + item.result['file_path'] + '/' + item.result['file_name'];
              }
              return null;
            });
            onSendImg!(imageList)
            Toast.hide();
          } catch (error) {
            Toast.info(error);
          }finally{
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          }
        } else {
          Toast.info(`本次最多选择${count}张图片`, 2, undefined, false);
        }
      }
    },
    [count, onSendImg, uptype]
  );

  //
  return useMemo(
    () => (
      <div className="Component-ChatFooter" ref={ref}>
        <Flex className="Input-Wrap" align="end">
          <Flex.Item>
            <TextareaItem
              style={{
                maxHeight: "6em",
              }}
              id="TextArea"
              autoHeight
              placeholder="请充分描述您的问题.."
              value={inputVal}
              onBlur={inputBlur}
              onChange={(v) => setInputVal(v)}
              onFocus={() => {
                setToolBarVisible(false);
              }}
            />
          </Flex.Item>
          {hasTools && (
            <div
              className="Tool-Button"
              onClick={() => {
                setToolBarVisible(!toolBarVisible);
              }}
            >
              <Icon size="28">jiahao</Icon>
            </div>
          )}
          <Button
            inline
            size="small"
            type="primary"
            className="Send-Button"
            onClick={() => {
              if (!inputVal || inputVal.length < 5) {
                Toast.info("发送内容不能少于5个字", 2, undefined, false);
                return;
              }
              if (onSend) {
                setSending(true);
                onSend(inputVal).finally(() => {
                  setSending(false);
                  cleanInput();
                });
              }
            }}
            disabled={sending ? sending : !inputVal}
          >
            发送
          </Button>
        </Flex>
        {hasTools && (
          <div className={`ToolBar ${toolBarVisible ? "Show" : ""}`}>
            <Grid
              hasLine={false}
              data={
                tools &&
                tools.map((tool: any) => {
                  if(tool.isUpload){
                    return {
                      icon: (
                        <div className="Icon-Wrap">
                          <Icon size="32" color="#999">
                            {tool.icon}
                          </Icon>
                          <input
                            type="file"
                            accept="image/*"
                            multiple={count>1}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            style={{ opacity: 0, display: "none" }}/>
                        </div>
                      ),
                      text: tool.text,
                      onClick: () => {
                        if (fileInputRef.current) {
                          fileInputRef.current.click();
                        } else {
                          throw Error("undefined input dom");
                        }
                      }
                    };
                  }else{
                    return {
                      icon: (
                        <div className="Icon-Wrap">
                          <Icon size="32" color="#999">
                            {tool.icon}
                          </Icon>
                        </div>
                      ),
                      text: tool.text,
                      onClick: tool.onClick,
                    };
                  }
                })
              }
              onClick={(el: any) => {
                el.onClick && el.onClick();
              }}
            />
          </div>
        )}
      </div>
    ),

    [cleanInput, count, handleFileChange, hasTools, inputVal, onSend, sending, toolBarVisible, tools]
  );
};

export default Footer;
