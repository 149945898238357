import React, { memo, useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { customShare } from "utils/weixin";
import { DataList, EnterLoading, Error } from "components/basic";
import { initHealthTest, changeTestList } from "./store/actionCreators";
import useBack from "hooks/useBack";
import "./index.scss";

import { LOGO } from "common/constant";

const HealthTest: React.FC<{
  rows?: any[];
}> = ({ rows }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    // 自定义分享内容
    customShare({
      title: `健康自测-丁丁好医`,
      desc: "国际通用的男科医学指南自测量表，自测评估你的健康状况",
      imgUrl:LOGO
    });

    if (!rows) {
      dispatch(
        initHealthTest((error?: string) => {
          if (error) {
            setError(error);
          }
          setLoading(false);
        })
      );
    }
  }, [dispatch, rows]);

  useBack(() => dispatch(changeTestList()));

  if (error) {
    return <Error error={error} />;
  } else {
    if (loading) {
      return <EnterLoading />;
    } else {
      return (
        <div className="Screen-HealthTest">
          <DataList
            rows={rows}
            renderRow={(item: any, index) => {
              return (
                <div className="test-item" key={index} onClick={() => {
                  if(item.is_brand_custom === 1){
                    history.push(`/brand-test/${item.id}`);
                  }else{
                    history.push(`/test/${item.id}`);
                  }
                }}>
                  <div className="item-cover" style={{
                    backgroundImage: "url(" + item.test_img + ")",
                  }}></div>

                  <div className="item-main">
                    <div className="item-title">{item.test_title}</div>
                    <div className="item-more Text-Line__1">{item.test_alias}</div>
                  </div>

                  <div className="item-btn">
                    开始测评
                  </div>
                </div>
              );
            }}
          />
        </div>
      );
    }
  }
};

const mapStateToProps = (state: any) => ({
  rows: state.getIn(["test", "testList"]),
});

export default connect(mapStateToProps)(memo(HealthTest));
