import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import routers from "routes";
import { NotFound } from "components/business";
import { getToken } from "utils/storage";
import { ROOT } from "common/constant";
import { EnterLoading } from "components/basic";
import "../index.scss";
import { TopAppDownloadBar } from "components/global";
import { device } from "utils/util";


const MainRoutes: React.FC<{
  withFooter?: boolean;
}> = ({ withFooter }) => {
  const token = getToken();
  const isWeiXin = device.versions.wx;


  return (
    <main className={`layout-main${withFooter ? " with-footer" : ""}`}>
      <TopAppDownloadBar />

      <Switch>
        {routers.map((item, index) => {
          return (
            <Route
              key={index}
              path={item.path}
              exact
              render={(d) => {
                if (item.title) document.title = item.title;

                let child = <item.component />;
                // 如果是私有页面并且没有用户信息，则重定向到登陆页
                if (item.private && !token && !isWeiXin) {
                  // 登陆页面的重定向地址
                  const redirectUrl = ROOT + d.location.pathname + d.location.search;
                  child = (
                    <Redirect
                      exact
                      to={`/login?redirectUrl=${encodeURIComponent(
                        redirectUrl
                      )}`}
                    />
                  );
                }

                return <Suspense fallback={<EnterLoading />}>{child}</Suspense>
              }}
            />
          );
        })}
        {/* 首页默认重定向到 /home */}
        <Redirect from="/" to="/home" />
        {/* 未匹配到的路由重定向到 404 */}
        <Route component={NotFound} />
      </Switch>
    </main>
  );
};

export default MainRoutes;

