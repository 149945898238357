import { lazy } from "react";

const HospitallRule = lazy(() => import("./HospitallRule"));
const HospitalDetail = lazy(() => import("./HospitalDetail"));

const routes: DDHY.Route[] = [
  {
    path: "/hospitallrule",
    title: "医院列表",
    component: HospitallRule,
  },
  {
    path: "/hospitaldetail/:hospital_id",
    title: "医院详情",
    component: HospitalDetail,
  }
];

export { routes };
