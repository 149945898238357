import { lazy } from "react";

const Search = lazy(() => import("./index"));

const routes: DDHY.Route[] = [
  {
    path: "/search",
    title: "搜索",
    component: Search,
  },
];

export { routes };
