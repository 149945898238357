import React, { CSSProperties } from "react";
import LazyLoad from "react-lazyload";
import { getImageUrl } from "utils/util";
interface Props {
  src: string;
  alt?: string;
  style?: CSSProperties;
}
const Avatar: React.FC<Props> = (props) => {
  const { src, alt = "", style } = props;
  const _style = {
    width: 24,
    height: 24,
    borderRadius: "50%",
    ...style,
  };

  return (
    <div style={_style}>
      <LazyLoad>
        <img style={_style} src={getImageUrl(src)} alt={alt} />
      </LazyLoad>
    </div>
  );
};

export default Avatar;
