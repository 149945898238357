import * as actionTypes from "./constants";
import { getLocalSearchHistory } from "./actionCreators";

export interface SearchArticleState {
  articleList?: DDHY.Article[];
  page?: number;
  hasMore?: boolean;
}
export interface SearchPostState {
  postList?: DDHY.SNSPost[];
  page?: number;
  hasMore?: boolean;
}
export interface SearchDoctorState {
  doctorList?: DDHY.Doctor[];
  recommendDoctorList?: DDHY.Doctor[];
  page?: number;
  hasMore?: boolean;
}
export interface SearchProjectState {
  projectList?: DDHY.Project[];
  recommendProjectList?: DDHY.Project[];
  page?: number;
  hasMore?: boolean;
}

export interface SearchHospitalState { 
  hospitalList?: DDHY.hospitals[];
  recommendHospitalList?: DDHY.hospitals[];
  page?: number;
  hasMore?: boolean;
}

export interface SearchState {
  topSearch?: string[]; // 热门搜索
  historySearch?: string[]; // 搜索历史
  searchKey?: string; // 搜索关键字
  searched?: boolean; //搜索状态
  tabIndex: number; // 搜索结果分页索引
  article: SearchArticleState; // 搜索文章结果缓存
  post: SearchPostState; // 搜索帖子结果缓存
  doctor: SearchDoctorState; // 搜索医生结果缓存
  project: SearchProjectState; // 搜索服务项目结果缓存
}

const defaultState: SearchState = {
  historySearch: getLocalSearchHistory(), // 历史搜索
  searchKey: "", // 搜索关键字
  searched: false,
  tabIndex: 0,
  article: {
    page: 1,
    hasMore: true,
  },
  post: {
    page: 1,
    hasMore: true,
  },
  doctor: {
    page: 1,
    hasMore: true,
  },
  project: {
    page: 1,
    hasMore: true,
  },
};

export default (state = defaultState, action: any): SearchState => {
  switch (action.type) {
    case actionTypes.SET_TOP_SEARCH:
      return { ...state, topSearch: action.data };
    case actionTypes.SET_HISTORY_SEARCH:
      return { ...state, historySearch: action.data };
    case actionTypes.SET_SEARCH_KEY:
      return { ...state, searchKey: action.data };
    case actionTypes.SET_SEARCHED:
      return { ...state, searched: action.data };
    case actionTypes.SET_TAB_INDEX:
      return { ...state, tabIndex: action.data };

    // SearchArticle
    case actionTypes.SET_ARTICLE_LIST:
      return {
        ...state,
        article: { ...state.article, articleList: action.data },
      };
    case actionTypes.SET_ARTICLE_PAGE:
      return {
        ...state,
        article: { ...state.article, page: action.data },
      };
    case actionTypes.SET_ARTICLE_HASMORE:
      return {
        ...state,
        article: { ...state.article, hasMore: action.data },
      };

    // SearchPost
    case actionTypes.SET_POST_LIST:
      return {
        ...state,
        post: { ...state.post, postList: action.data },
      };
    case actionTypes.SET_POST_PAGE:
      return {
        ...state,
        post: { ...state.post, page: action.data },
      };
    case actionTypes.SET_POST_HASMORE:
      return {
        ...state,
        post: { ...state.post, hasMore: action.data },
      };

    // SearchDoctor
    case actionTypes.SET_DOCTOR_RECOMMEND_LIST:
      return {
        ...state,
        doctor: { ...state.doctor, recommendDoctorList: action.data },
      };
    case actionTypes.SET_DOCTOR_LIST:
      return {
        ...state,
        doctor: { ...state.doctor, doctorList: action.data },
      };
    case actionTypes.SET_DOCTOR_PAGE:
      return {
        ...state,
        doctor: { ...state.doctor, page: action.data },
      };
    case actionTypes.SET_DOCTOR_HASMORE:
      return {
        ...state,
        doctor: { ...state.doctor, hasMore: action.data },
      };

    // SearchProject
    case actionTypes.SET_PROJECT_RECOMMEND_LIST:
      return {
        ...state,
        project: { ...state.project, recommendProjectList: action.data },
      };
    case actionTypes.SET_PROJECT_LIST:
      return {
        ...state,
        project: { ...state.project, projectList: action.data },
      };
    case actionTypes.SET_PROJECT_PAGE:
      return {
        ...state,
        project: { ...state.project, page: action.data },
      };
    case actionTypes.SET_PROJECT_HASMORE:
      return {
        ...state,
        project: { ...state.project, hasMore: action.data },
      };
    default:
      return state;
  }
};
