import { lazy } from "react";

const ActivityHelpgo = lazy(() => import("./Helpgo"));
// const AppStoreRegionInvite = lazy(() => import("./RegionInvite"));

const routes: DDHY.Route[] = [
  {
    path: "/activity/help-go",
    title: "快来帮我助力",
    component: ActivityHelpgo,
    private: true
  },
  // {
  //   path: "/activity/invite",
  //   title: "用户邀请",
  //   component: AppStoreRegionInvite,
  //   private: true
  // },
];

export { routes };
