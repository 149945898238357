import articleApi from "api/article";
import * as constatnts from "./constants";
import { Toast } from "antd-mobile";
import { handleResponseError } from "utils/request";
import memberApi from "api/member";

export const changeDetails = (data?: any) => ({
  type: constatnts.SET_DETAILS,
  data,
});

export const changeComments = (data?: Array<DDHY.SNSPostComment>) => ({
  type: constatnts.SET_COMMENTS,
  data: data,
});
export const changePage = (data: number) => ({
  type: constatnts.SET_PAGE,
  data: data,
});
export const changeHasMore = (data: boolean) => ({
  type: constatnts.SET_HASMORE,
  data: data,
});


/**
 * 页面初始化
 *
 * @comopnent Article
 * @param id 文章ID
 */
export const initArticle = (id: string | number, callback?: Function) => {
  return async (dispatch: any) => {
    try {
      const data = await articleApi.getDetails(id);
      dispatch(changeDetails(data));
      callback && callback();
    } catch (error) {
      callback && callback(error);
    } finally {
      setTimeout(() => {
        dispatch(loadComments());
      });
    }
  };
};

/**
 * 加载文章评论
 */
export const loadComments = (callback?: Function) => {
  return async (dispatch: any, getState: any) => {
    const { articleDetails, comments, page } = getState().getIn(["classroom", "article"]);

    const limit = 10;
    if (!articleDetails) {
      throw Error("loadComments Error: article is undefined");
    }
    try {
      const { data } = await articleApi.getComments({
        article_id: articleDetails.article_id,
        page,
        limit,
      });

      if (page === 1 || !comments) {
        dispatch(changeComments(data));
      } else {
        dispatch(changeComments([...comments, ...data]));
      }
      dispatch(changeHasMore(data.length === limit));
    } catch (error) {
      Toast.info(error);
    } finally {
      callback && callback();
    }
  };
};

/**
 * 重新加载帖子评论列表
 */
export const reloadComments = () => {
  return (dispatch: any) => {
    dispatch(changePage(1));
    dispatch(changeComments());
    setTimeout(() => {
      dispatch(loadComments());
    });
  };
};

/**
 * 收藏文章
 *
 * @comopnent Article
 * @param callback 回调函数
 */
export const handleCollectionArticle = (callback?: Function) => {
  return async (d: any, getState: any) => {
    try {
      const { articleDetails } = getState().getIn(["classroom", "article"]);
      if (!articleDetails || !articleDetails.article_info) {
        Toast.info("获取文章信息失败！请刷新重试");
        return;
      }
      let { article_info } = articleDetails;
      await memberApi.memberCollectionOperation(article_info.article_id, 'collect', 'article');
    } catch (error) {
      handleResponseError(error);
    } finally {
      callback && callback();
    }
  };
};

/**
 * 点赞文章
 *
 * @component Article
 * @param callback 回调函数
 */
export const handleSupportArticle = (item: any, callback: Function) => {
  return async (d: any, getState: any) => {
    try {
      const { articleDetails } = getState().getIn(["classroom", "article"]);
      if (!articleDetails || !articleDetails.article_info) {
        Toast.info("获取文章信息失败！请刷新重试");
        return;
      }
      let { article_info } = articleDetails;
      await articleApi.userSupport(article_info.article_id, item.active_id);
    } catch (error) {
      handleResponseError(error);
    } finally {
      callback();
    }
  };
};
