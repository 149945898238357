/**
 * 帖子评论
 */
import React, { memo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Flex } from "antd-mobile";
import { Icon } from "components/basic";
import { getImageUrl, richTextFilter, timeInference } from "utils/util";
import { canIDeleteComment } from "screens/Community/Post/action";
import { isCommunityAdmin } from "screens/Account/actions";
import "./index.scss";

interface PostCommentProps {
  comment: DDHY.SNSPostComment; // 评论信息
  floor: number; // 楼层
  onBanned?(user: DDHY.SNSUserInfo): void; // 禁言用户的回调函数，返回被禁言的用户信息
  onDelete?(comment: DDHY.SNSPostComment): void; // 删除评论的会点函数， 返回被删除的评论信息
  onClick?(comment: DDHY.SNSPostComment): void; // 点击评论的处理
}
const PostComment: React.FC<PostCommentProps> = (props) => {
  const history = useHistory();
  const { comment, floor, onBanned, onDelete, onClick } = props;

  /**
   * 回复评论
   *
   * * 如果用户被禁言，无法回复
   * * 如果目标评论被删除，无法回复
   */
  const handleCommentClick = useCallback(
    (comment: DDHY.SNSPostComment) => {
      onClick && onClick(comment);
    },
    [onClick]
  );

  // 评论被删除则不显示
  if (!comment || comment.is_delete === 1) return null;
  const { user_info, sub_comment_list } = comment;
  const filterSubComments = sub_comment_list.filter(
    (item) => item && item.refer_user_info && item.is_delete !== 1
  );
  return (
    <div className="Component-PostComment">
      <Flex
        className="User-Info"
        onClick={() => {
          history.push(`/member/${comment.user_id}`);
        }}
      >
        <img
          className="Avatar"
          src={getImageUrl(user_info.member_avatar)}
          alt=""
        />
        <Flex.Item className="Nickname">{user_info.nick_name}</Flex.Item>
        <div>{floor}楼</div>
      </Flex>
      <div style={{ paddingLeft: "37px" }}>
        <div
          className="Content"
          onClick={(e) => {
            e.stopPropagation();
            handleCommentClick(comment);
          }}
        >
          {richTextFilter(comment.comment_content)}
        </div>
        <Flex className="Status">
          <Flex.Item>{timeInference(comment.comment_addtime)}</Flex.Item>

          {/* 禁言按钮 */}
          {isCommunityAdmin() && onBanned && (
            <Flex
              style={{ padding: "3px 5px" }}
              align="center"
              onClick={(e) => {
                e.stopPropagation();
                onBanned(comment.user_info);
                // handleBanMember(comment.user_info);
              }}
            >
              <Icon
                size="18"
                color={comment.user_info.is_banned === 1 ? "#0099ff" : "#999"}
              >
                micDisable
              </Icon>
            </Flex>
          )}

          {/* 删除评论按钮 */}
          {canIDeleteComment(comment) && onDelete && (
            <Flex
              style={{ padding: "3px 5px" }}
              align="center"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(comment);
              }}
            >
              <Icon size="18">empty_fill</Icon>
            </Flex>
          )}
        </Flex>

        {/* 过滤后的子评论 */}
        {filterSubComments.length > 0 && (
          <div className="Comment-Children">
            {filterSubComments.map((item) => {
              if (!item.refer_user_info) return null;
              return (
                <div
                  key={`comment-children-${item.comment_id}`}
                  className="Comment-Child"
                >
                  <div className="Comment-Child__Header">
                    <span className="Child-Nickname">
                      {item.user_info.nick_name}
                    </span>
                    <span style={{ padding: "0 3px" }}>回复</span>
                    <span className="Child-Nickname">
                      {item.refer_user_info.nick_name}
                    </span>
                    <span>：</span>
                  </div>
                  <div
                    className="Comment-Child__Body"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCommentClick(item);
                    }}
                  >
                    {richTextFilter(item.comment_content)}
                  </div>
                  <Flex className="Comment-Child__Footer">
                    <Flex.Item>{timeInference(item.comment_addtime)}</Flex.Item>
                    {isCommunityAdmin() && onBanned && (
                      <Flex
                        style={{ padding: "3px 5px" }}
                        align="center"
                        onClick={(e) => {
                          e.stopPropagation();
                          onBanned(item.user_info);
                          // handleBanMember(item.user_info);
                        }}
                      >
                        <Icon
                          size="18"
                          color={
                            item.user_info.is_banned === 1 ? "#0099ff" : "#999"
                          }
                        >
                          micDisable
                        </Icon>
                      </Flex>
                    )}
                    {canIDeleteComment(comment) && onDelete && (
                      <Flex
                        style={{ padding: "3px 5px" }}
                        align="center"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDelete(item);
                        }}
                      >
                        <Icon size="18">empty_fill</Icon>
                      </Flex>
                    )}
                  </Flex>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
export default memo(PostComment);
