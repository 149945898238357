// 社区广场公用
export const SET_TAB_INDEX = "community/SET_TAB_INDEX";

// 社区广场 -> 圈子
export const SET_TOPICS = "community/SET_TOPICS";
export const SET_TOPIC_PAGE = "community/SET_TOPIC_PAGE";
export const SET_TOPIC_HASMORE = "community/SET_TOPIC_HASMORE";

// 社区广场 -> 最新
export const SET_NEW_POSTS = "community/SET_NEW_POSTS";
export const SET_NEW_PAGE = "community/SET_NEW_PAGE";
export const SET_NEW_HASMORE = "community/SET_NEW_HASMORE";

// 社区广场 -> 最热
export const SET_HOT_POSTS = "community/SET_HOT_POSTS";
export const SET_HOT_PAGE = "community/SET_HOT_PAGE";
export const SET_HOT_HASMORE = "community/SET_HOT_HASMORE";

// 社区广场 -> 关注
export const SET_FOLLOWED_POSTS = "community/SET_FOLLOWED_POSTS";
export const SET_FOLLOWED_PAGE = "community/SET_FOLLOWED_PAGE";
export const SET_FOLLOWED_HASMORE = "community/SET_FOLLOWED_HASMORE";
