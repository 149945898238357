// Search
export const SET_TOP_SEARCH = "search/SET_TOP_SEARCH";
export const SET_HISTORY_SEARCH = "search/SET_HISTORY_SEARCH";
export const SET_SEARCH_KEY = "search/SET_SEARCH_KEY";
export const SET_SEARCHED = "search/SET_SEARCHED";
export const SET_TAB_INDEX = "search/SET_TAB_INDEX";

// SearchArticle
export const SET_ARTICLE_LIST = "search/SET_ARTICLE_LIST";
export const SET_ARTICLE_PAGE = "search/SET_ARTICLE_PAGE";
export const SET_ARTICLE_HASMORE = "search/SET_ARTICLE_HASMORE";

// SearchPost
export const SET_POST_LIST = "search/SET_POST_LIST";
export const SET_POST_PAGE = "search/SET_POST_PAGE";
export const SET_POST_HASMORE = "search/SET_POST_HASMORE";

// SearchDoctor
export const SET_DOCTOR_RECOMMEND_LIST = "search/SET_DOCTOR_RECOMMEND_LIST";
export const SET_DOCTOR_LIST = "search/SET_DOCTOR_LIST";
export const SET_DOCTOR_PAGE = "search/SET_DOCTOR_PAGE";
export const SET_DOCTOR_HASMORE = "search/SET_DOCTOR_HASMORE";

// SearchProject
export const SET_PROJECT_RECOMMEND_LIST = "search/SET_PROJECT_RECOMMEND_LIST";
export const SET_PROJECT_LIST = "search/SET_PROJECT_LIST";
export const SET_PROJECT_PAGE = "search/SET_PROJECT_PAGE";
export const SET_PROJECT_HASMORE = "search/SET_PROJECT_HASMORE";
