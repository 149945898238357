import { lazy } from "react";

import HealthTest from "./Home";
const Test = lazy(() => import("./Test"));
const TestResult = lazy(() => import("./TestResult"));
const BrandTest = lazy(() => import('./BrandTest/Home'));
//const BrandTestPage = lazy(() => import('./BrandTest/Test'));
const BrandTestResult = lazy(() => import('./BrandTest/TestResult'));

const routes: DDHY.Route[] = [
  // 健康自测
  {
    path: "/health-test",
    title: "健康自测",
    component: HealthTest,
  },
  {
    path: "/test/result/:result_id",
    title: "自测结果",
    component: TestResult,
    private: true,
  },
  {
    path: "/test/:id",
    title: "健康自测",
    component: Test,
    private: true,
  },

  // 品牌定制自测
  {
    path: "/brand-test/:id",
    title: "健康自测",
    component: BrandTest,
    private: true,
  },
  {
    path: "/brand-test/result/:result_id",
    title: "自测结果",
    component: BrandTestResult,
    private: true,
  },

];

export { routes };
