import React, { useEffect, memo, useRef } from "react";
import "./index.scss";

/**
 * 比例伸展动画
 */
const Scale: React.FC<{
  scale: number; // 比例，最大不应超过1
  children: any;
}> = props => {
  const { scale, children } = props;
  // dom引用
  const domRef = useRef<HTMLDivElement>(null);
  // 记录dom高度
  const domHeightRef = useRef<number>(0);

  useEffect(() => {
    if (domRef.current) {
      domHeightRef.current = domRef.current.clientHeight;
    }
  }, [domRef]);
  useEffect(() => {
    if (domHeightRef && domRef.current) {
      if (!scale) {
        domRef.current.style.height = `0`;
      } else {
        domRef.current.style.height = `${domHeightRef.current * scale}px`;
      }
    }
  }, [scale, domHeightRef, domRef]);

  return (
    <div id="ScaleAnimation" ref={domRef}>
      {children}
    </div>
  );
};

export default memo(Scale);
