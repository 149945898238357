import { lazy } from "react";

const TopicList = lazy(() => import("./Topic/TopicList"));
const Topic = lazy(() => import("./Topic")); // 圈子详情
const Post = lazy(() => import("./Post")); // 帖子详情
const Member = lazy(() => import("./Member")); // 用户主页

const Square = lazy(() => import("./Square")); // 社区广场
// const CreatePost = lazy(() => import("./Post/Create"));
// const CommentPost = lazy(() => import("./Post/CommentPost"));


const routes: DDHY.Route[] = [
  {
    path: "/topic",
    title: "丁丁圈子",
    component: TopicList,
  },
  {
    path: "/topic/:id",
    title: "圈子详情",
    component: Topic,
    private: true,
  },
  {
    path: "/post/:id",
    title: "帖子详情",
    private: true,
    component: Post,
  },
  {
    path: "/member/:id",
    title: "用户主页",
    component: Member,
  },

  { path: "/community", title: "社区广场", component: Square },
  // {
  //   path: "/post/create",
  //   title: "发帖",
  //   component: CreatePost,
  //   private: true,
  // },
  // {
  //   path: "/post/comment",
  //   title: "评论",
  //   component: CommentPost,
  //   private: true,
  // },

];

export { routes };
