import { getResult } from "utils/request";

/**
 * 管理员对帖子加精、取消加精操作接口
 */
const postElite = (data: {
  posts_id: string | number; // 帖子编号
  active_type: "essence" | "unessence"; // 操作类型 essence-加精 unessence-取消加精
}) => {
  return getResult({
    needLogin: true,
    needRealInfo: true,
    url: "/v2/manager/essence",
    data,
    // method: "post",
  });
};

/**
 * 管理员隐藏、显示帖子所有图片操作接口
 */
const postHideImage = (data: {
  posts_id: number | string; // 帖子编号
  active_type: "hidden" | "display"; // 操作类型 hidden-隐藏 display-显示
}) => {
  return getResult({
    needLogin: true,
    needRealInfo: true,
    url: `/v2/manager/post_image_hidden`,
    data,
    // method: "post",
  });
};


/**
 * 管理员删除、恢复帖子操作接口
 */
const postDelete = (data: {
  posts_id: number; // 帖子编号
  active_type: "del" | "restore"; // 操作类型 del-删除 restore-恢复
}) => {
  return getResult({
    needLogin: true,
    needRealInfo: true,
    url: `/v2/manager/post_del`,
    data,
    method: "post",
  });
};

/**
 * 删除、恢复帖子评论
 */
const commentDelete = (data: {
  posts_id: number; //  帖子编号
  comment_id: number; //  评论编号
  active_type: "del" | "restore"; //  操作类型 del-删除 restore-恢复
}) => {
  return getResult({
    needLogin: true,
    needRealInfo: true,
    url: `/v2/manager/post_comment_del`,
    data,
    // method: "post",
  });
};

/**
 * 管理员对用户进行禁言、解除禁言操作接口
 */
const memberBand = (data: {
  user_id: number; //用户编号
  active_type: "banned" | "unban"; // banned-禁言 unban-解除禁言
  posts_id: number; //相关的帖子编号
  type?: "post" | "article"; // 禁言发起主体，默认post
}) => {
  return getResult({
    needLogin: true,
    needRealInfo: true,
    url: `/v2/manager/user_banned`,
    data,
    method: "post",
  });
};

const adminApi = {
  postElite,
  postHideImage,
  postDelete,
  commentDelete,
  memberBand,
};

export default adminApi;
