import * as consts from "./constants";
import { Toast } from "antd-mobile";
import specialApi from "api/special";
import articleApi from "api/article";
import { MyCollectedState, MyPurchasedState } from "./reducer";

export const changeTabIndex = (data: number) => ({
  type: consts.SET_TAB_INDEX,
  data: data,
});

// MyRead
// MyRead -> MyCollected
export const changeCollectedSpecialList = (data?: DDHY.Special[]) => ({
  type: consts.SET_COLLECTED_SPECIAL_LIST,
  data: data,
});
export const changeCollectedArticleList = (data?: DDHY.Article[]) => ({
  type: consts.SET_COLLECTED_ARTICLE_LIST,
  data: data,
});
export const changeCollectedArticlePage = (data: number) => ({
  type: consts.SET_COLLECTED_ARTICLE_PAGE,
  data: data,
});
export const changeCollectedArticleHasMore = (data: boolean) => ({
  type: consts.SET_COLLECTED_ARTICLE_HASMORE,
  data: data,
});

// MyRead -> MyPurchased
export const changePurchasedSpecialList = (data?: DDHY.Special[]) => ({
  type: consts.SET_PURCHASED_SPECIAL_LIST,
  data: data,
});
export const changePurchasedArticleList = (data?: DDHY.Article[]) => ({
  type: consts.SET_PURCHASED_ARTICLE_LIST,
  data: data,
});
export const changePurchasedArticlePage = (data: number) => ({
  type: consts.SET_PURCHASED_ARTICLE_PAGE,
  data: data,
});
export const changePurchasedArticleHasMore = (data: boolean) => ({
  type: consts.SET_PURCHASED_ARTICLE_HASMORE,
  data: data,
});

/**
 * 清除State
 *
 * @component MyRead
 */
export const cleanMyRead = () => {
  return (dispatch: any) => {
    dispatch(changeCollectedSpecialList());
    dispatch(changeCollectedArticleList());
    dispatch(changeCollectedArticlePage(1));
    dispatch(changeCollectedArticleHasMore(true));

    dispatch(changePurchasedArticleList());
    dispatch(changePurchasedSpecialList());
    dispatch(changePurchasedArticlePage(1));
    dispatch(changePurchasedArticleHasMore(true));
  };
};

/**
 * 加载我收藏的专栏
 *
 * @component MyRead -> Collected
 * @param callback
 */
export const loadMyCollectedSpecials = (callback: Function) => {
  return (dispatch: any) => {
    specialApi
      .getCollectionSpecials({
        page: 1,
        limit: 100,
      })
      .then((result: any) => {
        let { data } = result;
        dispatch(changeCollectedSpecialList(data));
      })
      .catch((error) => {
        Toast.info(error);
      })
      .finally(() => callback());
  };
};
/**
 * 加载我收藏的文章
 *
 * @component MyRead -> Collected
 */
export const loadMyCollectedArticles = (callback?: Function) => {
  return async (dispatch: any, getState: any) => {
    const limit = 10;

    try {
      const state: MyCollectedState = getState().getIn([
        "profile",
        "myRead",
        "collected",
      ]);
      const { articlePage, articleList } = state;
      const result = await articleApi.getCollectionAticles({
        page: articlePage,
        limit,
      });
      let { current_page, last_page, data } = result;
      if (articlePage === 1 || !articleList) {
        dispatch(changeCollectedArticleList(data));
      } else {
        dispatch(changeCollectedArticleList([...articleList, ...data]));
      }
      dispatch(changeCollectedArticleHasMore(current_page < last_page));
    } catch (error) {
      Toast.info(error);
    } finally {
      callback && callback();
    }
  };
};



/**
 * 加载我购买的专栏
 *
 * @component MyRead -> Collected
 * @param callback
 */
export const loadMyPurchasedSpecials = (callback: Function) => {
  return (dispatch: any) => {
    specialApi
      .getPurchasedSpecials({
        page: 1,
        limit: 100,
      })
      .then((result: any) => {
        dispatch(changePurchasedSpecialList(result));
      })
      .catch((error) => {
        Toast.info(error);
      })
      .finally(() => callback());
  };
};
/**
 * 加载我购买的文章
 *
 * @component MyRead -> Purchased
 */
export const loadMyPurchasedArticles = (callback?: Function) => {
  return async (dispatch: any, getState: any) => {
    const limit = 10;

    try {
      const state: MyPurchasedState = getState().getIn([
        "profile",
        "myRead",
        "purchased",
      ]);
      const { articlePage, articleList } = state;
      const data = await articleApi.getPurchasedAticles({
        page: articlePage,
        limit,
      });

      if (articlePage === 1 || !articleList) {
        dispatch(changePurchasedArticleList(data));
      } else {
        dispatch(changePurchasedArticleList([...articleList, ...data]));
      }
      dispatch(changePurchasedArticleHasMore(data.length === limit));
    } catch (error) {
      Toast.info(error);
    } finally {
      callback && callback();
    }
  };
};
