import * as actionTypes from "./constants";

export interface SpecialState {
  specialDetails?: any;
  specials?: Array<DDHY.Special>;
  specialsPage: number;
  specialsHasMore: boolean;
}

const defaultState: SpecialState = {
  specialsPage: 1,
  specialsHasMore: true,
};

export default (state = defaultState, action: any): SpecialState => {
  switch (action.type) {
    // Special
    case actionTypes.SET_DETAILS:
      return { ...state, specialDetails: action.data };

    // Specials
    case actionTypes.SET_SPECIALS:
      return { ...state, specials: action.data };
    case actionTypes.SET_SPECIALS_PAGE:
      return { ...state, specialsPage: action.data };
    case actionTypes.SET_SPECIALS_HASMORE:
      return { ...state, specialsHasMore: action.data };

    default:
      return state;
  }
};
