/**
 * 封装列表渲染，上拉加载组件
 *
 * @component DataList
 */
import React, { useMemo, useEffect, useCallback } from "react";
import ToEnd from "../ToEnd";
import Loading from "../Loading";
import NoData from "../NoData";
import { getContainerMetrics } from "utils/browser";
import throttle from "lodash/throttle";
import "./index.scss";
/**
 * 列表底部，展示列表状态
 */
export const DataListFooter: React.FC<{
  loading?: boolean;
  hasMore?: boolean;
}> = ({ loading, hasMore }) => {
  if (loading) {
    return <Loading />;
  } else if (hasMore) {
    return null;
  } else {
    return <ToEnd />;
  }
};

const DataList: React.FC<{
  rows?: any[];
  renderRow(data: any, index: any): React.ReactElement<any>;
  renderFooter?: React.ReactElement<any>;
  renderRecommend?: React.ReactElement<any>; // 推荐内容
  renderEmptyView?: React.ReactElement<any>; // 空内容视图
  onPullUpLoad?(): void;
  onScroll?(y: number): void;
  onPullUpThreshold?: number; // 触发上拉加载距离底部的阈值
}> = (props) => {
  const {
    rows,
    renderRow,
    renderRecommend,
    renderFooter,
    renderEmptyView,
    onPullUpLoad,
    onScroll,
    onPullUpThreshold = 300,
  } = props;

  let _onPullUpLoad: any;
  if (onPullUpLoad) {
    _onPullUpLoad = throttle(onPullUpLoad, 300);
  }

  const renderBody = useCallback(() => {
    if (!rows) return;
    const bodyComponents = [];
    const totalCount = rows ? rows.length : 0;
    for (let i = 0; i < totalCount; i++) {
      bodyComponents.push(renderRow(rows[i], i));
    }
    return React.cloneElement(
      <div className="DataList-Body" />,
      {},
      bodyComponents
    );
  }, [renderRow, rows]);

  /**
   * 列表渲染
   *
   * ### 列表不为空
   * * 渲染列表Body内容
   * * 渲染为您推荐(优先)/Footer
   *
   * ### 列表为空
   * * 渲染空内容提示
   * * 渲染为您推荐(如果有)
   *
   */
  const renderContent = useCallback(() => {
    return (
      <div className="DataList-Content">
        {rows && rows.length > 0 ? (
          <>
            {renderBody()}
            {renderRecommend
              ? renderRecommend
              : renderFooter
              ? renderFooter
              : null}
          </>
        ) : (
          <>
            {renderEmptyView ? renderEmptyView : <NoData />}
            {renderRecommend ? renderRecommend : null}
          </>
        )}
      </div>
    );
  }, [renderBody, renderEmptyView, renderFooter, renderRecommend, rows]);

  const handleScroll = useCallback(() => {
    const scrollProperties = getContainerMetrics();
    if (scrollProperties.topOffset <= 100) return;
    // 上拉加载触发条件判断
    onScroll && onScroll(scrollProperties.topOffset);

    if (
      scrollProperties.windowHeight + scrollProperties.topOffset >=
      scrollProperties.contentHeight - onPullUpThreshold
    ) {
      _onPullUpLoad && _onPullUpLoad();
    }
  }, [_onPullUpLoad, onPullUpThreshold, onScroll]);

  // const _Scroll = debounce(handleScroll, 30);

  useEffect(() => {
    if (onPullUpLoad || onScroll) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll, onPullUpLoad, onScroll]);

  return useMemo(
    () => (
      <div className="Component-DataList">
        {rows ? renderContent() : <Loading />}
      </div>
    ),
    [renderContent, rows]
  );
};

export default DataList;
