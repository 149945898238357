import * as consts from "./constants";
import mainApi from "api/main";
import { Toast } from "antd-mobile";
import { getLocation } from "utils/weixin";
import hospitalApi from "api/hospital";

export const changeCountDown = (data: any) => ({
  type: consts.SET_COUNT_DOWN,
  data: data,
});
export const changeHospitals = (data: any) => ({
  type: consts.SET_HOSPITALS,
  data: data,
});
export const changeHospitalsLoading = (data: any) => ({
  type: consts.SET_HOSPITALS_LOADING,
  data: data,
});
export const changeHospitalsHasMore = (data: any) => ({
  type: consts.SET_HOSPITALS_HASMORE,
  data: data,
});
export const changeHospitalsPage = (data: any) => ({
  type: consts.SET_HOSPITALS_PAGE,
  data: data,
});
export const changeRegions = (data: any) => ({
  type: consts.SET_REGIONS,
  data: data,
});
export const changeRegionValue = (data: any) => ({
  type: consts.SET_REGION_VALUE,
  data: data,
});
export const changeLocation = (data: any) => ({
  type: consts.SET_LOCATION,
  data: data,
});

/**
 * ### Register
 * 开始倒计时
 */
export const beginCountdown = (cb?: Function) => {
  return (dispatch: any, getState: any) => {
    const verifyInterval = getState().getIn([
      "doctorRegister",
      "verifyInterval",
    ]);
    dispatch(changeCountDown(verifyInterval));
    const timer = setInterval(() => {
      const count = getState().getIn(["doctorRegister", "countDown"]);
      if (count <= 0) {
        clearInterval(timer);
      } else {
        dispatch(changeCountDown(count - 1));
      }
    }, 1000);
  };
};

export const initRegionInfo = (cb?: Function) => {
  return (dispatch: any, getState: any) => {
    const location = getState().getIn(["doctorRegister", "location"]);
    const regions = getState().getIn(["doctorRegister", "regions"]);
    const allPms = Promise.all([
      regions ? undefined : getRegions(),
      location ? undefined : handleLocation(),
    ]);
    allPms.then((response: any[]) => {
      const [regions, location] = response;
      if (regions) {
        dispatch(changeRegions(regions));
      }
      if (location) {
        dispatch(changeLocation(location));
        localStorage.setItem("location", JSON.stringify(location));
      }
    });
    allPms.catch((error) => {
      throw error;
    });
    allPms.finally(() => {
      cb && cb();
    });

    function getRegions() {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await mainApi.getRegionsTree();
          let data = result.splice(1);
          for (let i = 0; i < data.length; i++) {
            data[i].children = data[i].children.splice(1);
          }
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    }

    function handleLocation() {
      return new Promise((resolve, reject) => {
        const pms = getLocation();
        pms.then((response: any) => {
          if (response) {
            resolve(response);
          } else {
            reject("获取位置信息失败！");
          }
        });
        pms.catch((error) => {
          throw error;
        });
      });
    }
  };
};

export const loadHospitals = () => {
  return (dispatch: any, getState: any) => {
    const page = getState().getIn(["doctorRegister", "hospitalsPage"]);
    const limit = 15;
    const address_id = getLocationId();

    dispatch(changeHospitalsLoading(true));
    const pms = hospitalApi.getHospitalList({
      page,
      limit,
      hospital_address_id: address_id,
      from:""
    });

    pms.then((result: any) => {
      const hospitals = getState().getIn(["doctorRegister", "hospitals"]);
      dispatch(changeHospitals([...hospitals, ...result]));
      dispatch(changeHospitalsHasMore(result.length === limit));
    });
    pms.catch((error: any) => {
      Toast.fail(error);
    });
    pms.finally(() => dispatch(changeHospitalsLoading(false)));

    function getLocationId() {
      const regionValue = getState().getIn(["doctorRegister", "regionValue"]);
      if (regionValue) {
        return regionValue[1].value;
      }
      const regions = getState().getIn(["doctorRegister", "regions"]);
      const location = getState().getIn(["doctorRegister", "location"]);

      if (!location || regions.length <= 0) {
        return null;
      }
      let addressName = location.address.city;
      for (let i = 0; i < regions.length; i++) {
        const p = regions[i];
        for (let j = 0; j < p.children.length; j++) {
          const c = p.children[j];
          if (c.label === addressName) {
            return c.value;
          }
        }
      }
    }
  };
};

export const loadMoreHospitals = () => {
  return (dispatch: any, getState: any) => {
    const loading = getState().getIn(["doctorRegister", "hospitalsLoading"]);
    const hasMore = getState().getIn(["doctorRegister", "hospitalsHasMore"]);
    if (loading || !hasMore) {
      return;
    } else {
      const page = getState().getIn(["doctorRegister", "hospitalsPage"]);
      dispatch(changeHospitalsLoading(true));
      dispatch(changeHospitalsPage(page + 1));
      dispatch(loadHospitals());
    }
  };
};

export const handleChangeRegion = (region: any) => {
  return (dispatch: any) => {
    dispatch(changeHospitals([]));
    dispatch(changeHospitalsPage(1));
    dispatch(changeHospitalsHasMore(true));
    dispatch(changeRegionValue(region));
    dispatch(loadHospitals());
  };
};

export const cleanHospitals = () => {
  return (dispatch: any) => {
    dispatch(changeRegions(undefined));
    dispatch(changeRegionValue(undefined));
    dispatch(changeHospitals([]));
    dispatch(changeHospitalsHasMore(true));
    dispatch(changeHospitalsPage(1));
  };
};
