import { getToken } from "utils/storage";
import accountApi from "api/account";
import verifyApi from "api/verify";
import store from "../../reduxStore";


/**
 * 从store中获取用户账号信息
 */
export const getAccountInfo = (): DDHYAccount.AccountInfo | null => {
  if (store) {
    const state: any = store.getState();
    if (state) {
      return state.getIn(["account", "accountInfo"]);
    }
  }
  return null;
};

/**
 * 检查用户必填信息是否完整
 *
 * 只检查手机号(包含手机格式检查)
 */
export const checkRealInfo = (): boolean => {
  const accountInfo = getAccountInfo();

  if (accountInfo) {
    const phone = accountInfo.phone || "";
    let _phone = phone.replace(" ", "");

    // 验证忽略手机号码中的空格
    return _phone.length > 11 ? true : /^1[3456789]\d{9}$/.test(phone.replace(" ", ""));
  }
  return false;
};

/**
 * 当前账户是否为社区管理员
 */
export const isCommunityAdmin = (): boolean => {
  const accountInfo = getAccountInfo();
  if (accountInfo) {
    return accountInfo.sns_manager === 1;
  }
  return false;
};

/**
 * 当前账户在社区是否被禁言
 */
export const isCommunityBanned = (): boolean => {
  const accountInfo = getAccountInfo();
  if (accountInfo) {
    return accountInfo.sns_banned === 1;
  }
  return false;
};

/**
 * 静默登录
 *
 * * 根据token获取用户信息
 */
export const silentLogin = (pathname: string): Promise<DDHYAccount.AccountInfo> => {

  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      if (token) {
        const _accountInfo = await accountApi.getAccountInfoByToken();
        resolve(_accountInfo);
      }
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * 手机验证码登录
 *
 * @param phone 手机号码
 * @param verificationCode 验证码
 */
export const loginByVerifyCode = (
  phone?: string,
  verificationCode?: string
): Promise<DDHYAccount.AccountInfo> => {
  return new Promise(async (resolve, reject) => {
    if (!phone) {
      reject("请输入手机号");
      return;
    }
    if (!verificationCode) {
      reject("请输入短信验证码");
      return;
    }

    // 检查手机号码格式是否正确
    let checkPhone = RegExp(/^1[3456789]\d{9}$/).test(phone);
    if (!checkPhone) {
      reject("手机号码格式错误");
      return;
    }
    try {
      const _accountInfo = await verifyApi.loginByVerifyCode(
        phone,
        verificationCode
      );
      resolve(_accountInfo);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * 缓存医生邀请码
 *
 * 注意：缓存在sessionStorage中，程序关闭后就会清除
 *
 * @param inviteCode
 */
export function cacheInviteCode(inviteCode: string) {
  if (inviteCode) {
    sessionStorage.setItem("INVITE_CODE", inviteCode);
  }
}

/**
 * 从缓存中获取医生邀请码
 */
export function getInviteCodeFromCache() {
  return sessionStorage.getItem("INVITE_CODE");
}
