import React from "react";
import "./index.scss";

interface ToEndProps {
  tips?: string;
}
const ToEnd: React.FC<ToEndProps> = ({ tips }) => {
  return (
    <div className="To-End">
      <span className="Tips">{tips || "没有更多数据"}</span>
    </div>
  );
};

export default ToEnd;
