import { lazy } from "react";

const Order = lazy(() => import("./Order"));

const routes: DDHY.Route[] = [
  {
    path: "/order/:order_id",
    title: "订单详情",
    component: Order,
  },
];

export { routes };
