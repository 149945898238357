import { combineReducers } from "redux-immutable";
import { reducer as ArticleReducer } from "../Article/store";
import { reducer as AuthorReducer } from "../Author/store";
import { reducer as PopularScienceReducer } from "../PopularScience/store";
import { reducer as SpecialReducer } from "../Special/store";

export default combineReducers({
  article: ArticleReducer,
  author: AuthorReducer,
  popularScience: PopularScienceReducer,
  special: SpecialReducer,
});
