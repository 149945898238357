export const SET_USE_STATE = "home/SET_USE_STATE";    // 更新页面数据状态
export const SET_AGGREGATION_DATA = "home/SET_AGGREGATION_DATA";  // 更新特色专题列表
export const SET_TABBAR_INDEX = 'home/SET_TABBAR_INDEX';  // 更新已选中Tab 索引

// 更新推荐数据列表
export const SET_RECOMMEND_LIST_DATA = "home/SET_RECOMMEND_LIST_DATA";
export const SET_RECOMMEND_PAGE_DATA = "home/SET_RECOMMEND_PAGE_DATA";
export const SET_RECOMMEND_HAS_MORE_DATA = "home/SET_RECOMMEND_HAS_MORE_DATA";
export const SET_RECOMMEND_TIMESTAMP_DATA = "home/SET_RECOMMEND_TIMESTAMP_DATA";


// 更新文章列表数据
export const SET_ARTICLES_LIST_DATA = "home/SET_ARTICLES_LIST_DATA";
export const SET_ARTICLES_PAGE_DATA = "home/SET_ARTICLES_PAGE_DATA";
export const SET_ARTICLES_HAS_MORE_DATA = "home/SET_ARTICLES_HAS_MORE_DATA";
export const SET_ARTICLES_TIMESTAMP_DATA = "home/SET_ARTICLES_TIMESTAMP_DATA";

// 更新帖子列表数据
export const SET_POSTS_LIST_DATA = "home/SET_POSTS_LIST_DATA";
export const SET_POSTS_PAGE_DATA = "home/SET_POSTS_PAGE_DATA";
export const SET_POSTS_HAS_MORE_DATA = "home/SET_POSTS_HAS_MORE_DATA";
export const SET_POSTS_TIMESTAMP_DATA = "home/SET_POSTS_TIMESTAMP_DATA";

// 更新公告列表数据
export const SET_ANNOUNCEMENT_LIST_DATA = "home/SET_ANNOUNCEMENT_LIST_DATA";
export const SET_ANNOUNCEMENT_PAGE_DATA = "home/SET_ANNOUNCEMENT_PAGE_DATA";
export const SET_ANNOUNCEMENT_HAS_MORE_DATA = "home/SET_ANNOUNCEMENT_HAS_MORE_DATA";
export const SET_ANNOUNCEMENT_TIMESTAMP_DATA = "home/SET_ANNOUNCEMENT_TIMESTAMP_DATA";
