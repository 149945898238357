import { ROOT } from "common/constant";
import { urlFormat } from "./util";

// 广告跳转参数
export interface ParamsQuery{
  link_type: number;
  link_obj?: string;
  obj_id?: string;
  invite_code?: string;
  link_url?: string;
  youzan_url?: string;
}

// 组合广告目标链接地址
const makeAdLink = (objType = '', objId? : string, inviteCode? : string) => {
  let url = ''
  //关联类型 1咨询  4预约  6文章 7专题 8训练 9课程 10有赞 11药品12会员',

  switch(objType){
    case '10':
    case 'youzan':
      if(objId.indexOf('http') === 0){
        url = objId
      }else{
        url = 'https://h5.youzan.com/wscgoods/detail/'+objId
      }
      break;

    case 'topic':
      // 处理圈子
      url = '/community'
      if (objId) {
        url = '/topic/'+objId;
      }
      break;

    case 'post':
        // 处理帖子
        url = '/community'
        if (objId) {
          url = '/post/'+objId;
        }
        break;

    case '6':
    case 'article':
      // 处理文章
      url = '/classroom'
      if (objId) {
        url = '/article/'+objId;
      }
      break;

    case '8':
    case '9':
    case 'class':
    case 'health':
      // 处理训练
      url = '/trains'
      if (objId) {
        url = '/training/'+objId;
      }
      if(inviteCode){
        url += '?invite_code='+inviteCode;
      }
      break;

    case '4':
    case 'item':
    case 'reserve':
      // 预约项目
      url = '/projects'
      if (objId) {
        url = '/project/'+objId;
      }
      break;

    case 'hospital':
      // 处理医院
      url = '/hospitallrule'
      if (objId) {
        url = '/hospitaldetail/'+objId;
      }
      break;

    case 'doctor':
      // 处理医生
      url = '/doctors'
      if (objId) {
        url = '/doctor/'+objId;
      }
      break;

    case 'test':
      // 自测列表
      url = '/health-test'
      if (objId) {
        url = '/test/'+objId;
      }
      break;
    case 'test_detail':
      if (objId) {
        url = '/test/'+objId;
      }
      break;
  }
  return url;
};

// 处理广告点击跳转
const handleAdvClick = (item:ParamsQuery, history) => {
  if(item.link_type === 2){
    // 外部链接跳转
    let _url = urlFormat(item.link_url);
    let search = _url.split("?");
    let _fromStr = 'from=' + ROOT;
    if(search.length > 1){
      _url += "&" + _fromStr;
    }else{
      _url += '?' + _fromStr;
    }
    window.location.href = urlFormat(_url);
  } else {
    // 处理内部跳转
    if(item.link_obj === 'youzan' || item.link_obj === '10'){
      let linkUrl = makeAdLink(item.link_obj, item.youzan_url, item.invite_code);
      window.location.href = linkUrl;
    }else{
      let linkUrl = makeAdLink(item.link_obj, item.obj_id, item.invite_code);
      history.push(linkUrl);
    }
  }
};

// 内容中嵌入广告渲染
const goodsAdHtmlRanderHandler = (html = '') => {
  let nodeObj = document.createElement("div")
  nodeObj.innerHTML = html
  let adNodeList = nodeObj.getElementsByTagName('ddhygoods')

  if(adNodeList.length > 0){
    for(let i = 0; i < adNodeList.length; i++){
      let adNodeItem = adNodeList[i]
      let adAttrGoodsType = adNodeItem.getAttribute('data-goods_type')
      let adAttrGoodsId = adNodeItem.getAttribute('data-goods_id')
      let adAttrGoodsName = adNodeItem.getAttribute('data-goods_name')

      // 广告图片
      let adCardNode = document.createElement("div")
      adCardNode.innerHTML = adNodeItem.innerHTML
      adCardNode.setAttribute('class', 'ddhy-ad-goods-card')

      // 广告标题
      let adTitleNode = document.createElement("div")
      adTitleNode.innerHTML = adAttrGoodsName

      adCardNode.appendChild(adTitleNode)

      // 添加链接
      let adLinkNode = document.createElement('a')
      adLinkNode.setAttribute('href', makeAdLink(adAttrGoodsType, adAttrGoodsId))
      adLinkNode.append(adCardNode)

      adNodeItem.replaceChild(adLinkNode, adNodeItem.childNodes[0])
    }
    return nodeObj.innerHTML
  }else{
    return html
  }
};


export {
  makeAdLink,
  handleAdvClick,
  goodsAdHtmlRanderHandler
}
