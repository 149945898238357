import * as constants from "./constants";

export const changeUseState = (data: boolean) => ({
  type: constants.SET_USE_STATE,
  data: data,
});
export const changeTags = (data?: any[]) => ({
  type: constants.SET_TAGS,
  data: data,
});
export const changeRegions = (data?: any[]) => ({
  type: constants.SET_REGIONS,
  data: data,
});
export const changeFilter = (data?: any) => ({
  type: constants.SET_FILTER,
  data: data,
});

// 医生列表页面数据状态
export const changeHasMore = (data: boolean) => ({
  type: constants.SET_HASMORE,
  data: data,
});
export const changeDoctorList = (data?: any[]) => ({
  type: constants.SET_DOCTOR_LIST,
  data: data,
});

// 医生列表
export const changeDocotrPage = (data: number) => ({
  type: constants.SET_DOCTORPAGE,
  data: data,
});
export const changeDocotrHasMore = (data: boolean) => ({
  type: constants.SET_DOCTORHASMORE,
  data: data,
});

// 推荐医生列表
export const changeRecommendPage = (data: number) => ({
  type: constants.SET_RECOMMENDPAGE,
  data: data,
});
export const changeRecommendHasMore = (data: boolean) => ({
  type: constants.SET_RECOMMENDHASMORE,
  data: data,
});
export const changeHasRecommend = (data: boolean) => ({
  type: constants.SET_HASRECOMMEND,
  data: data,
});

