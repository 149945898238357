import { routes as HomeRoutes } from "screens/Home/routes";
import { routes as AccountRoutes } from "screens/Account/routes";
import { routes as CommunityRoutes } from "screens/Community/routes";
import { routes as AggregationRoutes } from "screens/Aggregation/routes";
import { routes as ClassroomRoutes } from "screens/Classroom/routes";
import { routes as DoctorRoutes } from "screens/Doctor/routes";
import { routes as ConsultRoutes } from "screens/Consult/routes";
import { routes as TestRoutes } from "screens/HealthTest/routes";
import { routes as HelpRoutes } from "screens/Help/routes";
import { routes as IntegralRoutes } from "screens/Integral/routes";
import { routes as MessageRoutes } from "screens/Message/routes";
import { routes as OthersRoutes } from "screens/Others/routes";
import { routes as ProfileRoutes } from "screens/Profile/routes";
import { routes as ProjectRoutes } from "screens/Project/routes";
import { routes as ReservationRoutes } from "screens/Reservation/routes";
import { routes as SearchRoutes } from "screens/Search/routes";
import { routes as UserAgreementRoutes } from "screens/UserAgreement/routes";
import { routes as DiagnosticRoutes } from "screens/Diagnostic/routes";
import { routes as DioctorRegisterRoutes } from "screens/DoctorRegister/routes";
import { routes as OrderRoutes } from "screens/Order/routes";
import { routes as HospitalRoutes } from "screens/Hospital/routes";
import { routes as TrainingRoutes } from "screens/Training/routes";
import { routes as DistributorRoutes } from "screens/Distributor/routes";
import { routes as ExchangeRoutes } from "screens/Exchange/routes";
import { routes as AppStoreRoutes } from "screens/AppStore/routes";
import { routes as ActivityRoutes } from "screens/Activity/routes";
import { routes as VipRoutes } from "screens/Vip/router";

// 导航布局的页面路由
export const routesWithNavigation = [
  "/home",
  "/train",
  "/trains",
  "/doctors",
  "/message",
  "/profile",
  "/community",
  "/classroom",
  "/health-test",
  "/projects",
  "/aggregation",
];

const routes: DDHY.Route[] = [
  ...HomeRoutes,
  ...AccountRoutes,
  ...CommunityRoutes,
  ...AggregationRoutes,
  ...ClassroomRoutes,
  ...DoctorRoutes,
  ...ConsultRoutes,
  ...TestRoutes,
  ...HelpRoutes,
  ...IntegralRoutes,
  ...MessageRoutes,
  ...OthersRoutes,
  ...ProfileRoutes,
  ...ProjectRoutes,
  ...ReservationRoutes,
  ...SearchRoutes,
  ...UserAgreementRoutes,
  ...DiagnosticRoutes,
  ...DioctorRegisterRoutes,
  ...OrderRoutes,
  ...HospitalRoutes,
  ...TrainingRoutes,
  ...DistributorRoutes,
  ...ExchangeRoutes,
  ...AppStoreRoutes,
  ...ActivityRoutes,
  ...VipRoutes,
];

export default routes;
