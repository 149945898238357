import * as actionTypes from "./constants";

// 社区广场关注页数据结构
export interface FollowedState {
  posts?: DDHY.SNSPost[];
  page?: number;
  hasMore?: boolean;
}

// 社区广场最热页数据结构
export interface HotState {
  posts?: DDHY.SNSPost[];
  page?: number;
  hasMore?: boolean;
}

// 社区广场最新页数据结构
export interface NewState {
  posts?: DDHY.SNSPost[];
  page?: number;
  hasMore?: boolean;
}

// 社区广场圈子页数据结构
export interface TopicState {
  topics?: DDHY.SNSTopic[];
  page?: number;
  hasMore?: boolean;
}
// 社区广场页数据结构
export interface SquareState {
  tabIndex: number;
  topic: TopicState;
  new: NewState;
  hot: HotState;
  followed: FollowedState;
}

// 默认数据
const defaultState: SquareState = {
  tabIndex: 1,
  topic: {
    page: 1,
    hasMore: true,
  },
  new: {
    page: 1,
    hasMore: true,
  },
  hot: {
    page: 1,
    hasMore: true,
  },
  followed: {
    page: 1,
    hasMore: true,
  },
};

// 导出reducer
export default (state = defaultState, action: any): SquareState => {
  switch (action.type) {
    // SquareState
    case actionTypes.SET_TAB_INDEX:
      return { ...state, tabIndex: action.data };
    // SquareState -> Topic
    case actionTypes.SET_TOPICS:
      return { ...state, topic: { ...state.topic, topics: action.data } };
    case actionTypes.SET_TOPIC_PAGE:
      return { ...state, topic: { ...state.topic, page: action.data } };
    case actionTypes.SET_TOPIC_HASMORE:
      return { ...state, topic: { ...state.topic, hasMore: action.data } };
    // SquareState -> New
    case actionTypes.SET_NEW_POSTS:
      return { ...state, new: { ...state.new, posts: action.data } };
    case actionTypes.SET_NEW_PAGE:
      return { ...state, new: { ...state.new, page: action.data } };
    case actionTypes.SET_NEW_HASMORE:
      return { ...state, new: { ...state.new, hasMore: action.data } };
    // SquareState -> Hot
    case actionTypes.SET_HOT_POSTS:
      return { ...state, hot: { ...state.hot, posts: action.data } };
    case actionTypes.SET_HOT_PAGE:
      return { ...state, hot: { ...state.hot, page: action.data } };
    case actionTypes.SET_HOT_HASMORE:
      return { ...state, hot: { ...state.hot, hasMore: action.data } };
    // SquareState -> Followed
    case actionTypes.SET_FOLLOWED_POSTS:
      return { ...state, followed: { ...state.followed, posts: action.data } };
    case actionTypes.SET_FOLLOWED_PAGE:
      return { ...state, followed: { ...state.followed, page: action.data } };
    case actionTypes.SET_FOLLOWED_HASMORE:
      return {
        ...state,
        followed: { ...state.followed, hasMore: action.data },
      };

    default:
      return state;
  }
};
