import { useRef, useReducer, useCallback, useEffect } from "react";

/**
 * 倒计时hooks
 *
 * @param target 目标值
 * @param delay 倒计时间隔时间（ms）默认1000
 *
 * @return count {number} 当前倒计时的值
 * @return start {Function} 启动倒计时
 */
export default function useCountDown(
  target: number,
  delay?: number
): [number, Function] {
  // 定时器引用
  const intervalRef = useRef<number | undefined>(void 0);

  // 由于setinterval 和 reacthooks之间的不和谐，所以需要使用 useReducer 来储存和计算倒计时的值
  // 参考 https://stackoverflow.com/questions/53024496/state-not-updating-when-using-react-state-hook-within-setinterval
  const [count, dispatch] = useReducer(
    (state: number, action: { type: "minus" | "reset" }) => {
      // reset: 将state置为目标值
      if (action.type === "reset") return target;

      // minus： 将state值减1， 如果减完后小于等于0，则清除定时器，本轮倒计时结束
      if (action.type === "minus") {
        const newValue = state - 1;
        if (newValue <= 0 && intervalRef) {
          clearInterval(intervalRef.current);
        }
        return newValue;
      }
      return state;
    },
    0
  );

  // 清除倒计时定时器
  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = void 0;
      }
    };
  }, []);

  /**
   * 启动倒计时
   */
  const start = useCallback((): void => {
    // 将倒计时值设置为目标值
    dispatch({ type: "reset" });
    // 使用定时器按delay间隔执行minus操作
    intervalRef.current = window.setInterval(() => {
      dispatch({ type: "minus" });
    }, delay || 1000);
  }, [delay]);

  return [count, start];
}
