import React, { memo, useCallback, useRef, useState } from "react";
import { getImageUrl } from "../../../utils/util";
import { useHistory } from "react-router";
import { Icon } from "../../basic";
import VoicePlay from "../../basic/VoicePlay";
import { makeAdLink } from "utils/ddhyAdRender";

import "./index.scss";

export const typeMap: any = {
  1: "text",
  2: "image",
  3: "healthTest",
  4: "changeReserveTime",
  5: "voice",
  6: "cancelReservation",
  7: "testDetails",
  8: "goods",
  99: "system",
};

export const messageTypeString: any = {
  1: "text", //文本信息
  2: "image", // 图片信息
  3: "testing", // 自测题
  5: "voice", // 语音信息
  7: "tested", // 历史自测
  8: "medicine", // 药品信息
  9: "inquiry", // 智能问诊
  99: "system", // 系统消息
};

export const messageTypeNumber: any = {
  "text": 1, //文本信息
  "image": 2, // 图片信息
  "testing": 3, // 自测题
  "voice": 5, // 语音信息
  "tested": 7, // 历史自测
  "medicine": 8, // 药品信息
  "inquiry": 9, // 药品信息
  "system": 99, // 系统消息
};

export interface ChatMessageDataProps {
  type:
    | "text"
    | "image"
    | "healthTest"
    | "testing"
    | "changeReserveTime"
    | "voice"
    | "cancelReservation"
    | "testDetails"
    | "tested"
    | "goods"
    | "medicine"
    | "system";
  source: "consult" | "reservation";
  sourceID: any; // 消息来源ID 咨询ID/预约ID
  messageID: any; // 消息ID
  content: any;
  position: "left" | "right" | "center";
  messageTime?: string;
  user: {
    avatar: string;
    nickname?: string;
  };
  onClickUser?(): void;
  onImageClick?(): void;
}

interface ChatMessageProps {
  data: ChatMessageDataProps;
  privacy?: boolean; // 隐私状态 如果为true，隐藏右边userInfo和替换image
}
const defaultAvatar = (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
  >
    <path
      d="M512.128 1024A512 512 0 1 1 512.064 0.064 512 512 0 0 1 512.128 1024zM510.912 165.376a174.336 174.336 0 0 0-173.952 174.656c0 96.512 77.888 174.656 173.952 174.656s174.016-78.144 174.016-174.656a174.4 174.4 0 0 0-174.016-174.656z m146.304 362.816c-40.512 32.128-90.816 65.92-143.552 65.92-53.376 0-104.128-34.112-144.896-67.008-107.392 39.616-155.328 177.856-155.328 240.96h597.312c0-62.592-48.256-199.808-153.536-239.872z"
      p-id="5508"
      fill="#bfbfbf"
    ></path>
  </svg>
);

const ChatMessage: React.FC<ChatMessageProps> = (props) => {
  const { privacy, data } = props;

  const {
    type,
    content,
    // source,
    // sourceID,
    messageID,
    position,
    user,
    messageTime,
  } = data;
  const { onClickUser, onImageClick } = data;



  const RenderMessage = useCallback(() => {
    function htmlReplace(contentStr){
      return contentStr.replace(/(\r\n)|(\n)/g, "<br/>");
    }

    switch (type) {
      case "text":
        return <div className="Text" dangerouslySetInnerHTML={{ __html: htmlReplace(content) }}></div>;
      case "system":
        return (
          <div className="System">
            {content['text']}
          </div>
        );
      case "image":
        if (privacy && position === "right") {
          return (
            <div className="Image privacy">
              <Icon size={48} color="grey">
                picture
              </Icon>
              <p>图片涉及用户隐私，已被系统隐藏</p>
            </div>
          );
        } else {
          return (
            <div className="Image">
              <img
                onClick={() => onImageClick && onImageClick()}
                src={getImageUrl(content)}
                alt=""
              />
            </div>
          );
        }
      case "testing":
      case "testDetails":
        return <div style={{
          color: "#333",
          backgroundColor: '#efefef',
          padding: '10px',
          borderRadius: '8px',
        }}>【暂不支持该自测消息，请前往APP查看处理】</div>;
      case "voice":
        return <Voice id={messageID} src={content} />;
      case "goods":
      case "medicine":
        return <Goods data={content} />;

      // case "changeReserveTime":
      //   let data = JSON.parse(content);
      //   return (
      //     <ChangeReserveTime
      //       data={data}
      //       detailID={messageID}
      //       reserveID={sourceID}
      //     />
      //   );
      // case "cancelReservation":
      //   return <CancelReservation data={JSON.parse(content)} />;

      default:
        return <div style={{
          color: "#333",
          backgroundColor: '#efefef',
          padding: '10px',
          borderRadius: '8px',
        }}>【暂不支持该消息类型，请前往APP查看处理】</div>;
    }
  }, [type, content, privacy, position, messageID, onImageClick]);

  return (
    <div className="Component-ChatMessage">
      <div className="ChatMessage-Header">
        {messageTime && <div className="MessageTime">{messageTime}</div>}
      </div>
      {position === "center" ? (
        <div className="Chat-Content">
          <RenderMessage />
        </div>
      ) : (
        <div className={`ChatMessage-Body ${position}`}>
          <div className="UserAvatar">
            {privacy && position === "right" ? (
              defaultAvatar
            ) : (
              <img
                src={getImageUrl(user.avatar)}
                onClick={() => onClickUser && onClickUser()}
                alt="头像"
              />
            )}
          </div>
          <div className="Chat-Content">
            <RenderMessage />
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * 渲染商品详情
 *
 * @param data 商品数据
 */
const Goods: React.FC<{ data: any[] }> = (props) => {
  const { data } = props;
  let goodsList = Object.values(data);
  let history = useHistory();

  const RenderGoods = (item: any, index: number) => {
    if (item) {
      const GoodsImage = (
        <div className="Goods-Image">
          <img src={getImageUrl(item.goods_images)} alt="" />
        </div>
      );

      /// 商品名称
      const GoodsName = (
        <div className="Text-Line__2 Goods-Name">{item.goods_title}</div>
      );

      return (
        <div key={'goods_' + index + '_' + item.goods_id} className="Goods-Item" onClick={() => {
            if (item.goods_url) {
              window.location.href = item.goods_url;
            }
            if(item.relevance_id > 0){
              let url = makeAdLink(`${item.relevance_type}`, `${item.relevance_id}`)
              history.push(url);
            }
          }}
        >
          {GoodsImage}
          {GoodsName}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="Goods-Wrap">
      {goodsList.map((item: any, index: number) => RenderGoods(item, index))}
    </div>
  );
};

/**
 * 渲染语音消息
 *
 * @param src 消息媒体文件src
 * @param id 聊天ID
 */
const Voice: React.FC<{
  src: string;
  id: any;
}> = (props) => {
  const { src, id } = props;
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState<number>(); // 单条语音总时长，单位:s 注意，此属性只能在媒体加载完毕后才能获取到，而ios不支持预加载
  const voiceRef = useRef(null);

  const handlePlay = useCallback(() => {
    if (voiceRef && voiceRef.current) {
      const audioDom: any = voiceRef.current;
      if (audioDom.paused) {
        audioDom.play(); //audio.play();// 这个就是播放
      } else {
        audioDom.pause();
      }
    }
  }, [voiceRef]);

  /**
   * 暂停其他audio
   *
   * @param current 当前的audio对象
   */
  const pauseOther = useCallback((current: any) => {
    // 获取所有audios
    var audios = document.getElementsByTagName("audio");
    [].forEach.call(audios, function (i: any) {
      if (i !== current && !i.paused) {
        i.pause();
      }
    });
  }, []);

  return (
    <div className="Voice" onClick={handlePlay}>
      <audio
        ref={voiceRef}
        src={src}
        preload="metadata"
        id={id}
        onLoadedMetadata={(e) => {
          let { currentTarget } = e;
          setDuration(currentTarget.duration);
        }}
        onPlaying={() => {
          setPlaying(true);
          pauseOther(voiceRef.current);
        }}
        onPause={() => setPlaying(false)}
      />

      <VoicePlay play={playing} />
      {duration && <span className="Duration"> {Math.ceil(duration)}</span>}
    </div>
  );
};

export default memo(ChatMessage);

/**
 * 渲染修改预约时间
 *
 * @param detailID  预约对话详情ID
 * @param reserveID  预约ID
 */
// const ChangeReserveTime: React.FC<{
//   data: any;
//   detailID: number;
//   reserveID: number;
// }> = (props) => {
//   const { reserveID, detailID } = props;
//   const [data, setData] = useState(props.data);
//   /**
//    * 响应医生的变更预约时间请求
//    *
//    * @param {int} status 响应状态状态 1同意，2拒绝
//    */
//   const handleConfirm = useCallback(
//     (status: number) => {
//       const pms = reservationApi.changTimeHandle({
//         reserve_id: reserveID,
//         confirm_reserve_time_status: status,
//         detail_id: detailID,
//       });
//       pms
//         .then(() => {
//           if (status === 1) {
//             let newData = { ...data, is_finish: true, is_confirm: true };
//             setData(newData);
//             Toast.success("预约时间修改成功！");
//           } else if (status === 2) {
//             let newData = { ...data, is_finish: true, is_confirm: false };
//             setData(newData);
//             Toast.info("您拒绝了修改预约时间！");
//           }
//         })
//         .catch((error) => {
//           Toast.fail(error);
//         });
//     },
//     [reserveID, detailID, data]
//   );
//   return (
//     <div className="ChangeReserveTime">
//       <div className="ChangeReserveTime-Title">医生申请修改预约时间</div>
//       <Flex direction="column" align="start" className="ChangeReserveTime-Body">
//         <Flex className="Row" align="start">
//           <div className="Label">预约时间：</div>
//           <Flex.Item>
//             {!data.old_reservation_time_area ||
//             data.old_reservation_time_area.length <= 0
//               ? timestamp2time(data.old_reservation_date, "MM月dd日 hh:mm")
//               : timestamp2time(data.old_reservation_date, "MM月dd日") +
//                 " " +
//                 data.old_reservation_time_area}
//           </Flex.Item>
//         </Flex>
//         <Flex className="Row" align="start">
//           <div className="Label">更改为：</div>
//           <Flex.Item>
//             {!data.confirm_reserve_time_area ||
//             data.confirm_reserve_time_area.length <= 0
//               ? timestamp2time(data.confirm_reserve_date, "MM月dd日 hh:mm")
//               : timestamp2time(data.confirm_reserve_date, "MM月dd日") +
//                 " " +
//                 data.confirm_reserve_time_area}
//           </Flex.Item>
//         </Flex>
//         <Flex className="Row" align="start">
//           <div className="Label">更改理由：</div>
//           <Flex.Item>
//             {data.confirm_reserve_time_reason || "未说明更改理由"}
//           </Flex.Item>
//         </Flex>
//       </Flex>
//       {data.is_finish ? (
//         <div className="ChangeReserveTime-Footer">
//           {data.is_confirm ? (
//             <span style={{ color: "#ccc" }}>您已同意</span>
//           ) : (
//             <span style={{ color: "#ccc" }}>您已拒绝</span>
//           )}
//         </div>
//       ) : (
//         <Flex className="ChangeReserveTime-Footer">
//           <Flex.Item>
//             <Button
//               size="small"
//               className="flat"
//               onClick={() => handleConfirm(2)}
//             >
//               <span style={{ color: "red" }}>拒绝</span>
//             </Button>
//           </Flex.Item>
//           <Flex.Item>
//             <Button
//               size="small"
//               className="flat"
//               onClick={() => handleConfirm(1)}
//             >
//               <span style={{ color: "#0099FF" }}>同意</span>
//             </Button>
//           </Flex.Item>
//         </Flex>
//       )}
//     </div>
//   );
// };

// const CancelReservation: React.FC<{
//   data: any;
// }> = (props) => {
//   const { data } = props;

//   return (
//     <div className="CancelReservation">
//       <div className="Header">申请取消预约</div>
//       <div className="Footer">
//         {data.is_handle ? (
//           data.is_confirm ? (
//             <div className="Success">医生已同意</div>
//           ) : (
//             <div className="Error">医生已拒绝</div>
//           )
//         ) : (
//           <div className="Default">待处理</div>
//         )}
//       </div>
//     </div>
//   );
// };
/**
 * 渲染健康自测请求框
 *
 * @param data 自测JSON数据
 * @param source 对话来源 consult or reservation
 * @param sourceID 对话来源ID
 */
// const HealthTest: React.FC<{
//   data: any[];
//   source: string;
//   sourceID: any;
// }> = (props) => {
//   const history = useHistory();
//   const { data, source, sourceID } = props;
//   return (
//     <div className="HealthTest">
//       <div className="HealthTest-Title">医生建议您完成以下自测</div>
//       <Flex direction="column" className="HealthTest-Wrap">
//         {data.map((item: any, index: number) => (
//           <div key={index} className="HealthTest-Item">
//             <Flex justify="between" wrap="nowrap">
//               <Flex.Item>
//                 <div className="Test-Name">{item.describe}</div>
//               </Flex.Item>
//               <div style={{ paddingLeft: "12px" }}>
//                 {item.is_finish === 1 ? (
//                   <span
//                     className="Link"
//                     onClick={() => {
//                       history.push(
//                         `/test/${item.id}?source=${source}&source_id=${sourceID}`
//                       );
//                     }}
//                   >
//                     去测试
//                   </span>
//                 ) : (
//                   <span className="Success">已完成</span>
//                 )}
//               </div>
//             </Flex>
//           </div>
//         ))}
//       </Flex>
//     </div>
//   );
// };
/**
 * 渲染自测详情
 *
 * @param data 自测数据
 */
// const TestDetails: React.FC<{ data: any[]; source: string }> = (props) => {
//   const { data, source } = props;
//   const history = useHistory();

//   return (
//     <div className="TestDetails-Wrap">
//       {data.map((item: any, index: number) => (
//         <div key={index} className="TestDetails">
//           <div className="Title">{item.paper_name}</div>
//           <div>测试时间：{timestamp2time(item.createtime)}</div>
//           <div>综合评分：{item.paper_score}</div>
//           <div
//             dangerouslySetInnerHTML={{
//               __html: item.paper_result,
//             }}
//           ></div>
//           <div
//             className="Button"
//             onClick={() => {
//               history.push(`/test/result/${item.result_id}?source=${source}`);
//             }}
//           >
//             查看详情
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };



