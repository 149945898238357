export const SET_NAV_HEIGHT = "project/SET_NAV_HEIGHT";
export const SET_TAGS = "project/SET_TAGS";
export const SET_CURRENT_TAG = "project/SET_CURRENT_TAG";
export const SET_CATES = "project/SET_CATES";
export const SET_CURRENT_CATE = "project/SET_CURRENT_CATE";
export const SET_MORE_TAG = "project/SET_MORE_TAG";
export const SET_CITY = "project/SET_CITY";
export const SET_PAGE = "project/SET_PAGE";
export const SET_HASMORE = "project/SET_HASMORE";
export const SET_PROJECT_LIST = "project/SET_PROJECT_LIST";
