import * as actionTypes from "./constants";

export interface HealthTestState {
  testList?: Array<any>;
}

export default (state = {}, action: any) => {
  switch (action.type) {
    case actionTypes.SET_TEST_LIST:
      return { ...state, testList: action.data };

    default:
      return state;
  }
};
