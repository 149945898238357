import { getResult } from "utils/request";
/**
 * 查询医生的服务项目详细信息
 * @param {int} hospital_id
 */
// const getDetails = (hospital_id: string | number) => {
//   return getResult({
//     url: "/hospital/detail" + hospital_id,
//   });
// };

export interface QueryParamshos {
  page?: string | number;
  limit?: string | number;
  city_name?: string;
  hospital_address_id?: string | number;
  skill_id?:string;
  from:string;}
const hospitalApi = {
  /**
   * 查询医院列表
   */
  getHospitalList(params: QueryParamshos) {
    return getResult({
      url: `/v2/hospital`,
      params,
    });
  },
  /**
   * 获取医院详情
   * @param hospital_id
   * @returns
   */
  getHospitalDetails(hospital_id: number){
    return getResult({
      url: `/v2/hospital/${hospital_id}`,
    });
  },
  // 获取医院特色技术树
  getskill() {
    return getResult({
      url: `/v2/hospital/skill`,
    });
  },






  //
  getRegionsTree() {
    return getResult({
      url: `/hasHospitalPlace`,
    });
  },
  getRegionsTrees() {
    return getResult({
      url: `/area/tree`,
    });
  },

  /**
   * 查询医院等级列表
   */
  getHospitalLevels() {
    return getResult({
      url: `/hospital/level`,
    });
  },

  /**
   * 添加医院
   */
  create(data: any) {
    return getResult({
      url: `/hospital`,
      data,
      method: "post",
    });
  },
};

export default hospitalApi;
