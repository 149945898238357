import { getResult } from "utils/request";

const reservationApi = {
  /**
   * 查询预约详情
   *
   */
  getDetails(reservation_id: any): Promise<any> {
    return getResult({
      url: `/v2/ask/detail`,
      params : {
        type: 'reserve',
        reserve_id: reservation_id
      }
    });
  },

  /**
   * 确定是否同意医生修改预约时间
   *
   */
  // changTimeHandle(params: {
  //   reserve_id: number; // 预约ID
  //   confirm_reserve_time_status: number; // 预约状态 1可以，2申请更换时间
  //   detail_id: number; // 聊天消息ID
  // }): Promise<any> {
  //   return getResult({
  //     needLogin: true,
  //     needRealInfo: true,
  //     url: `/api/ask/reservation/confirm`,
  //     params,
  //     method: "put",
  //   });
  // },

  /**
   * 查看取消预约进度
   */
  getRefundProgress(reservation_id: any): Promise<any> {
    return getResult({
      needLogin: true,
      url: `/api/ask/reservation/refund/${reservation_id}`,
    });
  },
};

export default reservationApi;
