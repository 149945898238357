import React from "react";
import "./index.scss";

const Divider: React.FC<{ height?: number }> = ({ height }) => {
  return (
    <div
      className="Component-Divider"
      style={{
        margin: height ? `${height}px 0` : 0
      }}
    ></div>
  );
};

export default Divider;
