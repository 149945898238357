import { lazy } from "react";

const Operator = lazy(() => import("./About/Operator"));
const DeviceInfo = lazy(() => import("./DeviceInfo"));

const routes: DDHY.Route[] = [
  {
    path: "/device",
    title: "设备信息",
    component: DeviceInfo,
  },
  {
    path: "/about/operator",
    title: "联系丁丁小运",
    component: Operator,
  },
];

export { routes };
