import * as consts from "./constants";
import { Toast } from "antd-mobile";
import topicApi from "api/topic";
import memberApi from "api/member";
import { communityState } from "./reducer";
import postApi from "api/post";

export const changeTabIndex = (data: number) => ({
  type: consts.SET_TAB_INDEX,
  data: data,
});

// MyCommunity
// MyCommunity -> MyPost
export const changPostList = (data?: DDHY.SNSPost[]) => ({
  type: consts.SET_POST_LIST,
  data: data,
});
export const changePostPage = (data: number) => ({
  type: consts.SET_POST_PAGE,
  data: data,
});
export const changePostHasMore = (data: boolean) => ({
  type: consts.SET_POST_HASMORE,
  data: data,
});

/**
 * 清除我的帖子页面State
 *
 * @component MyCommunity -> MyPost
 */
export const cleanMyPost = () => {
  return (dispatch: any) => {
    dispatch(changPostList());
    dispatch(changePostPage(1));
    dispatch(changePostHasMore(true));
  };
};

// MyCommunity -> FollowedTopic
export const changeTopicList = (data?: DDHY.SNSTopic[]) => ({
  type: consts.SET_TOPIC_LIST,
  data: data,
});
export const changeTopicPage = (data: number) => ({
  type: consts.SET_TOPIC_PAGE,
  data: data,
});
export const changeTopicHasMore = (data: boolean) => ({
  type: consts.SET_TOPIC_HASMORE,
  data: data,
});

/**
 * 清除我关注的圈子页面State
 *
 * @component MyCommunity -> FollowedTopic
 */
export const cleanFollowedTopic = () => {
  return (dispatch: any) => {
    dispatch(changeTopicList());
    dispatch(changeTopicPage(1));
    dispatch(changeTopicHasMore(true));
  };
};

// MyCommunity -> FollowedMember
export const changeMemberList = (data?: DDHY.SNSUserInfo[]) => ({
  type: consts.SET_MEMBER_LIST,
  data: data,
});
export const changeMemberPage = (data: number) => ({
  type: consts.SET_MEMBER_PAGE,
  data: data,
});
export const changeMemberHasMore = (data: boolean) => ({
  type: consts.SET_MEMBER_HASMORE,
  data: data,
});

/**
 * 清除我关注的用户页面State
 *
 * @component MyCommunity -> FollowedMember
 */
export const cleanFollowedMember = () => {
  return (dispatch: any) => {
    dispatch(changeMemberList());
    dispatch(changeMemberPage(1));
    dispatch(changeMemberHasMore(true));
  };
};

/**
 * 清除我的社区页面State
 *
 * @component MyCommunity
 */
export const cleanMyCommunity = () => {
  return (dispatch: any) => {
    dispatch(changeTabIndex(1));
    dispatch(cleanMyPost());
    dispatch(cleanFollowedMember());
    dispatch(cleanFollowedTopic());
  };
};

/**
 * 加载我的帖子数据
 *
 */
export const loadMyPosts = (callback?: Function) => {
  return async (dispatch: any, getState: any) => {
    const state: communityState = getState().getIn(["profile", "myCommunity"]);
    const { postList, postPage } = state;
    const limit = 10;
    try {
      const { data } = await postApi.queryPostList({
        type: "ucenter",
        page: postPage,
        limit,
      });

      if (postPage === 1 || !postList) {
        dispatch(changPostList(data));
      } else {
        dispatch(changPostList([...postList, ...data]));
      }
      dispatch(changePostHasMore(data.length === limit));
    } catch (error) {
      Toast.info(error);
    } finally {
      callback && callback();
    }
  };
};

/**
 * 从缓存中移除指定的帖子
 *
 * @component MyCommunity -> MyPost
 */
export const   removePost = (post: DDHY.SNSPost) => {
  return (dispatch: any, getState: any) => {
    const postList: DDHY.SNSPost[] = getState().getIn([
      "profile",
      "myCommunity",
      "postList",
    ]);
    if (postList) {
      const index = postList.findIndex((r) => r.post_id === post.post_id);
      if (index >= 0) {
        const newRows = [...postList];
        newRows.splice(index, 1);
        dispatch(changPostList(newRows));
      }
    }
  };
};

/**
 * 加载我关注的圈子
 *
 * @component MyCommunity -> FollowedTopic
 */
export const loadFollowedTopics = (callback?: Function) => {
  return (dispatch: any, getState: any) => {
    const limit = 10;

    const state: communityState = getState().getIn(["profile", "myCommunity"]);
    const { topicList, topicPage } = state;

    topicApi
      .userFollowed({
        page: topicPage,
        limit,
      })
      .then((result: any) => {
        if (topicPage === 1 || !topicList) {
          dispatch(changeTopicList(result.data));
        } else {
          dispatch(changeTopicList([...topicList, ...result.data]));
        }
        dispatch(changeTopicHasMore(result.data.length === limit));
      })
      .catch((error) => {
        Toast.info(error);
      })
      .finally(() => {
        callback && callback();
      });
  };
};

/**
 * 加载关注用户的数据
 *
 * @component MyCommunity -> FollowedMember
 */
export const loadFollowedMembers = (callback?: Function) => {
  return async (dispatch: any, getState: any) => {
    const limit = 10;
    const state: communityState = getState().getIn(["profile", "myCommunity"]);
    const { memberList, memberPage } = state;
    memberApi
      .userFollowed({
        page: memberPage,
        limit,
      })
      .then((result: any) => {
        if (memberPage === 1 || !memberList) {
          dispatch(changeMemberList(result.data));
        } else {
          dispatch(changeMemberList([...memberList, ...result.data]));
        }
        dispatch(changeMemberHasMore(result.data.length === limit));
      })
      .catch((error) => {
        Toast.info(error);
      })
      .finally(() => {
        callback && callback();
      });
  };
};
