import ConsultHelp from "./ConsultHelp";
import ReservationHelp from "./ReservationHelp";

const routes: DDHY.Route[] = [
  {
    path: "/help/consult",
    title: "咨询帮助",
    component: ConsultHelp,
  },
  {
    path: "/help/reservation",
    title: "预约帮助",
    component: ReservationHelp,
  },
];

export { routes };
