import { getResult } from "utils/request";

const healthTestApi = {
  /**
   * 获取所有测试列表
   */
  allTestQuestions() {
    return getResult({
      url: `/v2/test/list`,
      params:{
        page: 1,
        limit: 50,
      }
    });
  },

  /**
   * 获取测试题详细内容
   * @param id 测试题ID
   */
  getTestQuestionDetails(id: number | string) {
    return getResult({
      needLogin: true,
      url: `/v2/test/${id}`,
    });
  },

  /**
   * 获取用户的测试记录
   */
  getUserTestRecords(params: {
    page: number;
    limit: number;
  }) {
    return getResult({
      needLogin: true,
      url: `/v2/member/test_list`,
      params
    });
  },

  /**
   * 获取测试结果内容
   * @param test_record_id 测试记录ID
   */
  getTestDetailResult(test_record_id: number | string) {
    return getResult({
      needLogin: true,
      url: `/v2/member/test_basics/detail/${test_record_id}`,
    });
  },

  /**
   * 获取测试结果广告
   * @param test_record_id
   * @returns
   */
  getTestDetailAdv(test_record_id: number | string) {
    return getResult({
      needLogin: true,
      url: `/v2/member/test_adv/detail/${test_record_id}`,
    });
  },

  /**
   * 提交测试答卷
   * @param {Object} data 提交内容
   */
  postTest(data: {
    paper_id: number; //  测试 ID
    paper_score: number; //  测试得分
    question_answer: string; //  详细回答
    consult_detail_id?: number;  // 咨询/预约编号
  }) {
    return getResult({
      needLogin: true,
      url: `/v2/test/result`,
      data,
      method: "post",
      needRealInfo: true,
    });
  },


  /**
   * 打印测试结果内容
   */
  printResult(data: {
    sn: string; //  打印机编码
    result_id: number | string; //  测试记录ID
  }) {
    return getResult({
      url: `/api/test/test/print`,
      data,
      method: "post",
      needRealInfo: true,
    });
  },

};

export default healthTestApi;
