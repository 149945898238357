import React, { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import BasicLayout from "./layouts/BasicLayout"; // 常规布局
import NavLayout from "./layouts/NavLayout"; // 带有底部导航的布局
import { SDKRegister } from "utils/weixin";
import { changeWxSDK } from "./reduxStore/store/actionCreators";
import { routesWithNavigation } from "./routes";
import { device } from "utils/util";
import PopupLogin from "components/global/PopupLogin";
import { Toast } from "antd-mobile";


const App: React.FC = () => {
  const dispatch = useDispatch();

  let isWx = device.versions.wx;

  /**
   * 微信JSSDK注册
   */
  const WXJSSDKRegister = useCallback(() => {
    SDKRegister()
      .then(() => {
        dispatch(changeWxSDK(true));
      })
      .catch((error: any) => {
        Toast.info(error, 2, undefined, false);
      });
  }, [dispatch]);

  //
  if (isWx) {
    WXJSSDKRegister();
  }


  useEffect(() => {
    // 路由切换处理
    const listener = () => {
      PopupLogin.destory();
      if (global.modal) {
        global.modal.close();
      }
    };

    //监听键盘收起，然后滚动顶部
    document.body.addEventListener("focusout", () => {
      //软键盘收起的事件处理
      if (device.versions.ios) {
        //处理ios键盘收起页面底部空白问题
        document.body.scrollTop = document.body.scrollHeight + 1;
      }
    });

    // 监听路由切换
    window.addEventListener("popstate", listener, false);
    return () => {
      window.removeEventListener("popstate", listener, false);
    };
  }, []);


  return (
    <Switch>
      <Route path={routesWithNavigation} component={NavLayout} />
      <Route path="/" component={BasicLayout} />
    </Switch>
  );
};

export default App;
