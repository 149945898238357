import { lazy } from "react";

const ReservationDetails = lazy(() => import("./ReservationDetails"));
const ReservationChat = lazy(() => import("./ReservationChat"));
const CommentReservation = lazy(() => import("./CommentReservation"));
const CancleReservation = lazy(() => import("./CancleReservation"));
const CancleProgress = lazy(() => import("./CancleProgress"));
const ReservationItemCreate = lazy(() => import("./ReservationCreate"));

const routes: DDHY.Route[] = [
  {
    path: "/reservation/create",
    title: "创建预约",
    component: ReservationItemCreate,
    private: true,
  },
  {
    path: "/reservation/:reservation_id/chat",
    title: "预约对话",
    component: ReservationChat,
    private: true,
  },

  {
    path: "/reservation/:id/cancle",
    title: "取消预约",
    component: CancleReservation,
    private: true,
  },
  {
    path: "/reservation/:id/cancle-progress",
    title: "取消预约",
    component: CancleProgress,
    private: true,
  },
  {
    path: "/reservation/:id/comment",
    title: "评论",
    component: CommentReservation,
    private: true,
  },
  {
    path: "/reservation/:id",
    title: "预约详情",
    component: ReservationDetails,
    private: true,
  },

];

export { routes };
