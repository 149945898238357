import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "antd-mobile/dist/antd-mobile.css";
import { Provider } from "react-redux";
import store from "./reduxStore";
import "./index.scss";
import "@babel/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import Loading from "./Loading";

/**
 * mui 主题配置
 */
const theme = createTheme({
  palette: {
    primary: {
      main: "#0099ff",
    },
    secondary: {
      main: "#31c94c",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <Loading />
      </Router>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);


