import * as actionTypes from "./constants";

export interface MemberState {
  memberDetails?: DDHY.SNSUserInfo;
  posts?: DDHY.SNSPost[];
  page: 1;
  hasMore: true;
}

const defaultState: MemberState = {
  page: 1,
  hasMore: true,
};

export default (state = defaultState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_MEMBER_DETAILS:
      return { ...state, memberDetails: action.data };
    case actionTypes.SET_POSTS:
      return { ...state, posts: action.data };
    case actionTypes.SET_PAGE:
      return { ...state, page: action.data };
    case actionTypes.SET_HASMORE:
      return { ...state, hasMore: action.data };
    default:
      return state;
  }
};
