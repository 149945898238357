/**
 * 图片url转图片
 *
 * @param url 图片路径
 * @param fn 返回图片对象
 */
export function urltoImage(url: string, fn: Function) {
  var img = new Image();
  img.src = url;
  img.onload = function () {
    fn(img);
  };
}

/**
 * 图片转canvas
 *
 * @param image 图片对象
 */
export function imagetoCanvas(image: HTMLImageElement) {
  var cvs = document.createElement("canvas");
  var ctx = cvs.getContext("2d");
  cvs.width = image.width;
  cvs.height = image.height;
  ctx && ctx.drawImage(image, 0, 0, cvs.width, cvs.height);
  return cvs;
}

/**
 * canvas压缩为图片
 *
 * @param canvas canvas对象
 * @param quality 压缩比例 0 ～ 1
 * @param fn 返回压缩后的图片
 */
export function canvasResizetoFile(
  canvas: HTMLCanvasElement,
  quality: number,
  fn: Function
) {
  canvas.toBlob(
    function (blob) {
      fn(blob);
    },
    "image/jpeg",
    quality
  );
}

/**
 * canvas压缩为base64字符对象
 *
 * @param canvas canvas对象
 * @param quality 压缩比例 0 ～ 1
 */
export function canvasResizetoDataURL(
  canvas: HTMLCanvasElement,
  quality: number
) {
  return canvas.toDataURL("image/jpeg", quality);
}

/**
 * 文件转base64
 *
 * @param file 文件对象
 * @param fn 返回base64字符对象
 */
export function filetoDataURL(file: File, fn: Function) {
  var reader = new FileReader();
  reader.onloadend = function (e: any) {
    fn(e.target.result);
  };
  reader.readAsDataURL(file);
}

/**
 * 封装图片压缩
 *
 * @param file 文件
 */
export function fileResizetoFile(file: File): Promise<File> {
  return new Promise((resolve, reject) => {
    try {
      filetoDataURL(file, function (dataurl: string) {
        urltoImage(dataurl, function (image: HTMLImageElement) {
          canvasResizetoFile(
            imagetoCanvas(image),
            getFileQuality(file),
            (img: File) => {
              resolve(img);
            }
          );
        });
      });
    } catch (error) {
      reject(error);
    }
  });
}

export function getFileQuality(file: File) {
  let quality = 1;
  const fileSize = file.size; // 文件byte
  if (fileSize > 8 * 1024 * 1024) {
    quality = 0.1;
  } else if (fileSize > 6 * 1024 * 1024) {
    quality = 0.2;
  } else if (fileSize > 4 * 1024 * 1024) {
    quality = 0.3;
  } else if (fileSize > 2 * 1024 * 1024) {
    quality = 0.4;
  } else if (fileSize > 1 * 1024 * 1024) {
    quality = 0.5;
  } else {
    quality = 0.6;
  }

  return quality;
}
