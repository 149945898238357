// 付费训练详情公用
export const SET_TAB_INDEX = "training/SET_TAB_INDEX";

// 付费训练详情
export const SET_TRAINING = "training/SET_TRAINING";

// 付费训练 -> 滚动
export const SET_TRAINING_SLIDER = "training/SET_TRAINING_SLIDER";

// 付费训练详情 -> 目录
export const SET_TRAINING_ITEMS = "training/SET_TRAINING_ITEMS";

// 付费训练详情 -> 讨论
export const SET_TRAINING_DISCUSS = "community/SET_TRAINING_DISCUSS";
export const SET_TRAINING_DISCUSS_PAGE = "community/SET_TRAINING_DISCUSS_PAGE";
export const SET_TRAINING_DISCUSS_HASMORE = "community/SET_TRAINING_DISCUSS_HASMORE";

