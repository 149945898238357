export const SET_TAB_INDEX = "profile/SET_TAB_INDEX";

// Collected
export const SET_COLLECTED_SPECIAL_LIST = "profile/SET_COLLECTED_SPECIAL_LIST";
export const SET_COLLECTED_ARTICLE_LIST = "profile/SET_COLLECTED_ARTICLE_LIST";
export const SET_COLLECTED_ARTICLE_PAGE = "profile/SET_COLLECTED_ARTICLE_PAGE";
export const SET_COLLECTED_ARTICLE_HASMORE =
  "profile/SET_COLLECTED_ARTICLE_HASMORE";

// Purchased
export const SET_PURCHASED_SPECIAL_LIST = "profile/SET_PURCHASED_SPECIAL_LIST";
export const SET_PURCHASED_ARTICLE_LIST = "profile/SET_PURCHASED_ARTICLE_LIST";
export const SET_PURCHASED_ARTICLE_PAGE = "profile/SET_PURCHASED_ARTICLE_PAGE";
export const SET_PURCHASED_ARTICLE_HASMORE =
  "profile/SET_PURCHASED_ARTICLE_HASMORE";
