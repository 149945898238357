import { lazy } from "react";
import FindDoctor from "./FindDoctor";
const Doctor = lazy(() => import("./Doctor/index"));
const DoctorArticles = lazy(() => import("./Doctor/Articles"));
const DoctorProjects = lazy(() => import("./Doctor/Projects"));

const routes: DDHY.Route[] = [
  { path: "/doctors", title: "找医生", component: FindDoctor },
  {
    path: "/doctor/:id/articles",
    title: "医生文章",
    component: DoctorArticles,
  },
  {
    path: "/doctor/:id/projects",
    title: "医生项目",
    component: DoctorProjects,
  },
  { path: "/doctor/:id", component: Doctor },
];

export { routes };
