import * as actionTypes from "./constants";

export interface AggregationProps {
  id: number;
  name: string;
  count: number;
  hot_count: number;
}

// 推荐内容列表
export interface HomeRecommendListState {
  recommendList?: Array<any>;
  hasMore?: boolean;
  page?: number;
  timestamp?: number;
}
// 文章列表
export interface HomeArticleListState {
  articleList?: Array<any>;
  hasMore?: boolean;
  page?: number;
  timestamp?: number;
}
// 帖子列表
export interface HomePostListState {
  postList?: Array<any>;
  hasMore?: boolean;
  page?: number;
  timestamp?: number;
}
// 公告列表
export interface HomeAnnouncementListState {
  announcementList?: Array<any>;
  hasMore?: boolean;
  page?: number;
  timestamp?: number;
}

export interface HomeState {
  use: boolean;
  aggregation?: Array<any>; // 特色专题
  tabIndex: number; // 选中标签
  recommendInfo: HomeRecommendListState;  // 推荐内容列表
  articleInfo: HomeArticleListState;  // 文章列表
  postInfo: HomePostListState;  // 帖子列表
  announcementInfo: HomeAnnouncementListState;  // 公告列表
}

const defaultState: HomeState = {
  use: false,
  aggregation: [],
  tabIndex: 0,
  recommendInfo: {
    page: 1,
    hasMore: true,
  },
  articleInfo: {
    page: 1,
    hasMore: true,
  },
  postInfo: {
    page: 1,
    hasMore: true,
  },
  announcementInfo: {
    page: 1,
    hasMore: true,
  }
};

export default (state = defaultState, action: any): HomeState => {
  switch (action.type) {
    case actionTypes.SET_USE_STATE:
      return { ...state, use: action.data };

    case actionTypes.SET_AGGREGATION_DATA:
      return { ...state, aggregation: action.data };

    case actionTypes.SET_TABBAR_INDEX:
      return { ...state, tabIndex: action.data };

    // 推荐内容
    case actionTypes.SET_RECOMMEND_LIST_DATA:
      return {
        ...state,
        recommendInfo: { ...state.recommendInfo, recommendList: action.data },
      };
    case actionTypes.SET_RECOMMEND_PAGE_DATA:
      return {
        ...state,
        recommendInfo: { ...state.recommendInfo, page: action.data },
      };
    case actionTypes.SET_RECOMMEND_HAS_MORE_DATA:
      return {
        ...state,
        recommendInfo: { ...state.recommendInfo, hasMore: action.data },
      };
    case actionTypes.SET_RECOMMEND_TIMESTAMP_DATA:
      return {
        ...state,
        recommendInfo: { ...state.recommendInfo, timestamp: action.data },
      };

    // 科普文章
    case actionTypes.SET_ARTICLES_LIST_DATA:
      return {
        ...state,
        articleInfo: { ...state.articleInfo, articleList: action.data },
      };
    case actionTypes.SET_ARTICLES_PAGE_DATA:
      return {
        ...state,
        articleInfo: { ...state.articleInfo, page: action.data },
      };
    case actionTypes.SET_ARTICLES_HAS_MORE_DATA:
      return {
        ...state,
        articleInfo: { ...state.articleInfo, hasMore: action.data },
      };
    case actionTypes.SET_ARTICLES_TIMESTAMP_DATA:
      return {
        ...state,
        articleInfo: { ...state.articleInfo, timestamp: action.data },
      };

    // 最新帖子
    case actionTypes.SET_POSTS_LIST_DATA:
      return {
        ...state,
        postInfo: { ...state.postInfo, postList: action.data },
      };
    case actionTypes.SET_POSTS_PAGE_DATA:
      return {
        ...state,
        postInfo: { ...state.postInfo, page: action.data },
      };
    case actionTypes.SET_POSTS_HAS_MORE_DATA:
      return {
        ...state,
        postInfo: { ...state.postInfo, hasMore: action.data },
      };
    case actionTypes.SET_POSTS_TIMESTAMP_DATA:
      return {
        ...state,
        postInfo: { ...state.postInfo, timestamp: action.data },
      };

    // 公告帖子
    case actionTypes.SET_ANNOUNCEMENT_LIST_DATA:
      return {
        ...state,
        announcementInfo: { ...state.announcementInfo, announcementList: action.data },
      };
    case actionTypes.SET_ANNOUNCEMENT_PAGE_DATA:
      return {
        ...state,
        announcementInfo: { ...state.announcementInfo, page: action.data },
      };
    case actionTypes.SET_ANNOUNCEMENT_HAS_MORE_DATA:
      return {
        ...state,
        announcementInfo: { ...state.announcementInfo, hasMore: action.data },
      };
    case actionTypes.SET_ANNOUNCEMENT_TIMESTAMP_DATA:
      return {
        ...state,
        announcementInfo: { ...state.announcementInfo, timestamp: action.data },
      };

    default:
      return state;
  }
};
