import { combineReducers } from "redux-immutable";
// 引用各个模块下的reducer
import { reducer as AccountReducer } from "screens/Account/store";
import { reducer as MainReducer } from "./store";
import { reducer as HomeReducer } from "screens/Home/store";
import { reducer as CommunityReducer } from "screens/Community/store";
import { reducer as MessageReducer } from "screens/Message/store";
import { reducer as profileReducer } from "screens/Profile/store";
import { reducer as SearchReducer } from "screens/Search/store";
import { reducer as ClassroomReducer } from "screens/Classroom/store";
import { reducer as DoctorReducer } from "screens/Doctor/store";
import { reducer as ProjectReducer } from "screens/Project/store";
import { reducer as HealthTestReducer } from "screens/HealthTest/store";
import { reducer as ReservationReducer } from "screens/Reservation/store";
import { reducer as DoctorRegisterReducer } from "screens/DoctorRegister/store";
// import { reducer as AggregationReducer } from "screens/Aggregation/store";
import { reducer as HospitalReducer } from "screens/Hospital/store";
import { reducer as TrainingReducer } from "screens/Training/store"

// 合并导出
export default combineReducers({
  main: MainReducer,
  account: AccountReducer,
  home: HomeReducer,
  community: CommunityReducer,
  message: MessageReducer,
  profile: profileReducer,
  classroom: ClassroomReducer,
  doctor: DoctorReducer,
  search: SearchReducer,
  project: ProjectReducer,
  test: HealthTestReducer,
  reservation: ReservationReducer,
  doctorRegister: DoctorRegisterReducer,
  // aggregation: AggregationReducer,
  hospital: HospitalReducer,
  training: TrainingReducer,
});
