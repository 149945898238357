import React from "react";
import "./index.scss";

const ReservationHelp: React.FC = () => {
  return (
    <div className="HelpDocument">
      <ol>
        <li>收费说明</li>
        <p>
          患者向医生支付的预约金仅为本次预约的费用，具体的手术费用或治疗费用由患者所预约的医院或网点收取；预约金包含的内容为：术前咨询和术后30天随访，期间患者将获得100条咨询次数。
        </p>
        <li>续费说明</li>
        <p>
          当前预约金中所附带的咨询条数用完后，患者可以以50元/100条的价格额外购买咨询次数，但是术后随访的有效期将不会重置。
        </p>
        <li>术后随访有效期</li>
        <p>
          术前不计算有效期，在医生确认患者已到院就诊、进入术后随访阶段之后，平台将开始计算有效期，术后随访有效期为30天。
        </p>
        <li>退款说明</li>
        <p>
          <ol>
            <li>
              在患者预约后、并且医生接受预约前，患者可以选择向平台退款，此时平台将会无条件100%向患者退款，并关闭本次预约；
            </li>
            <li>
              在医生接受预约后、确认患者到院就诊前，患者可以向医生申请退款，如果医生同意退款，将会向患者退还预约金的50%，并关闭本次预约；
            </li>
            <li>
              如果医生拒绝向患者退款，患者可以像平台申请强制退款，平台将会介入，并使具体情况对本次预约做出最终处理；
            </li>
            <li>在医生确认患者已经到院就诊后，患者将不能申请退款。</li>
          </ol>
        </p>
        <li>
          在预约中如果患者对医生的服务有异议或者不满，可以向丁丁客服进行反馈。
        </li>
      </ol>
    </div>
  );
};

export default ReservationHelp;
