import React from "react";
import styled from "styled-components";

interface MemberLevelProps {
  color?: string;
  children?: any;
}
const MemberLevel: React.FC<MemberLevelProps> = ({ color, children }) => {
  const Container = styled.div`
    position: relative;
    font-size: 12px;
    color: ${(props: any) => props.color || "#0099ff"};
    box-sizing: border-box;
    line-height: 18px;
    height: 18px;
    padding: 0px 5px;
    border: solid 1px #0099ff;
    border-color: ${(props: any) => props.color || "#0099ff"};
    border-radius: 3px;
    margin: 0 5px;
  `;
  return <Container color={color}>{children}</Container>;
};

export default MemberLevel;
