/**
 * 快捷调用本地存储函数
 *
 *
 */

function setToken(token: string) {
  localStorage.setItem("token", token);
}
function getToken() {
  return localStorage.getItem("token");
}
function removeToken() {
  return localStorage.removeItem("token");
}
/**
 * 记录登陆前页面
 * @param path
 */
function setLoginBeforePath(path: string) {
  sessionStorage.setItem("login_before_path", path);
}
function getLoginBeforePath() {
  return sessionStorage.getItem("login_before_path");
}

export {
  setToken,
  getToken,
  removeToken,
  setLoginBeforePath,
  getLoginBeforePath,
};
