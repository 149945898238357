import { lazy } from "react";

const AppStoreDownload = lazy(() => import("./Download"));
const AppStoreRegionInvite = lazy(() => import("./RegionInvite"));

const routes: DDHY.Route[] = [
  {
    path: "/app/download",
    title: "APP下载",
    component: AppStoreDownload,
    private: false
  },
  {
    path: "/app/invite",
    title: "用户邀请",
    component: AppStoreRegionInvite,
    private: true
  },
];

export { routes };
