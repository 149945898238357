// Doctor
export const SET_DOCTOR_DETAILS = "doctors/SET_DOCTOR_DETAILS";
export const SET_CONSULTS = "doctors/SET_CONSULTS";
export const SET_CONSULTS_PAGE = "doctors/SET_CONSULTS_PAGE";
export const SET_CONSULTS_HASMORE = "doctors/SET_CONSULTS_HASMORE";

// DoctorArticleList
export const SET_ARTICLES = "doctors/SET_ARTICLES";

// DoctorPorjectsList
export const SET_PROJECTS = "doctors/SET_PROJECTS";
