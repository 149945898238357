import * as consts from "./constants";
import reservationApi from "api/reservation";

// Reservation
export const changeReservationDetails = (data: any) => ({
  type: consts.SET_DETAILS,
  data: data,
});
export const changeRefundProgress = (data: any) => ({
  type: consts.SET_REFUND_PROGRESS,
  data: data,
});

/**
 * Reservation 初始化预约信息
 * @param reservationID {string | number} 预约ID
 * @param callback  {Function} 初始化回调函数
 */
export const initReservation = (
  reservationID: string | number,
  callback?: Function
) => {
  return async (dispatch: any, getState: any) => {
    try {
      const response: any = await Promise.all([
        getReservationDetails(),
        getRefundProgess(),
      ]);
      const [reservation, refundProgess] = response;
      if (reservation) {
        dispatch(changeReservationDetails(reservation));
      }
      if (refundProgess) {
        dispatch(changeRefundProgress(refundProgess));
      } else {
        dispatch(changeRefundProgress(null));
      }
      callback && callback();
    } catch (error) {
      callback && callback(error);
    }

    // 获取预约详情
    function getReservationDetails() {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await reservationApi.getDetails(reservationID);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      });
    }

    // 获取退款进度
    function getRefundProgess() {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await reservationApi.getRefundProgress(reservationID);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      });
    }
  };
};
