import * as actionTypes from "./constants";

export interface MyDoctorState {
  doctorList?: DDHY.Doctor[];
  page?: number;
  hasMore?: boolean;
}

const defaultState: MyDoctorState = {
  page: 1,
  hasMore: true,
};

export default (state = defaultState, action: any): MyDoctorState => {
  switch (action.type) {
    case actionTypes.SET_DOCTOR_LIST:
      return { ...state, doctorList: action.data };
    case actionTypes.SET_DOCTOR_PAGE:
      return { ...state, page: action.data };
    case actionTypes.SET_DOCTOR_HASMORE:
      return { ...state, hasMore: action.data };

    default:
      return state;
  }
};
