import * as consts from "./constants";
import healthTestApi from "api/healthTest";

// HealthTest
export const changeTestList = (data?: Array<any>) => ({
  type: consts.SET_TEST_LIST,
  data: data,
});

/**
 * HealthTest
 */
export const initHealthTest = (callback?: Function) => {
  return async (dispatch: any) => {
    try {
      const result = await healthTestApi.allTestQuestions();
      let { data } = result;
      dispatch(changeTestList(data));
    } catch (error) {
      callback && callback(error);
    } finally {
      callback && callback();
    }
  };
};
