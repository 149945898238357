// Register
export const SET_RESGISTER_ERROR = "doctorRegister/SET_RESGISTER_ERROR";
export const SET_COUNT_DOWN = "doctorRegister/SET_COUNT_DOWN";
export const SET_DOCTOR_PROFILE = "doctorRegister/SET_DOCTOR_PROFILE";

// Hospitals
export const SET_HOSPITALS = "doctorRegister/SET_HOSPITALS";
export const SET_HOSPITALS_LOADING = "doctorRegister/SET_HOSPITALS_LOADING";
export const SET_HOSPITALS_HASMORE = "doctorRegister/SET_HOSPITALS_HASMORE";
export const SET_HOSPITALS_PAGE = "doctorRegister/SET_HOSPITALS_PAGE";
export const SET_REGIONS = "doctorRegister/SET_REGIONS";
export const SET_REGION_VALUE = "doctorRegister/SET_REGION_VALUE";
export const SET_LOCATION = "doctorRegister/SET_LOCATION";
