import * as actionTypes from "./constants";

export const changeTabIndex = (data: number) => ({
  type: actionTypes.SET_TAB_INDEX,
  data: data,
});

// 社区广场圈子页
export const changeTopics = (data?: DDHY.SNSTopic[]) => ({
  type: actionTypes.SET_TOPICS,
  data: data,
});
export const changeTopicPage = (data: number) => ({
  type: actionTypes.SET_TOPIC_PAGE,
  data: data,
});
export const changeTopicHasMore = (data: boolean) => ({
  type: actionTypes.SET_TOPIC_HASMORE,
  data: data,
});

/**
 * 清除社区广场圈子页
 */
export const cleanTopic = () => {
  return (d: any) => {
    d(changeTopics());
    d(changeTopicPage(1));
    d(changeTopicHasMore(true));
  };
};

// 社区广场最新页
export const changeNewPosts = (data?: DDHY.SNSPost[]) => ({
  type: actionTypes.SET_NEW_POSTS,
  data: data,
});
export const changeNewPage = (data: number) => ({
  type: actionTypes.SET_NEW_PAGE,
  data: data,
});
export const changeNewHasMore = (data: boolean) => ({
  type: actionTypes.SET_NEW_HASMORE,
  data: data,
});
/**
 * 清除社区广场最新页
 */
export const cleanNew = () => {
  return (d: any) => {
    d(changeNewPosts());
    d(changeNewPage(1));
    d(changeNewHasMore(true));
  };
};

// 社区广场最热页
export const changeHotPosts = (data?: DDHY.SNSPost[]) => ({
  type: actionTypes.SET_HOT_POSTS,
  data: data,
});
export const changeHotPage = (data: number) => ({
  type: actionTypes.SET_HOT_PAGE,
  data: data,
});
export const changeHotHasMore = (data: boolean) => ({
  type: actionTypes.SET_HOT_HASMORE,
  data: data,
});
/**
 * 清除社区广场最热页
 */
export const cleanHot = () => {
  return (d: any) => {
    d(changeHotPosts());
    d(changeHotPage(1));
    d(changeHotHasMore(true));
  };
};

// 社区广场关注页
export const changeFollowedPosts = (data?: DDHY.SNSPost[]) => ({
  type: actionTypes.SET_FOLLOWED_POSTS,
  data: data,
});
export const changeFollowedPage = (data: number) => ({
  type: actionTypes.SET_FOLLOWED_PAGE,
  data: data,
});
export const changeFollowedHasMore = (data: boolean) => ({
  type: actionTypes.SET_FOLLOWED_HASMORE,
  data: data,
});
/**
 * 清除社区广场关注页
 */
export const cleanFollowed = () => {
  return (d: any) => {
    d(changeFollowedPosts());
    d(changeFollowedPage(1));
    d(changeFollowedHasMore(true));
  };
};

/**
 * 清除社区广场
 */
export const cleanSquare = () => {
  return (d: any) => {
    d(changeTabIndex(1));
    d(cleanTopic());
    d(cleanNew());
    d(cleanHot());
    d(cleanFollowed());
  };
};
