import * as actionTypes from "./constants";
// import { Toast } from "antd-mobile";
// import consultApi from "api/consult";
// import reservationApi from "api/reservation";
import { initUnreadMessageCount } from "reduxStore/store/actionCreators";

// Message
export const changeMessageUseState = (data: boolean) => ({
  type: actionTypes.SET_USE_STATE,
  data: data,
});
export const changeConsults = (data: any[]) => ({
  type: actionTypes.SET_CONSULTS,
  data: data,
});
export const changeReservations = (data: any[]) => ({
  type: actionTypes.SET_RESERVATIONS,
  data: data,
});
export const changeMessageInterval = (data?: number) => ({
  type: actionTypes.SET_INTERVAL,
  data: data,
});

/**
 * 初始化消息数据
 *
 * @component Message
 */
export const initMessage = (cb?: Function) => {
  return (dispatch: any) => {
    dispatch(changeMessageUseState(true));
    dispatch(initUnreadMessageCount());
    cb && cb();
  };
};

/**
 * 启动消息轮询
 *
 * @component Message
 */
export const startMessageInterval = () => {
  return (dispatch: any, getState: any) => {
    const { interval } = getState().getIn(["message", "message"]);
    if (!interval) {
      let newInterval = window.setInterval(() => {
        dispatch(initMessage());
      }, 10000);
      dispatch(changeMessageInterval(newInterval));
    }
  };
};

/**
 * 清除消息轮询
 *
 * @component Message
 */
export const cleanMessageInterval = () => {
  return (dispatch: any, getState: any) => {
    const { interval } = getState().getIn(["message", "message"]);
    if (interval) {
      clearInterval(interval);
      dispatch(changeMessageInterval(undefined));
    }
  };
};

/**
 * 标记咨询为已读
 *
 * @param consultID 咨询ID
 */
export const markConsultAsRead = (consultID: number) => {
  return (dispatch: any, getState: any) => {
    const { consults } = getState().getIn(["message", "message"]);
    if (consults && consults.length > 0) {
      const newConsults = consults.map((item: any) => {
        if (item.consult_id === consultID) {
          item.read_flag = 0;
        }
        return item;
      });
      dispatch(changeConsults(newConsults));
    }
  };
};

/**
 * 标记预约为已读
 *
 * @param reserveID 预约ID
 */
export const markReserveAsRead = (reserveID: number) => {
  return (dispatch: any, getState: any) => {
    const { reservations } = getState().getIn(["message", "message"]);
    if (reservations && reservations.length > 0) {
      const newReservations = reservations.map((item: any) => {
        if (item.reserve_id === reserveID) {
          item.read_flag = 0;
        }
        return item;
      });
      dispatch(changeReservations(newReservations));
    }
  };
};
