import * as actionTypes from "./constants";

export interface PostState {
  details?: DDHY.SNSPostDetails;
  comments?: DDHY.SNSPostComment[];
  page: number;
  hasMore: boolean;
}

const defaultState: PostState = {
  page: 1,
  hasMore: false,
};

export default (state = defaultState, action: any): PostState => {
  switch (action.type) {
    case actionTypes.SET_DETAILS:
      return { ...state, details: action.data };
    case actionTypes.SET_COMMENTS:
      return { ...state, comments: action.data };
    case actionTypes.SET_PAGE:
      return { ...state, page: action.data };
    case actionTypes.SET_HASMORE:
      return { ...state, hasMore: action.data };
    default:
      return state;
  }
};
