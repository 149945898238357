import articleApi from "api/article";
import * as constatnts from "./constants";
import request from "utils/request";
import { AuthorState } from "./reducer";

export const changeAuthorDetails = (data?: any) => ({
  type: constatnts.SET_DETAILS,
  data,
});
export const changeAuthorArticles = (data?: DDHY.Article[]) => ({
  type: constatnts.SET_ARTICLES,
  data,
});
export const changeAuthorArticlesPage = (data: number) => ({
  type: constatnts.SET_PAGE,
  data,
});
export const changeAuthorArticlesHasMore = (data: boolean) => ({
  type: constatnts.SET_HASMORE,
  data,
});
/**
 * 清除State
 *
 * @component Author
 */
export const cleanAuthor = () => {
  return (dispatch: any) => {
    dispatch(changeAuthorDetails());
    dispatch(changeAuthorArticles());
    dispatch(changeAuthorArticlesPage(1));
    dispatch(changeAuthorArticlesHasMore(true));
  };
};

/**
 * 初始化作者信息
 *
 * @component Author
 * @param cb 初始化完毕的回调函数
 */
export const initAuthor = (authorID: string | number, cb?: Function) => {
  return (dispatch: any) => {
    const pms = request({ url: `/v2/doctor/info`, params: {access_id: authorID} });
    pms.then((response: any) => {
      const { code, msg, result } = response;
      if (code === 200) {
        dispatch(changeAuthorDetails(result));
        cb && cb();
      } else {
        cb && cb(msg);
      }
    });
    pms.catch((error: any) => {
      throw error;
    });
  };
};

/**
 * 加载文章数据
 *
 * @component Author
 */
export const loadAuthorArticles = (callback?: Function) => {
  return async (dispatch: any, getState: any) => {
    const limit = 10;
    try {
      const state: AuthorState = getState().getIn(["classroom", "author"]);
      const { authorDetails, articlesPage, articles } = state;
      if (authorDetails) {
        const result = await articleApi.queryArticleList({
          user_id: authorDetails.access_id,
          page: articlesPage,
          limit,
        });
        let { current_page, data, last_page} = result;
        if (Array.isArray(data)) {
          if(current_page === 1){
            dispatch(changeAuthorArticles(data));
          }else{
            dispatch(changeAuthorArticles([...articles, ...data]));
          }
          dispatch(changeAuthorArticlesHasMore(current_page < last_page));
          callback && callback();
        } else {
          callback && callback("数据错误");
        }
      } else {
        callback && callback("作者信息不存在");
      }
    } catch (error) {
      callback && callback(error);
    }
  };
};
