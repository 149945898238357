import React, { useState, useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import { Modal, Button, InputItem, Toast } from "antd-mobile";
import "./index.scss";
import useCountDown from "hooks/useCountDown";
import { loginByVerifyCode } from "screens/Account/actions";
import Loading from "../../basic/Animation/Loading";
import verifyApi from "api/verify";
const COMPONENT_ID = "PopupLogin";

interface PopupLoginState {
  onSuccess(value: DDHYAccount.AccountInfo): void; // 完成回调
}
interface PopupLoginProps extends React.FC<{ operation: PopupLoginState }> {
  create(operation: PopupLoginState): void;
  destory(): void;
}

const PopupLogin: PopupLoginProps = (props) => {
  const { operation } = props;
  const { onSuccess } = operation;
  const [show, setShow] = useState(true);
  const [count, start] = useCountDown(60);
  const [phone, setPhone] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [gettingCode, setGettingCode] = useState<boolean>(false);

  // 图文验证码
  const [verifyCodeImg, setVerifyCodeImg] = useState<string>('');
  const [verifyCodeKey, setVerifyCodeKey] = useState<string>('');
  const [textVerifyCode, setTextVerifyCode] = useState<string>('');

  const destroy = () => {
    const div = document.getElementById(COMPONENT_ID);
    if (div) {
      ReactDOM.unmountComponentAtNode(div);
      document.body.removeChild(div);
    }
  };
  const handleCancle = useCallback(() => {
    setShow(false);
    setTimeout(() => {
      destroy();
    }, 300);
  }, []);

  /**
   * 获取图文验证码
   */
   const getTextVerifyCode = useCallback(async () => {
    const verifyImgInfo = await verifyApi.fetchVerifyCodeImg();
    setVerifyCodeImg(verifyImgInfo.base64);
    setVerifyCodeKey(verifyImgInfo.key);
  },[]);

  useEffect(() => {
    if(!verifyCodeImg){
      getTextVerifyCode();
    }
  }, [getTextVerifyCode, verifyCodeImg]);

  /**
   * 获取验证码
   */
  const getCode = useCallback(() => {
    if(textVerifyCode.length !== 4){
      Toast.info("请正确输入验证码", 2, undefined, false);
      return;
    }
    if (phone) {
      // 检查手机号码格式是否正确
      let checkPhone = RegExp(/^1[3456789]\d{9}$/).test(phone);
      if (checkPhone) {
        setGettingCode(true);
        verifyApi
          .fetchVerifyCode(phone, textVerifyCode, verifyCodeKey, "login")
          .then((res) => {
            if(res.state === 'success'){
              Toast.info("验证码已发送到您的手机，请注意查收", 2, undefined, false);
              start();
            } else {
              Toast.info(res.msg);
              getTextVerifyCode();
            }
          })
          .catch((error) => {
            Toast.fail(error);
          })
          .finally(() => {
            setGettingCode(false);
          });
      } else {
        Toast.info("手机号码格式错误", 2, undefined, false);
      }
    } else {
      Toast.info("请输入手机号码", 2, undefined, false);
    }
  }, [getTextVerifyCode, phone, start, textVerifyCode, verifyCodeKey]);

  const handlePhoneLogin = useCallback(() => {
    Toast.loading("正在登录", 0);
    loginByVerifyCode(phone, code)
    .then((resp) => {
      Toast.success("登录成功", 2, undefined, false);
      handleCancle();
      onSuccess(resp);
    })
    .catch((error) => {
      Toast.info(error);
    });
  }, [code, handleCancle, onSuccess, phone]);

  return (
    <Modal
      className="PopupLoginModal"
      popup
      visible={show}
      onClose={() => handleCancle()}
      animationType="slide-up"
    >
      <div className={`GlobalComponent-${COMPONENT_ID}`}>
        <div style={{ flex: 1 }}>
          <h2 style={{ color: "#333", fontSize: 20 }}>快捷注册/登录</h2>
          <p style={{ color: "#888", fontSize: 14 }}>
            为了你的账号安全，请用手机号码登录
          </p>
          <div className="Login-Form">
            <div className="Form-Item">
              <InputItem
                type="number"
                maxLength={11}
                placeholder="请输入手机号码"
                value={phone}
                onChange={setPhone}
                clear
              />
            </div>
            <div className="Form-Item">
              <div style={{ flex: 1 }}>
                <InputItem
                  type="text"
                  maxLength={6}
                  placeholder="请输入验证码"
                  value={textVerifyCode}
                  onChange={setTextVerifyCode}
                  clear
                />
              </div>
              <div
                style={{
                  flexShrink: 0,
                  paddingLeft: 8,
                  lineHeight: "28px",
                }}
              >
                <img alt="" src={verifyCodeImg} onClick={getTextVerifyCode} style={{maxHeight:"36px",}} />
              </div>
            </div>
            <div className="Form-Item">
              <div style={{ flex: 1 }}>
                <InputItem
                  type="number"
                  maxLength={6}
                  placeholder="请输入短信验证码"
                  value={code}
                  onChange={setCode}
                  clear
                />
              </div>
              <div
                style={{
                  flexShrink: 0,
                  fontSize: 14,
                  color: "#0099ff",
                  paddingLeft: 8,
                  lineHeight: "28px",
                }}
                onClick={() => {
                  if (gettingCode || (count && count > 0)) {
                    return;
                  }
                  getCode();
                }}
              >
                {gettingCode ? (
                  <div style={{ padding: "0 10px" }}>
                    <Loading size={20} />
                  </div>
                ) : count && count > 0 ? (
                  <span style={{ color: "#888" }}>已发送({count}s)</span>
                ) : (
                  <span>获取验证码</span>
                )}
              </div>
            </div>
            <Button
              className="Login-Button"
              type="primary"
              disabled={!phone || !code}
              onClick={handlePhoneLogin}
            >
              登录
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

PopupLogin.create = (operation: PopupLoginState) => {
  let div = document.createElement("div");
  div.id = COMPONENT_ID;
  document.body.appendChild(div);
  let instance = ReactDOM.render(
    React.createElement(PopupLogin, { operation }),
    div
  );
  return {
    instance,
    destroy() {
      ReactDOM.unmountComponentAtNode(div);
      document.body.removeChild(div);
    },
  };
};

PopupLogin.destory = () => {
  const div = document.getElementById(COMPONENT_ID);
  if (div) {
    ReactDOM.unmountComponentAtNode(div);
    document.body.removeChild(div);
  }
};

export default PopupLogin;
