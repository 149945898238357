/**
 * 创建axios实例并添加拦截
 *
 */
import { API_ROOT } from "common/constant";
import * as storage from "utils/storage";
import Axios, { AxiosRequestConfig } from "axios";
import PopupLogin from "components/global/PopupLogin";
import { Modal, Toast } from "antd-mobile";
import { checkRealInfo } from "screens/Account/actions";
/**
 * 获取用户token添加到请求头中
 *
 */
export const getToken = () => {
  return storage.getToken();
};
/**
 * 创建实例
 *
 */
export let service = Axios.create({
  baseURL: API_ROOT,
  timeout: 10000, // 请求超时时间
});

service.interceptors.request.use(
  async (config) => {
    if (!config.headers["token"]) {
      const token = getToken();
      if (token) {
        config.headers["token"] = getToken();
      }
    }
    config.headers["fromClient"] = 'wx';

    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    // 获取返回数据，并处理。按自己业务需求修改。
    const { status, statusText, data } = response;
    if (status !== 204 && status !== 256 && status !== 200) {
      return {
        code: 400,
        msg: statusText,
      };
    }
    return data;
  },
  async (error) => {
    // Toast.offline(error.message, 2);
    console.error("response error:", JSON.stringify(error));

    // 如果是服务端错误，返回错误信息或者请求超时
    // 否则尝试重新请求一次
    if (error.message === "Request failed with status code 500") {
      return Promise.resolve({
        code: 400,
        msg: error.message,
      });
    }
    if (error.message.includes("timeout of")) {
      return Promise.resolve({
        code: 400,
        msg: "请求超时",
      });
    }
    // Create new promise to handle exponential backoff
    var backoff = new Promise((resolve) => {
      setTimeout(function () {
        resolve(undefined);
      }, 100);
    });

    // Return the promise in which recalls axios to retry the request
    await backoff;
    return Axios(error.config);
  }
);

export interface ResponseProps {
  code: 200 | 400 | 401 | 402 | 404 | 500;
  msg: string;
  result: any;
}

interface RequestConfig extends AxiosRequestConfig {
  needLogin?: boolean; // 需要登陆
  needRealInfo?: boolean; // 需要完善真实信息
}

/**
 * 通用请求封装
 * 直接获取响应结果数据
 *
 * 将所有请求的返回内容做处理，让使用此请求函数的代码最简化，但是碰到需要对请求结果做特殊处理的接口，需使用其他请求方式
 *
 * @param config 请求配置项
 */
export const getResult = (config: RequestConfig): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      // 需要登录
      if (config.needLogin) {
        const token = getToken();
        // const token='1328caa63c13fd19342cd6b9f78ef60c'
        if (!token) {
          PopupLogin.create({
            onSuccess: (v) => {
              if (v && v.token) {
                storage.setToken(v.token);
                window.location.reload();
                resolve(undefined);
              }
            },
          });
          reject("need login");
          return;
        }
      }

      // 需要完善用户信息
      if (config.needRealInfo) {
        const flag = checkRealInfo();

        if (!flag) {
          // 将Modal实例挂载到全局变量，方便在页面跳转时关闭
          global.modal = Modal.alert("提示", "请完善个人信息后再操作", [
            {
              text: "取消",
            },
            {
              text: "去完善",
              onPress() {
                window.location.href = "/personal-info";
                resolve(undefined);
              },
            },
          ]);
          reject("incomplete user information");
          return;
        }
      }

      const resp: ResponseProps = await service.request(config);
      if (resp.code === 200) {
        resolve(resp.result);
      } else {
        reject(resp.msg);
      }
    } catch (error) {
      resolve(error);
    }
  });
};

/**
 * 统一错误信息处理
 * @param error 错误信息
 * @param level 错误级别
 */
export function handleResponseError(
  error: string,
  level: "info" | "error" | "warning" = "info"
) {
  // console.log(`[${level}] handleResponseError:`, error);

  // 不需要提示的错误信息
  const toastIgnore = ["need login", "incomplete user information"];
  if (typeof error === "string") {
    if (!toastIgnore.includes(error)) {
      switch (level) {
        case "warning":
          Toast.offline(error);
          break;
        case "error":
          Toast.fail(error);
          break;
        case "info":
          Toast.info(error, 2, undefined, false);
          break;
      }
    }
  } else {
    console.error(error);
  }
}

export default service;
