import * as constants from "./constants";

// Doctor
export const changeDoctorDetails = (data?: DDHY.DoctorDetails) => ({
  type: constants.SET_DOCTOR_DETAILS,
  data: data,
});

export const changeConsults = (data?: Array<DDHY.Consult>) => ({
  type: constants.SET_CONSULTS,
  data: data,
});
export const changeConsultsPage = (data: number) => ({
  type: constants.SET_CONSULTS_PAGE,
  data: data,
});
export const changeConsultsHasMore = (data: boolean) => ({
  type: constants.SET_CONSULTS_HASMORE,
  data: data,
});

// DoctorArticle
export const changeArticles = (data?: Array<DDHY.Article>) => ({
  type: constants.SET_ARTICLES,
  data: data,
});

// DoctorprojectList
export const changeProjects = (data?: Array<DDHY.Project>) => ({
  type: constants.SET_PROJECTS,
  data: data,
});

/**
 * 清除医生详情页面数据
 *
 * @component Doctor
 */
export const cleanDoctor = () => {
  return (dispatch: any) => {
    dispatch(changeDoctorDetails());
    dispatch(changeConsults());
    dispatch(changeConsultsPage(1));
    dispatch(changeConsultsHasMore(true));
    dispatch(changeArticles());
    dispatch(changeProjects());
  };
};
