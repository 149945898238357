import { lazy } from "react";

const Login = lazy(() => import("./Login"));
const Invite = lazy(() => import("./Invite"));
const ChangePhone = lazy(() => import("./ChangePhone"));
const BindPhone = lazy(() => import("./BindPhone"));

const routes: DDHY.Route[] = [
  { path: "/login", title: "登录", component: Login },
  { path: "/account/phone", title: "绑定手机号", component: ChangePhone },
  { path: "/account/bind-phone", title: "绑定手机号", component: BindPhone },
  {
    path: "/invite/:invite_code",
    title: "登陆",
    component: Invite,
  },
];

export { routes };
