import { getResult } from "utils/request";
const baseUrl = "/api/classroom";

export interface QueryParams {
  category_id?: number; // 分类编号
  special_id?: number; // 专题编号
  author_id?: number; // 作者id
  keywords?: string; // 搜索关键字
  page?: number;
  limit?: number;
  from?: "home" | "doctor"; // 调用来源 home: 首页展示的文章  doctor: 健康讲堂医生科普内容
}

export interface QueParams {
  cate_id?: number; // 分类编号
  topic_id?: number; // 圈子编号
  subject_id?: number; // 特色专题编号
  user_id?: number | string; // 账号编号(access_id)
  page?: number;
  limit?: number;
  from?: "home" | "doctor"; // 调用来源 home: 首页展示的文章  doctor: 健康讲堂医生科普内容
}

export interface QueryCommentParams {
  article_id?: number; // 帖子编号
  page?: number;
  limit?: number;
}

const articleApi = {
  /**
   * 获取文章列表【新】
   * @param params 参数
   * @returns
   */
  queryArticleList(params: QueParams){
    return getResult({
      url: `/v2/community/article/list`,
      params,
    });
  },

  /**
   * 获取文章详情
   *
   * @param {int} article_id 文章ID
   */
  getDetails(article_id: number | string) {
    return getResult({
      url: `/v2/community/article/${article_id}`,
    });
  },

  /**
   * 获取已收藏文章列表
   *
   * @param 请求参数
   */
    getCollectionAticles(params: {
      page?: number;
      limit?: number;
    }): Promise<any> {
      return getResult({
        url: `/v2/member/collect_list`,
        needLogin: true,
        params: {
          ...params,
          type: 'article',
        },
      });
    },

  /**
   * 获取评论列表
   * @param params
   */
  getComments (params: QueryCommentParams) {
    return getResult({
      url: `/v2/community/article_commentary`,
      params,
    });
  },






  /**
   *  获取所有一级文章分类 ?[待处理]
   */
  // getCategoryList(): Promise<any[]> {
  //   return getResult({
  //     url: `/cate`,
  //   });
  // },

  /**
   * 根据分类ID获取所有子分类 ?[待处理]
   * @param {int} parent_id 上级文章分类
   */
  getCategoryChildren(parent_id: string | number): Promise<any[]> {
    return getResult({
      url: `/cate/${parent_id}`,
    });
  },



  /**
   *  用户添加/删除文章标签赞同 ?[待处理]
   * @param user_id 用户ID
   * @param article_id 要操作的文章ID
   * @param operation_type 操作动作 1-点击 2-分享 3收藏/取消收藏
   */
  userSupport(article_id: string | number, tag_id: string | number) {
    return getResult({
      url: `${baseUrl}/article/support`,
      needLogin: true,
      needRealInfo: true,
      params: {
        obj_id: article_id,
        active_id: tag_id,
        obj_type: 1,
      },
    });
  },



  /**
   * 获取已购买的文章列表 ?[待处理]
   *
   * @param 请求参数
   */
  getPurchasedAticles(params: {
    page?: number;
    limit?: number;
  }): Promise<DDHY.Article[]> {
    return getResult({
      url: `${baseUrl}/purchased`,
      needLogin: true,
      params: {
        ...params,
        obj_type: 1,
      },
    });
  },
};

export default articleApi;
