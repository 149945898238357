import React from "react";
import Icon from "../../Icon";
import "./index.scss";

/**
 * 旋转菊花加载动画
 */
const Loading: React.FC<{
  size?: number;
  color?: string;
  style?: React.CSSProperties;
}> = (props) => {
  return (
    <div className="Animation-Loading">
      <Icon {...props}>jiazhai</Icon>
    </div>
  );
};

export default Loading;
