import { combineReducers } from "redux-immutable";
import { reducer as MyCommunityReducer } from "../MyCommunity/store";
import { reducer as MyDoctorReducer } from "../MyDoctor/store";
import { reducer as MyReadReducer } from "../MyRead/store";
import { reducer as MyOrderReducer } from "../MyOrder/store";

export default combineReducers({
  myCommunity: MyCommunityReducer,
  myDoctor: MyDoctorReducer,
  myRead: MyReadReducer,
  myOrder: MyOrderReducer,
});
