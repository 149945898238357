import React, { useEffect, useState } from "react";
import { TabBar } from "antd-mobile";
import { useHistory } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { Icon, SafeArea } from "components/basic";
import { changeBottomBar, initUnreadMessageCount } from "../../reduxStore/store/actionCreators";


const Footer: React.FC<{
  unreadCount: any;
  bottomBar: any;
}> = (props) => {
  const { unreadCount, bottomBar } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const pathName = history.location.pathname;
  const [messageBadge, setMessageBadge] = useState(0);
  const amountKey = ['comment_count', 'support_count', 'consult_count', 'reserve_count', 'chat_count'];

  useEffect(() => {
    dispatch(changeBottomBar(pathName.substring(1)));
  }, [dispatch, pathName]);

  useEffect(() => {
    dispatch(initUnreadMessageCount());
  }, [dispatch]);

  useEffect(() => {
    if (unreadCount) {
      let totalCount = 0;
      for (const key in unreadCount) {
        if (unreadCount.hasOwnProperty(key)) {
          if(amountKey.indexOf(key) >= 0){
            const element = unreadCount[key];
            totalCount = totalCount + element;
          }
        }
      }
      setMessageBadge(totalCount);
    }
  }, [amountKey, unreadCount]);

  return (
    <footer className="layout-footer">
      <SafeArea>
        <TabBar
          unselectedTintColor="#949494"
          tintColor="#0099ff"
          barTintColor="white"
          tabBarPosition="bottom"
        >
          <TabBar.Item
            title="首页"
            icon={<Icon size="26">shouye</Icon>}
            selectedIcon={<Icon size="26">shouyefill</Icon>}
            selected={bottomBar === "home"}
            style={{ fontSize: "12px" }}
            onPress={() => {
              history.push("/home");
              dispatch(changeBottomBar("home"));
            }}
          />
          <TabBar.Item
            title="训练"
            icon={<Icon size="26">task</Icon>}
            selectedIcon={<Icon size="26">task_fill</Icon>}
            selected={bottomBar === "trains"}
            style={{ fontSize: "12px" }}
            onPress={() => {
              history.push("/trains");
              dispatch(changeBottomBar("trains"));
            }}
          />
          {/* <TabBar.Item
            title="社区"
            icon={<Icon size="26">liuyan</Icon>}
            selectedIcon={<Icon size="26">liuyanfill</Icon>}
            selected={selected === "community"}
            style={{ fontSize: "12px" }}
            onPress={() => {
              history.push("/community");
              setSelected("community");
            }}
          /> */}
          <TabBar.Item
            title="消息"
            icon={<Icon size="26">icon_dmail</Icon>}
            selectedIcon={<Icon size="26">icon_cmail</Icon>}
            selected={bottomBar === "message"}
            style={{ fontSize: "12px" }}
            badge={messageBadge}
            onPress={() => {
              history.push("/message");
              dispatch(changeBottomBar("message"));
            }}
          />
          <TabBar.Item
            title="我的"
            icon={<Icon size="26">wode</Icon>}
            selectedIcon={<Icon size="26">wodefill</Icon>}
            selected={bottomBar === "profile"}
            style={{ fontSize: "12px" }}
            onPress={() => {
              history.push("/profile");
              dispatch(changeBottomBar("profile"));
            }}
          />
        </TabBar>
      </SafeArea>
    </footer>
  );
};

const mapStateToProps = (state: any) => ({
  unreadCount: state.getIn(["main", "unreadCount"]),
  bottomBar: state.getIn(["main", "bottomBar"]),
});

export default connect(mapStateToProps)(Footer);
