import { lazy } from "react";

const Exchange = lazy(() => import("./index"));


const routes: DDHY.Route[] = [
  { path: "/exchange/:train_id/:ex_id", title: "训练领取", component: Exchange },
];

export { routes };
