import { getAccountInfo } from "screens/Account/actions";
import adminApi from "api/admin";
import postApi from "api/post";
import { SharePost } from "ddhy";
import { richTextFilter } from "utils/util";

// 帖子处理类型 删除/加精/隐藏图片
type handlePostType = "DELETE" | "ADD_TO_DIGEST" | "HIDE_IMG";

/**
 * 处理帖子
 *
 * @param type 处理类型
 * @param post 要处理的帖子
 */
export const handlePost = (
  type: handlePostType,
  post: DDHY.SNSPost
): Promise<void> => {
  switch (type) {
    case "DELETE":
      return deletePost(post);
    case "ADD_TO_DIGEST":
      return elitePost(post);
    case "HIDE_IMG":
      return hidePostImages(post);
    default:
      throw Error("handlePost Error: undefined type:" + type);
  }
};

/**
 * 删除帖子
 *
 * @param post
 */
const deletePost = (post: DDHY.SNSPost): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    const accountInfo = getAccountInfo();
    if (!accountInfo) {
      reject("未获取到用户信息，请刷新重试");
    } else {
      try {
        let result: any;
        // 删自己的评论
        if (accountInfo.user_id === post.user_info.member_id) {
          result = await postApi.deletePost({
            posts_id: post.post_id,
          });
        } else {
          result = await adminApi.postDelete({
            posts_id: post.post_id,
            active_type: "del",
          });
        }

        resolve(result);
      } catch (error) {
        reject(error);
      }
    }
  });
};

/**
 * 帖子加精
 *
 * @param post
 */
const elitePost = (post: DDHY.SNSPost): Promise<any> => {
  return new Promise((resolve, reject) => {
    const type = post.is_essence === 1 ? "unessence" : "essence";
    adminApi
      .postElite({
        active_type: type,
        posts_id: post.post_id,
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * 隐藏帖子内图片
 *
 * @param post
 */
const hidePostImages = (post: DDHY.SNSPost): Promise<any> => {
  return new Promise((resolve, reject) => {
    adminApi
      .postHideImage({
        active_type: "hidden",
        posts_id: post.post_id,
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * 判断是否为当前用户发的帖子
 *
 * @param post
 */
export const isMyPost = (post: DDHY.SNSPost): boolean => {
  if (!post) return false;
  const accountInfo = getAccountInfo();
  if (accountInfo) {
    return post.user_info.member_id === accountInfo.user_id;
  }
  return false;
};
/**
 * 当前用户是否有删除帖子权限
 * 1. 当前用户是管理员
 * 2. 该帖子是当前用户发布的
 *
 * @param post 要删除的帖子
 */
export const canIDeletePost = (post: DDHY.SNSPost): boolean => {
  if (!post) return false;
  const accountInfo = getAccountInfo();
  if (accountInfo) {
    return isMyPost(post) || accountInfo.sns_manager === 1;
  }
  return false;
};

/**
 * 当前用户是否有删除评论权限
 * 1. 帖子的is_delete 不能等于1
 * 2. 当前用户是管理员或该评论是当前用户发布的
 *
 * @param comment 要删除的帖子评论
 */
export const canIDeleteComment = (comment: DDHY.SNSPostComment): boolean => {
  if (!comment || comment.is_delete === 1) return false;
  const accountInfo = getAccountInfo();
  if (accountInfo) {
    return (
      accountInfo.sns_manager === 1 ||
      comment.user_info.member_id === accountInfo.user_id
    );
  }
  console.log("canIDeleteComment:", canIDeleteComment);

  return false;
};

/**
 * 删除帖子评论，存在两种情况
 *
 * 1. 删除自己发的评论（无需admin权限）
 * 2. 管理员删除评论
 *
 * @param comment 要删除的帖子评论
 */
export const deleteComment = (comment: DDHY.SNSPostComment): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    const accountInfo = getAccountInfo();
    if (!accountInfo) {
      reject("未获取到用户信息，请刷新重试");
    } else {
      try {
        // 删自己的评论
        if (accountInfo.user_id === comment.user_info.user_id) {
          await postApi.deleteComment({
            comment_id: comment.comment_id,
          });
        } else {
          await adminApi.commentDelete({
            posts_id: comment.post_id,
            comment_id: comment.comment_id,
            active_type: "del",
          });
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    }
  });
};

/**
 * 处理帖子内需要展示的内容
 *
 * @param data
 */
export const handleShowContent = (data: DDHY.SNSPost): string => {
  if (!data) {
    throw Error("帖子不能为空");
  }
  const type = data.post_type;
  switch (type) {
    case 2:
      let recordInfo = data.record_info;
      let content = '';
      if(recordInfo){
        content += `【${recordInfo.item_name}】`;
      }
      content += richTextFilter(data.post_content);
      return content;
    case 3:
      let _data: SharePost = data.post_content;
      return  "地区："+_data.city_name+"；医院名称："+_data.hospital_name+"；科室介绍："+_data.doctor_info+
      "；手术费用："+_data.service_price+"；手术时间："+_data.surgery_time+"。"+_data.service_description
      ;
    default:
      return richTextFilter(data.post_content);
  }
};
