import React from "react";
import "./index.scss";

/**
 * 咨询帮助文档
 *
 * TODO: 已经过时了，如果再使用需要按实际咨询重新编写
 */
const ConsultHelp: React.FC = () => {
  return (
    <div className="HelpDocument">
      <ol>
        <li>收费说明</li>
        <ul>
          <li>单条：</li>
          <p>
            单次付费仅可向医生提问一次，该次提问可免费附0~9张图片和最多3条历史自测。问题提交后不可追问。医生将根据您的提问进行一条或多条回答。如需追问，请根据您的需求购买提问次数。
          </p>
          <li>3条：</li>
          <p>
            单次付费可向医生进行3次提问，首次提问可免费附0~9张图片和最多3条历史自测；后续2次提问则仅可提交文字、提交图片或提交自测。提交3次问题后，将不可再进行追问。医生将根据您的提问进行一条或多条回答。如需追问，请根据您的需求购买提问次数。
          </p>
          <li>10条：</li>
          <p>
            单次付费可向医生进行10次提问，首次提问可免费附0~9张图片和最多3条历史自测；后续9次提问则仅可提交文字、提交图片或提交自测。提交10次问题后，将不可再进行追问。医生将根据您的提问进行一条或多条回答。如需追问，请根据您的需求购买提问次数
          </p>
        </ul>
        <li>续费说明</li>
        <p>
          当前咨询条数使用完后，点击【继续付费咨询】即可继续向医生进行咨询。
        </p>
        <li>咨询有效期</li>
        <p>
          在咨询成功支付后患者在48小时内可向医生进行咨询，超时咨询则会自动关闭；患者续费后倒计时将重置。
        </p>
        <li>退款说明</li>
        <p>
          在患者单次购买的咨询次数中，如果医生未作回复，则在咨询关闭后系统将会自动退款；如果医生进行了回复，则视为医生已经对患者当前的付费做出了服务，再咨询结束后将不会针对这次付费向患者退款。如果患者对医生的咨询有异议或者不满，可以向丁丁客服进行反馈。
        </p>
      </ol>
    </div>
  );
};

export default ConsultHelp;
