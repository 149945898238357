import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "components/basic";
import "./index.scss";

const SearchBar: React.FC<{ appDownloadTips: Function }> = ({ appDownloadTips }) =>{
  const title = '发帖提示';
  const msg = '网页端暂不支持发帖功能，请下载APP后再发帖';
  return (
    <div className="banner-search-bar">
      <Link to="/search" className="search-bar">
        <Icon size="18">sousuo</Icon>
        <span style={{ paddingLeft: "3px", color:"#999", marginLeft: "10px" }}>搜文章 搜帖子</span>
      </Link>
      <div className="btn-create-post" onClick={() => appDownloadTips(title, msg)}>
        发帖
      </div>
    </div>
  );
};


export default SearchBar;
