import React from "react";
import Loading from "components/basic/Animation/Loading";
import { connect } from "react-redux";

const MyMedicine: React.FC<{ accountInfo: DDHYAccount.AccountInfo }> = ({accountInfo}) => {
    window.location.href = "https://mp.ddhaoyi.com/home";

    return (
        <Loading />
    );
};

const mapStateToProps = (state: any) => ({
    accountInfo: state.getIn(["account", "accountInfo"]),
});
export default connect(mapStateToProps)(MyMedicine);
