import React, { memo } from "react";
import "./index.scss";

interface GridProps {
  data: any[];
  column?: number;
  space?: number;
  render(item: any, index: number): JSX.Element;
}

const Grid: React.FC<GridProps> = props => {
  const { data, column = 3, space = 10, render } = props;
  return (
    <div className="Component-Grid">
      {data.map((item: any, index: number) => {
        const isLastItemOfLine: boolean = (index + 1) % column === 0; // 是否是当前行的最后一个元素
        return (
          <div
            className={`Component-Grid__Item ${
              isLastItemOfLine ? "No_Space" : ""
            }`}
            style={{
              marginRight: `${space}px`,
              width: `calc((100% - ${(column - 1) * space}px) / ${column})`
            }}
            key={`grid-item__${index}`}
          >
            {render(item, index)}
          </div>
        );
      })}
    </div>
  );
};

export default memo(Grid);
