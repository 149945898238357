import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import { Flex } from "antd-mobile";
import { richTextFilter, getImageUrl } from "utils/util";
import "./index.scss";
import Divider from "components/basic/Divider";

/**
 * 项目列表项
 */
const ProjectCard: React.FC<{
  data: DProject.Project;
  hideDoctor?: boolean;
}> = (props) => {
  const { data, hideDoctor } = props;
  const history = useHistory();
  const { doctor_info, hospital_info } = data;

  if (!data) return null;

  let itemDescript = data.describe ? richTextFilter(data.describe) : richTextFilter(data.item_description);

  return (
    <div
      className="Component-ProjectCard"
      onClick={() => {
        history.push(`/project/${data.item_id}`);
      }}
    >
      <Flex justify="between" align="center">
        <h4 className="Text-Line__1" style={{ margin: 0, lineHeight: "32px" }}>
          {data.item_name}
        </h4>
        <div style={{ fontSize: 12, marginLeft: "10px", flexShrink: 0 }}>
          <span style={{ color: "red" }}>¥{data.item_true_price}</span>
        </div>
      </Flex>

      {itemDescript &&(
        <div className="ProjectCard-Description Text-Line__4">
          {itemDescript}
        </div>
      )}


      <Divider height={6} />
      {!hideDoctor && (
        <Flex className="ProjectCard-Doctor" style={{
          marginTop:"10px",
          marginBottom:"10px",
        }}>
          <img
            className="Doctor-Avatar"
            style={{ flexShrink: 0 }}
            src={getImageUrl(doctor_info.doctor_avatar)}
            alt=""
          />

          <div className="Doctor-Name" style={{ flexShrink: 0 }}>
            {doctor_info.real_name}
          </div>
          <div style={{ flexShrink: 0 }}>{doctor_info.doctor_level}</div>
          <div style={{flex:1}}></div>
          <div style={{
            marginRight:"10px",
            backgroundColor:"#09f",
            color: "#fff",
            padding:"0 15px",
            borderRadius:"15px",
            height:"24px",
            lineHeight:"24px",
          }}>预约</div>
        </Flex>
      )}

       <Flex className="ProjectCard-Location" align="center" justify="between">
        <Flex>
          <div
            className="Text-Line__1"
            style={{ fontSize: "12px", color: "#888", margin: 0 }}
          >
            {hospital_info.hospital_name}
          </div>
        </Flex>
        <div className="Location-Name" style={{ flexShrink: 0 }}>
          {data.item_location_text}
        </div>
      </Flex>

    </div>
  );
};

export default memo(ProjectCard);
