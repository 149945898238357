import React, { memo, CSSProperties } from "react";
import { Flex } from "antd-mobile";
import Icon from "../Icon";
import "./index.scss";
import { useHistory } from "react-router";

interface TitleBarProps {
  title: string;
  linkText?: string;
  href?: string;
  style?: CSSProperties;
}

/**
 * 标题导航栏
 * @param {*} props
 */
const TitleBar: React.FC<TitleBarProps> = (props) => {
  const history = useHistory();
  const { title, linkText = "查看", href, style } = props;
  return (
    <Flex justify="between" className="Component-TitleBar" style={style}>
      <div className="Title">{title}</div>
      {href && (
        <Flex
          align="center"
          onClick={() => {
            if (href) {
              if (/^\//.test(href)) {
                history.push(href);
              } else {
                window.location.href = href;
              }
            }
          }}
        >
          <span className="LinkTitle">{linkText}</span>
          <Icon color="#ccc" size="16">
            xiangyou1
          </Icon>
        </Flex>
      )}
    </Flex>
  );
};

export default memo(TitleBar);
