import { lazy } from "react";

const Home = lazy(() => import("./Home"));
const CreateDiagnostic = lazy(() => import("./CreateDiagnostic"));
const HistoryDiagnostic = lazy(() => import("./HistoryDiagnostic"));
const DiagnosticDetails = lazy(() => import("./DiagnosticDetails"));
const DiagnosticResult = lazy(() => import("./DiagnosticResult"));
const Success = lazy(() => import("./Success"));

const routes: DDHY.Route[] = [
  {
    path: "/diagnostic/history",
    title: "男科智能问诊助手",
    component: HistoryDiagnostic,
    private: true,
  },
  {
    path: "/diagnostic/create",
    title: "男科智能问诊助手",
    component: CreateDiagnostic,
    private: true,
  },
  {
    path: "/diagnostic/details/:id",
    title: "男科智能问诊助手",
    component: DiagnosticDetails,
    private: true,
  },
  {
    path: "/diagnostic/result/:id",
    title: "男科智能问诊助手",
    component: DiagnosticResult,
    private: true,
  },
  {
    path: "/diagnostic/success",
    title: "男科智能问诊助手",
    component: Success,
  },
  {
    path: "/diagnostic",
    title: "男科智能问诊助手",
    component: Home,
  },
];

export { routes };
