import { IMG_ROOT } from "common/constant";
import { getLocation } from "./weixin";

const setTitle = (title: string) => {
  document.title = title;
};


/**
 * 利用json序列化对数组深拷贝
 *
 * 注意： 在比较古老的浏览器需要使用window.JSON检查是否支持，如果不支持需要转换为递归赋值方式
 * @param array
 */
const deepClone = <T>(obj: T): T | undefined => {
  if (obj) {
    const arrayStr = JSON.stringify(obj);
    return JSON.parse(arrayStr);
  }
  return undefined;
};

/**
 * 异步获取百度地图api当前定位信息
 */
const getAsyncLocation = async (): Promise<any> => {
  return new Promise((resolve, reject) => {
    // 获取定位信息
    let location: any = localStorage.getItem("LOCATION");
    if (location) {
      location = JSON.parse(location);
      resolve(location);
    } else {
      var pms = getLocation();
      pms.then((res) => {
        if (res) {
          localStorage.setItem("LOCATION", JSON.stringify(res));
          resolve(res);
        } else {
          resolve(null);
        }
      });
    }
  });
};

/**
 * 从url查询参数中获取字段对应的值
 *
 * @param {string} field 需要查询的字段
 * @param {string} query 需要查询的query字符串
 *
 */
const getQueryString = (field: string, query: string) => {
  var reg = new RegExp("(^|&)" + field + "=([^&]*)(&|$)", "i");
  var r = query.substring(1).match(reg);
  if (r != null) return r[2];
  return "";
};

/**
 * 获取url中指定字段的值
 *
 * @param field 字段名
 */
const getQueryVariable = (field: string) => {
  let url = window.location.href;

  // 使用浏览器URL接口处理
  if (window.URL) {
    // 去掉hash路由中的/#
    let urlObj = new URL(url.replace("/#", ""));
    return urlObj.searchParams.get(field);
  } else {
    let reg = new RegExp("(^|&)" + field + "=([^&]*)(&|$)", "i");
    let search = url.split("?")[1];
    if (!search) return null;
    let r = search.match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  }
};
/**
 * ### 富文本标签过滤
 * @param {string} richText
 */
const richTextFilter = (richText: string) => {
  if (!richText) return "";
  return richText
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&")
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'")
    .replace(/(<[^>]*>)|(\s+)|(&[a-z]+;)/g, "");
};

/**
 * ### 处理图片url
 * - 没有host的添加图片host
 * @param {string} imgUrl
 */
const getImageUrl = (imgUrl: string) => {
  if (imgUrl) {
    var reg = RegExp(/^(http|https)/);
    if (reg.test(imgUrl)) {
      return imgUrl;
    }
    return IMG_ROOT + imgUrl;
  }
  return "";
};

/**
 * URL格式化函数
 * @param url
 * @returns
 */
const urlFormat = (url: string) => {
  if (url) {
    var reg = RegExp(/^(http|https)/);
    if (reg.test(url)) {
      return url;
    }
    return 'http://'+url;
  }
  return "";
}

/**
 * ### 根据传入的时间戳推断出过了多久并格式化输出
 *  1. 0-59s 刚刚
 *  2. 60-3600s xx分钟前
 *
 * #### 超过一小时
 * 1. xxx小时前
 * 2. 昨天的 昨天
 * 3. 其他 yyyy/MM/dd
 * @param {any} ts 需要推断的时间戳
 */
const timeInference = (ts: any) => {
  // 时间戳毫秒转为秒
  if (ts > 9999999999) {
    ts = Math.floor(ts / 1000);
  }
  // 当前时间戳秒数
  let newTs = Math.floor(new Date().getTime() / 1000);

  // 两个时间相差秒数
  let diffTs = newTs - ts;
  if (diffTs < 0) {
    console.error("[TimeError] 传入的时间大于当前时间");
    return "";
  } else if (diffTs < 3600) {
    if (diffTs <= 59) {
      return "刚刚";
    } else {
      return Math.floor(diffTs / 60) + "分钟前";
    }
  } else {
    let newTsDate: Date = new Date(newTs * 1000);
    let tsDate: Date = new Date(ts * 1000);
    // 同一天
    if (newTsDate.toDateString() === tsDate.toDateString()) {
      return Math.floor(diffTs / 60 / 60) + "小时前";
    } else if (isYestday(tsDate, newTsDate)) {
      return "昨天";
    } else {
      return timestamp2time(tsDate, "yyyy/MM/dd");
    }
  }

  /**
   * 判断theDate是否是昨天
   * @param {*} theDate
   * @param {*} today
   */
  function isYestday(theDate: any, today: any): boolean {
    today = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ).getTime(); //今天凌晨
    var yestday = new Date(today - 24 * 3600 * 1000).getTime();
    return theDate.getTime() < today && yestday <= theDate.getTime();
  }
};

/**
 * ### 根据传入的时间戳推断出过了多少天
 * - 0 当天
 * - others 加入天数
 * @param {numbner} ts 需要推断的时间戳
 */
const timeInference2 = (ts: any) => {
  // 时间戳毫秒转为秒
  if (ts > 9999999999) {
    ts = Math.floor(ts / 1000);
  }
  // 当前时间戳秒数
  let newTs = Math.floor(new Date().getTime() / 1000);

  // 两个时间相差秒数
  let diffTs = newTs - ts;
  if (diffTs < 0) {
    console.error("[TimeError] 传入的时间大于当前时间");
    return 0;
  } else {
    let newTsDate: Date = new Date(newTs * 1000);
    let tsDate: Date = new Date(ts * 1000);
    // 同一天
    if (newTsDate.toDateString() === tsDate.toDateString()) {
      return null;
    } else {
      return Math.ceil(diffTs / (60 * 60 * 24));
    }
  }
};

/**
 * ### 获取剩余时间
 * @param  {Number} closeTime  关闭时间
 * @return {Object}            剩余时间对象
 */
const getRemainTime = (closeTime: any, showShort=false) => {
  const t = closeTime * 1000 - +new Date();
  const seconds = Math.floor((t / 1000) % 60);
  const minutes = Math.floor((t / 1000 / 60) % 60);
  const hours = Math.floor(t / (1000 * 60 * 60));
  const days = Math.floor(t / (1000 * 60 * 60 * 24));
  if(showShort){
    if(days > 0){
      return `${days}天`;
    }else if(hours >= 1){
      return `${hours}小时`;
    }else if(minutes >= 1){
      return `${minutes}分钟`;
    }else{
      return `${seconds}秒`;
    }
  }else{
    let _tday = '';
    if(days > 0){
      _tday = `${days}天`;
    }
    return `${_tday}${hours}:${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    }`;
  }

};

/**
 * ### 日期格式化
 * @param {Date} date 要格式的日期对象
 * @param {String} fmt 格式化字符串模板
 */
const dateFormat = (date: any, fmt: string) => {
  try {
    let o: any = {
      "M+": date.getMonth() + 1, //月份
      "d+": date.getDate(), //日
      "h+": date.getHours(), //小时
      "m+": date.getMinutes(), //分
      "s+": date.getSeconds(), //秒
      "q+": Math.floor((date.getMonth() + 3) / 3), //季度
      S: date.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + "").substring(4 - RegExp.$1.length)
      );
    }
    for (var k in o) {
      if (new RegExp("(" + k + ")").test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ("00" + o[k]).substring(("" + o[k]).length)
        );
      }
    }
  } catch (error) {
    console.error(error);
  }
  return fmt;
};

/**
 * ### 时间戳格式化为日期
 * @param {时间戳} timestamp 时间戳
 * @param {String} fmt 格式化字符串模板
 */
const timestamp2time = (timestamp: any, fmt: any = "yyyy-MM-dd") => {
  if (!timestamp) return "";
  try {
    if (timestamp <= 9999999999) {
      // 如果传入的时间戳是秒的 转位毫秒
      timestamp *= 1000;
    }
    let date = new Date(timestamp);
    return dateFormat(date, fmt);
  } catch (error) {
    console.error(error);
  }
};
/**
 * 从url中获取code的值
 */
// http://cs.m.ddhaoyi.com/login?code=0011mMYn0Fp8rn1AdMUn0YxyYn01mMYC&state=ddinghy
const getCodeFromUrl = () => {
  const url = window.location.href;
  if (window.location.search.length > 0) {
    let temp: any = window.location.search;
    if (temp.match(/=.*&/)) {
      // 解析Code
      let code: any = temp.match(/=.*&/)[0];
      code = code.substr(1, code.length); // 去掉 ?
      code = code.substr(0, code.length - 1); // 去掉 #
      // 重置URL(去掉code参数，避免重复调用)
      window.history.pushState(
        {},
        "",
        url.replace(temp.substr(0, temp.length), "")
      );
      return code;
    }
  }
  return null;
};

/**
 * 指定滚动条位置
 *
 * @param scrollTop
 */
const setScrollTop = (scrollTop: number) => {
  if (scrollTop <= 0) {
    scrollTop = 1;
  }

  setTimeout(() => {
    document.body.scrollTop = scrollTop;
    // 兼容不支持document.body.scrollTop的浏览器（如safari）
    if (document.body.scrollTop !== scrollTop) {
      document.documentElement.scrollTop = scrollTop;
    }
  });
};

/**
 * 去掉微信分享自带的参数
 */
const removeWeiXinShareParams = () => {
  if (
    /from=[^&$?]{1,}(&|$)/.test(window.location.search) ||
    /isappinstalled=[^&$?]{1,}(&|$)/.test(window.location.search)
  ) {
    var newSearch = window.location.search
      .replace(/from=[^&$?]{1,}(&|$)/, "")
      .replace(/isappinstalled=[^&$?]{1,}(&|$)/, "")
      .replace(/&$|\?$/, "");
    var newUrl =
      window.location.origin +
      window.location.pathname +
      newSearch +
      window.location.hash;
    window.location.replace(newUrl);
  }
};

/**
 * 设备信息
 */
const device = {
  versions: (function () {
    var u = navigator.userAgent;
    return {
      userAgent: u,
      //移动终端浏览器版本信息
      trident: u.indexOf("Trident") > -1, //IE内核
      presto: u.indexOf("Presto") > -1, //opera内核
      webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
      gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") === -1, //火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
      android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或者uc浏览器
      iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
      iPad: u.indexOf("iPad") > -1, //是否iPad
      webApp: u.indexOf("Safari") === -1, //是否web应该程序，没有头部与底部
      wx: !!u.match(/MicroMessenger/i), // 是否为微信浏览器
      xiaomi: u.match(/MiuiBrowser/i), // 是否为小米手机内置览器
      chrome: u.indexOf("Chrome") > -1, // 是否Chrome
    };
  })(),
  language: navigator.language.toLowerCase(),
};

/**
 * 防止在IOS中input失去焦点时屏幕底部不自动回弹
 */
const inputBlur = () => {
  let ua = window.navigator.userAgent;
  if (!!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    //$alert('ios端');
    var currentPosition: any, timer: any;
    var speed = 10;
    timer = setInterval(function () {
      currentPosition =
        document.documentElement.scrollTop || document.body.scrollTop;
      currentPosition -= speed;
      window.scrollTo(0, currentPosition); //页面向上滚动
      currentPosition += speed;
      window.scrollTo(0, currentPosition); //页面向下滚动
      clearInterval(timer);
    }, 100);
  } else if (ua.indexOf("Android") > -1 || ua.indexOf("Adr") > -1) {
    //$alert('android端');
  }
};

/**
 * 延迟响应函数
 */
const delayResponse = (
  callback: Function | Promise<any>,
  delay: number
): Promise<any> => {
  return new Promise(async (resolve: any, reject: any) => {
    const startTime = new Date().getTime();
    try {
      const response = await callback;
      const endTime = new Date().getTime();
      const totalTime = endTime - startTime;
      if (totalTime < delay) {
        // 如果请求耗时小于300ms 则延迟到300ms再返回数据
        setTimeout(() => {
          resolve(response);
        }, delay - totalTime);
      } else {
        resolve(response);
      }
    } catch (error) {
      const endTime = new Date().getTime();
      const totalTime = endTime - startTime;
      if (totalTime < delay) {
        // 如果请求耗时小于300ms 则延迟到300ms再返回数据
        setTimeout(() => {
          reject(error);
        }, delay - totalTime);
      } else {
        reject(error);
      }
    }
  });
};

export {
  setTitle,
  deepClone,
  getAsyncLocation,
  getQueryString,
  getQueryVariable,
  richTextFilter,
  getImageUrl,
  urlFormat,
  setScrollTop,
  timeInference,
  timeInference2,
  getRemainTime,
  timestamp2time,
  dateFormat,
  getCodeFromUrl,
  removeWeiXinShareParams,
  device,
  inputBlur,
  delayResponse,
};
