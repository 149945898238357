import DDHY from "ddhy";
import * as actionTypes from "./constants";

// 付费训练讨论页数据结构
export interface DiscussState {
  notes?: DDHY.NoteInfo[];
  page?: number;
  hasMore?: boolean;
}

// 付费训练页数据结构
export interface TraininfoState {
  tabIndex: number;  // 当前tab
  details?: DDHY.Training;
  sales: DDHY.SaleInfo[];
  items: DDHY.TrainingItem[];
  discuss: DiscussState;
}

// 默认数据
const defaultState: TraininfoState = {
  tabIndex: 0,
  items:[],
  sales:[],
  discuss: {
    page: 1,
    hasMore: true,
  },
};

// 导出reducer
export default (state = defaultState, action: any): TraininfoState => {
  switch (action.type) {
    // Tab 索引 
    case actionTypes.SET_TAB_INDEX:
      return { ...state, tabIndex: action.data };
      
    // 训练详情
    case actionTypes.SET_TRAINING:
      return { ...state, details: action.data};

    // 训练滚动
    case actionTypes.SET_TRAINING_SLIDER:
      return { ...state, sales: action.data};

    // 训练目录
    case actionTypes.SET_TRAINING_ITEMS:
      return { ...state, items: action.data};

    // 训练讨论
    case actionTypes.SET_TRAINING_DISCUSS:
      return { ...state, discuss: { ...state.discuss, notes: action.data } };
    case actionTypes.SET_TRAINING_DISCUSS_PAGE:
      return { ...state, discuss: { ...state.discuss, page: action.data } };
    case actionTypes.SET_TRAINING_DISCUSS_HASMORE:
      return { ...state, discuss: { ...state.discuss, hasMore: action.data } };
    default:
      return state;
  }
};
