import React, { useCallback, useState, useRef } from "react";
import Cropper from "react-cropper";
import ReactDOM from "react-dom";
import "cropperjs/dist/cropper.css";
const COMPONENT_ID = "ImageCropper";

/**
 * 图片压缩
 *
 * @param {file} file 文件(类型是图片格式)
 * @param {any} w
 * @param {function} objDiv 容器或者回调函数
 */
const photoCompress = (file: File, w: any, objDiv: Function) => {
  var ready = new FileReader();
  /*开始读取指定的Blob对象或File对象中的内容. 当读取操作完成时,readyState属性的值会成为DONE,如果设置了onloadend事件处理程序,则调用之.同时,result属性中将包含一个data: URL格式的字符串以表示所读取文件的内容.*/
  ready.readAsDataURL(file);
  ready.onload = function() {
    var re = this.result;
    if (re) {
      canvasDataURL(re.toString(), w, objDiv);
    }
  };
};
const canvasDataURL = (path: string, obj: any, callback: Function) => {
  var img = new Image();
  img.src = path;
  img.onload = function() {
    var that: any = this;
    // 默认按比例压缩
    var w = that.width,
      h = that.height,
      scale = w / h;
    w = obj.width || w;
    h = obj.height || w / scale;
    var quality = 0.7; // 默认图片质量为0.7
    //生成canvas
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    if (ctx) {
      // 创建属性节点
      var anw = document.createAttribute("width");
      anw.nodeValue = w;
      var anh = document.createAttribute("height");
      anh.nodeValue = h;
      canvas.setAttributeNode(anw);
      canvas.setAttributeNode(anh);
      ctx.drawImage(that, 0, 0, w, h);
      // 图像质量
      if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
        quality = obj.quality;
      }
      // quality值越小，所绘制出的图像越模糊
      var base64 = canvas.toDataURL("image/jpeg", quality);
      // 回调函数返回base64的值
      callback(base64);
    }
  };
};
const blobToFile = (theBlob: any, fileName: string) => {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
};

/*
 * 图片裁剪 全局组件
 * 使用方式：
 * ```js
 * import Cropper from "@/components/basic"
 * Cropper.open(imageFile, (file) => {
 * })
 * ```
 */

interface ImageCropperOperation {
  image: File; // 传入的图片
  onComplete(value: any): void; // 完成回调
}
interface MultipleProps extends React.FC<{ operation: ImageCropperOperation }> {
  open(operation: ImageCropperOperation): void;
  destory(): void;
}
const ImageCropper: MultipleProps = props => {
  const { operation } = props;
  const { image, onComplete } = operation;
  const [src, setSrc] = React.useState();
  const [fileName] = useState(() => {
    return image.name;
  });
  const cropperRef = useRef<HTMLImageElement>(null);

  React.useEffect(() => {
    const fileReader = new FileReader();
    fileReader.onload = e => {
      const dataURL: any = e.target ? e.target.result : undefined;
      if (dataURL) {
        setSrc(dataURL);
      }
    };
    fileReader.readAsDataURL(image);
  }, [image]);

  /**
   * 将以base64的图片url数据转换为Blob
   * @param urlData
   */
  const convertBase64UrlToBlob = useCallback((urlData: any) => {
    var arr = urlData.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }, []);

  /**
   * 图片裁剪
   */
  const cropImage = () => {
    const imageElement: any = cropperRef.current;
    const cropperObj: any = imageElement.cropper;

    if(!imageElement || !cropperObj || !cropperObj.getCroppedCanvas() || cropperObj.getCroppedCanvas() === 'undefined') {
      return;
    }

    cropperObj.getCroppedCanvas().toBlob((e: any) => {
      if (e.size / 1024 > 1025) {
        photoCompress(
          e,
          {
            quality: 0.2
          },
          (base64Codes: any) => {
            var bl = convertBase64UrlToBlob(base64Codes);
            onComplete(blobToFile(bl, fileName));
          }
        );
      } else {
        onComplete(blobToFile(e, fileName));
      }
    });
    destroy();
  };

  const destroy = () => {
    const div = document.getElementById(COMPONENT_ID);
    if (div) {
      ReactDOM.unmountComponentAtNode(div);
      document.body.removeChild(div);
    }
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: 100,
        background: "rgb(0,0,0)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <div
        style={{
          width: "100%",
          height: "calc(100% - 88px)",
          flex: "1 1 auto"
        }}
      >
        <Cropper
          src={src}
          style={{ height: "100%", width: "100%" }}
          aspectRatio={1 / 1}
          background={false}
          preview=".img-preview"
          guides={false}
          ref = {cropperRef}
          // ref={(ref: any) => (cropperRef.current = ref)}
        />
      </div>
      <div
        style={{
          height: "44px",
          width: "100%",
          padding: "0 10px 25px",
          display: "flex",
          lineHeight: "44px",
          justifyContent: "space-between"
        }}
      >
        <span
          style={{ color: "#fff", padding: "0 15px" }}
          onClick={() => {
            destroy();
          }}
        >
          取消
        </span>
        <span style={{ color: "#fff", padding: "0 15px" }} onClick={cropImage}>
          完成
        </span>
      </div>
    </div>
  );
};

ImageCropper.open = (operation: ImageCropperOperation) => {
  let div = document.createElement("div");
  div.id = COMPONENT_ID;
  document.body.appendChild(div);
  let instance = ReactDOM.render(
    React.createElement(ImageCropper, { operation }),
    div
  );
  return {
    instance,
    destroy() {
      ReactDOM.unmountComponentAtNode(div);
      document.body.removeChild(div);
    }
  };
};
ImageCropper.destory = () => {
  const div = document.getElementById(COMPONENT_ID);
  if (div) {
    ReactDOM.unmountComponentAtNode(div);
    document.body.removeChild(div);
  }
};

export default ImageCropper;
