import * as actionTypes from "./constants";

export interface FindDoctorState {
  use?: boolean;
  tags?: Array<DDHY.Tag>; // 筛选栏的病种标签数据
  regions?: any[]; // 筛选栏的区域列表数据
  filter?: any; // 筛选栏的医生排序列表数据
  hasRecommend: boolean;
  hasMore: boolean;
  doctorList?: any[];
  doctorsPage: number;
  doctorsHasMore: boolean;
  recommendPage: number;
  recommendHasMore: boolean;
}

const defaultState: FindDoctorState = {
  hasMore: true,
  doctorsPage: 1,
  doctorsHasMore: true,
  recommendPage: 1,
  recommendHasMore: true,
  hasRecommend: false
};

export default (state = defaultState, action: any): FindDoctorState => {
  switch (action.type) {
    case actionTypes.SET_USE_STATE:
      return { ...state, use: action.data };
    case actionTypes.SET_TAGS:
      return { ...state, tags: action.data };
    case actionTypes.SET_REGIONS:
      return { ...state, regions: action.data };
    case actionTypes.SET_FILTER:
      return { ...state, filter: action.data };
    // 医生列表页面数据状态
    case actionTypes.SET_HASMORE:
      return { ...state, hasMore: action.data };
    case actionTypes.SET_DOCTOR_LIST:
      return { ...state, doctorList: action.data };
    // 医生列表
    case actionTypes.SET_DOCTORPAGE:
      return { ...state, doctorsPage: action.data };
    case actionTypes.SET_DOCTORHASMORE:
      return { ...state, doctorsHasMore: action.data };
    // 推荐医生列表
    case actionTypes.SET_RECOMMENDPAGE:
      return { ...state, recommendPage: action.data };
    case actionTypes.SET_RECOMMENDHASMORE:
      return { ...state, recommendHasMore: action.data };

    case actionTypes.SET_HASRECOMMEND:
        return { ...state, hasRecommend: action.data };

    default:
      return state;
  }
};
