import React, { CSSProperties } from "react";
import "./index.scss";

const SafeArea: React.FC<{
  style?: CSSProperties;
}> = (props) => {
  return (
    <div style={props.style} className="Component-SafeArea">
      {props.children}
    </div>
  );
};

export default SafeArea;
