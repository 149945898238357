export const SET_TAB_INDEX = "profile/SET_TAB_INDEX";

// Post
export const SET_POST_LIST = "profile/SET_POST_LIST";
export const SET_POST_PAGE = "profile/SET_POST_PAGE";
export const SET_POST_HASMORE = "profile/SET_POST_HASMORE";

// Member
export const SET_MEMBER_LIST = "profile/SET_MEMBER_LIST";
export const SET_MEMBER_PAGE = "profile/SET_MEMBER_PAGE";
export const SET_MEMBER_HASMORE = "profile/SET_MEMBER_HASMORE";

// Topic
export const SET_TOPIC_LIST = "profile/SET_TOPIC_LIST";
export const SET_TOPIC_PAGE = "profile/SET_TOPIC_PAGE";
export const SET_TOPIC_HASMORE = "profile/SET_TOPIC_HASMORE";
