import { lazy } from "react";

const Aggregation = lazy(() => import("./index"));


const routes: DDHY.Route[] = [
  { path: "/aggregation/:id", title: "特色专题", component: Aggregation },
];

export { routes };
