import specialApi from "api/special";
import * as constatnts from "./constants";
import { Toast } from "antd-mobile";
import { SpecialState } from "./reducer";
import { handleResponseError } from "utils/request";

// Special
export const changeSpecialDetails = (data?: any) => ({
  type: constatnts.SET_DETAILS,
  data,
});
export const cleanSpecial = () => {
  return (d: any) => {
    d(changeSpecialDetails());
  };
};

/**
 * 初始化
 *
 * @component Special
 * @param id 专栏ID
 */
export const initSpecial = (id: number | string, cb?: Function) => {
  return (dispatch: any) => {
    const pms = specialApi.getDetails(id);
    pms.then((result: any) => {
      dispatch(changeSpecialDetails(result));
      cb && cb();
    });
    pms.catch((error) => {
      cb && cb(error);
    });
  };
};

/**
 * 收藏专栏
 *
 * @component Special
 */
export const handleCollectionSpecial = () => {
  return (dispatch: any, getState: any) => {
    const { specialDetails } = getState().getIn(["classroom", "special"]);

    if (!specialDetails) {
      Toast.info("获取专栏信息失败！请刷新重试");
      return;
    }
    specialApi
      .userOperation(specialDetails.special_id, 3)
      .then(() => {
        const special = {
          ...specialDetails,
          is_collection: !specialDetails.is_collection,
        };
        dispatch(changeSpecialDetails(special));
        Toast.success("操作成功", 2, undefined, false);
      })
      .catch((error) => {
        handleResponseError(error);
      });
  };
};

// SpecialList
export const changeSpecials = (data?: Array<DDHY.Special>) => ({
  type: constatnts.SET_SPECIALS,
  data,
});
export const changeSpecialsPage = (data: number) => ({
  type: constatnts.SET_SPECIALS_PAGE,
  data,
});
export const changeSpecialsHasMore = (data: boolean) => ({
  type: constatnts.SET_SPECIALS_HASMORE,
  data,
});
export const cleanSpecialList = () => {
  return (d: any) => {
    d(changeSpecials());
    d(changeSpecialsPage(1));
    d(changeSpecialsHasMore(true));
  };
};
/**
 * 获取专栏列表
 */
export const loadSpecials = (callback?: Function) => {
  return async (dispatch: any, getState: any) => {
    const state: SpecialState = getState().getIn(["classroom", "special"]);
    const { specials, specialsPage } = state;
    const limit = 10;
    try {
      const { data } = await specialApi.query({
        page: specialsPage,
        limit,
      });

      if (Array.isArray(data)) {
        if (specialsPage === 1 || !specials) {
          dispatch(changeSpecials(data));
        } else {
          dispatch(changeSpecials([...specials, ...data]));
        }
        dispatch(changeSpecialsHasMore(data.length === limit));
        callback && callback();
      } else {
        callback && callback("数据错误");
      }
    } catch (error) {
      callback && callback(error);
    }
  };
};
