import React, { useState, useEffect, useCallback, memo } from "react";
import { connect, useDispatch } from "react-redux";
import { Flex, Menu } from "antd-mobile";
import { initFindDoctor, loadingDoctorListData, handleFilterChange} from "./actions";
import { Icon, Error } from "components/basic";
import DataList, { DataListFooter } from "components/basic/DataList";
import { Doctor } from "components/business";
import { customShare } from "utils/weixin";
import { changeDocotrPage, changeRecommendPage } from "./store/actionCreators";
import { FindDoctorState } from "./store/reducer";
import usePageCache from "hooks/usePageCache";
import "./index.scss";

import { LOGO } from "common/constant";


/**
 * 找医生页面
 */
const FindDoctor: React.FC<FindDoctorState> = (props) => {

  usePageCache();

  const dispatch = useDispatch();
  const { doctorList, hasMore, doctorsPage, doctorsHasMore, recommendPage, recommendHasMore, use } = props;
  const [error, setError] = useState("");
  const [doctorsLoading, setDoctorsLoading] = useState(false);

  const loadDoctorData = useCallback(() => {
    setDoctorsLoading(true);
    dispatch(
      loadingDoctorListData(() => {
        setDoctorsLoading(false);
      })
    );
  }, [dispatch]);

  const handleLoadMoreDoctor = useCallback(() => {
    if (doctorsLoading || !hasMore) return;

    setDoctorsLoading(true);

    if(doctorsHasMore){
      dispatch(changeDocotrPage(doctorsPage + 1));
    }else if(recommendHasMore){
      dispatch(changeRecommendPage(recommendPage + 1));
    }

    setTimeout(() => {
      loadDoctorData();
    }, 1000);
  }, [dispatch, doctorsHasMore, doctorsLoading, doctorsPage, hasMore, loadDoctorData, recommendHasMore, recommendPage]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    customShare({
      title: "咨询预约-丁丁好医", // 分享标题
      desc: "实时咨询公立医生，预约各地医院线下诊疗",
      imgUrl:LOGO // 分享描述
    });

    if (!use) {
      dispatch(
        initFindDoctor((error: any) => {
          if (error) {
            setError(error);
          } else {
            loadDoctorData();
          }
        })
      );
    }
  }, [dispatch, loadDoctorData, use]);

  if (error) {
    return <Error error={error} />;
  } else {
    return (
      <div className="Screen-FindDoctor">
        {/* 筛选过滤栏 */}
        <FilterBar
          {...props}
          onChange={(v) => {
            dispatch(handleFilterChange(v, loadDoctorData));
          }}
        />

        {/* 数据列表 */}
        <DataList
          rows={doctorList}
          onPullUpLoad={handleLoadMoreDoctor}
          renderRow={(data, index) => {
            let propertyName = 'show_lable';
            if(propertyName in data){
              return (
                <div key={index} style={{textAlign: "center", color: "rgb(170, 170, 170)", margin: "30px auto"}}>
                  为您推荐
                </div>
              );
            }
            return <Doctor key={index} doctor={data} />;
          }}
          renderFooter={
            <DataListFooter loading={doctorsLoading} hasMore={hasMore} />
          }
          renderEmptyView={<div />}
        />
      </div>
    );
  }
};

const mapStateToProps = (state: any) => ({
  ...state.getIn(["doctor", "findDoctor"]),
});
export default connect(mapStateToProps)(memo(FindDoctor));

/**
 * [找医生]筛选栏
 */
const FilterBar: React.FC<{
  tags?: Array<DDHY.Tag>; // 筛选栏的病种标签数据
  regions?: any[]; // 筛选栏的区域列表数据
  filter?: any; // 筛选栏的医生排序列表数据
  onChange(v: any): void;
}> = (props) => {
  const { tags, regions, filter, onChange } = props;
  const [index, setIndex] = useState<number | undefined>();

  // 下拉菜单
  const renderMenu = useCallback(() => {
    if (!filter) return null;
    const { tag, region } = filter;
    switch (index) {
      case 0:
        if (tags && tag) {
          return (
            <Menu
              className="tag-menu"
              data={tags}
              value={tag}
              level={1}
              onChange={(value) => {
                onChange({ tag: value });
                setIndex(undefined);
              }}
            />
          );
        }
        return null;
      case 1:
        return (
          <Menu
            className="region-menu"
            data={regions}
            value={region}
            onChange={(value) => {
              onChange({ region: value });
              setIndex(undefined);
            }}
          />
        );
      default:
        return null;
    }
  }, [filter, index, onChange, regions, tags]);

  // 遮罩层
  const renderMask = useCallback(() => {
    if (index !== undefined) {
      return (
        <div
          className="FindDoctor-Mask"
          onClick={() => {
            setIndex(undefined);
          }}
        />
      );
    }
    return null;
  }, [index]);

  const items = ["类别", "地区"];
  return (
    <>
      <div className="FindDoctor-FilterBar">
        <Flex className="FilterBar-Menus">
          {items.map((item: string, i: number) => {
            const active = i === index;
            return (
              <Flex.Item
                key={`menu-${i}`}
                className={active ? "item active" : "item"}
                onClick={() => {
                  if (index === i) {
                    setIndex(undefined);
                  } else {
                    setIndex(i);
                  }
                }}
              >
                <span style={{ marginRight: "3px" }}>{item}</span>
                <Icon size="12">xiangxia1</Icon>
              </Flex.Item>
            );
          })}
        </Flex>
        {renderMenu()}
      </div>
      {renderMask()}
    </>
  );
};
