import React, { useState } from "react";


import { device, getQueryVariable } from "utils/util";
import { getLoginBeforePath, getToken, setLoginBeforePath } from "utils/storage";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAccountInfo } from "screens/Account/actions";
import { wxOauth2 } from "utils/weixin";
import accountApi from "api/account";
import { changeAccountInfo } from "screens/Account/store/actionCreators";
import App from "./App";

import "./loading.scss"
import DDHYLOGO from "./common/assets/images/logo.png"

export default function Loading(){
  const history = useHistory();
  const dispatch = useDispatch();

  // 获取当前已登录账号信息
  const [ accountInfo, setAccessInfo ] = useState(getAccountInfo());

  // 获取本地存储 Token
  const token = getToken();

  // 获取当前设备浏览器类型
  const isWeiXin = device.versions.wx;

  // sessionStorage.removeItem('login_before_path');

  /**
   * 调用微信接口获取code
   */
  function getWxAuthCode(){
    let redirectUrl = window.location.href;
    let _redirectUrl = getQueryVariable("redirectUrl");
    if (_redirectUrl) {
      redirectUrl = decodeURIComponent(_redirectUrl);
    }
    setLoginBeforePath(redirectUrl);
    wxOauth2();
  }

  /**
   * 使用微信 code 授权登录
   */
  function handleCodeLogin(code: string){
    accountApi.wxAuth(code).then((memberInfo) => {
      if (memberInfo) {
        dispatch(changeAccountInfo(memberInfo));

        // 登录成功处理
        loginSuccessRloadApp(memberInfo);
      }else{}
    });
  }

  /**
   * 通过 token 获取用户信息
   */
  function getMemberInfoByToken(){
    let redirectUrl = window.location.href;
    let _redirectUrl = getQueryVariable("redirectUrl");
    if (_redirectUrl) {
      redirectUrl = decodeURIComponent(_redirectUrl);
    }
    setLoginBeforePath(redirectUrl);

    accountApi.getAccountInfoByToken().then((memberInfo) => {
      if(memberInfo){
        dispatch(changeAccountInfo(memberInfo));

        // 登录成功处理
        loginSuccessRloadApp(memberInfo);
      }else{}
    });
  }

  /**
   * 登录成功页面重定向
   */
  function loginSuccessRloadApp(memberInfo){
    setAccessInfo(memberInfo);
    let jump_url = memberInfo.jump_url;
    if (jump_url) {
      history.push(jump_url);
    } else {
      let path = getLoginBeforePath();
      if (path) {
        var reg = RegExp(/^(http|https)/);
        if (reg.test(path)) {
          window.history.replaceState("", "", path);
        } else {
          history.replace(path);
        }
      } else {
        history.push("/home");
      }
    }
  }


  if(accountInfo){
    return <App />;
  }else{
    if(token){
      // 登录过再次访问
      getMemberInfoByToken();
    }else if(isWeiXin){
      // 微信浏览器初次打开
      let code = getQueryVariable("code");
      if (code) {
        handleCodeLogin(code);
      } else {
        getWxAuthCode();
      }
      console.log('ok');
    }
  }

  return (
    <div className="loading_main">
      <div className="loading-animation">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <h2>努力加载中...</h2>
      </div>

      <div className="ddhy-slogan">
        <div className="logo">
          <img className="logo-img" src={DDHYLOGO} alt="logo-img"/>
          <div className="logo-txt">丁丁好医</div>
        </div>
        <div className="slogan-txt">专注男性健康的数字医疗平台</div>
      </div>
    </div>
  );
}
