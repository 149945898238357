import React from "react";
import { useHistory } from "react-router-dom";
import { Flex } from "antd-mobile";
import "./index.scss";
import { Avatar } from "components/basic";

interface DoctorProps {
  doctor: {
    access_id: number;
    doctor_id: number;
    real_name: string;
    avatar_url: string;
    doctor_avatar: string
    composite_score: number;
    advisory_num: number;
    price?: number;
    doctor_label?: Array<string>;
    doctor_level?: string;
    doctor_reservations?: any[];
    doctor_tags?: string;
    hospital_name?: string;
  };
}

const Doctor: React.FC<DoctorProps> = ({ doctor }) => {

  const history = useHistory();
  let score = "0";
  if (doctor.composite_score) {
    score = doctor.composite_score.toFixed(1);
  }
  return (
    <div
      className="Component-Doctor"
      onClick={() => {
        history.push("/doctor/" + doctor.access_id);
      }}
    >
      <Flex align="start">
        <Avatar
          src={doctor.avatar_url}
          style={{ width: "4.5rem", height: "4.5rem", borderRadius: 5 }}
        />
        <Flex.Item>
          <Flex style={{ height: "1.5rem" }}>
            <Flex.Item>
              <div>
                <span
                  style={{
                    fontSize: "16px",
                    color: "#353535",
                    marginRight: "5px",
                  }}
                >
                  {doctor.real_name}
                </span>
                <span>{doctor.doctor_level}</span>
              </div>
            </Flex.Item>

            {doctor.doctor_label != null ? <div className="doctor-label">
              <span>{doctor.doctor_label[0]}</span>
            </div>: <></>}
          </Flex>
          <Flex style={{ height: "1.5rem", fontSize: "12px" }} align="end">
            <span className="Text-Line__1">{doctor.hospital_name}</span>
          </Flex>
          <Flex style={{ height: "1.5rem", fontSize: "12px" }} align="end">
            <span className="Text-Line__1">擅长：{doctor.doctor_tags}</span>
          </Flex>
        </Flex.Item>
      </Flex>
      <Flex justify="end" style={{ paddingTop: "8px", fontSize:"14px" }}>
        <div style={{ fontSize: "12px", lineHeight: "24px" }}>
          <span style={{ marginRight: "3px"}}>评分</span>
          <span style={{ color:"#09f" }}>{score}</span>
        </div>
        <div style={{ fontSize: "12px", lineHeight: "24px", marginLeft:"15px" }}>
          <span style={{ marginRight: "3px"}}>咨询量</span>
          <span style={{ color:"#09f" }}>{doctor.advisory_num}</span>
        </div>
        {doctor.price > 0 && <div style={{ fontSize: "12px", lineHeight: "24px", marginLeft:"15px" }}>
          <span style={{ marginRight: "3px"}}>价格</span>
          <span style={{ color:"#09f" }}>{doctor.price}元</span>
        </div>}

      </Flex>

      {doctor.doctor_reservations && doctor.doctor_reservations.length > 0 && (
        <div>
          <Flex align="start" className="Dcotor-Reservations">
            <span>可约项目 : </span>
            <Flex.Item>
              <Flex wrap="wrap">
                {doctor.doctor_reservations.map((item: any, index: number) => {
                  if (index < 2) {
                    return (
                      <div key={`service-${index}`} className="Doctor-Service">
                        {item.item_name}
                      </div>
                    );
                  }
                  return null;
                })}
              </Flex>
            </Flex.Item>
          </Flex>
        </div>
      )}
    </div>
  );
};
export default Doctor;
