import { lazy } from "react";

const GetIntegral = lazy(() => import("./GetIntegral"));
const IntegralRule = lazy(() => import("./IntegralRule"));

const routes: DDHY.Route[] = [
  {
    path: "/integral/rule",
    title: "积分规则",
    component: IntegralRule,
  },
  {
    path: "/integral/get",
    title: "快速赚取积分",
    component: GetIntegral,
  },
];

export { routes };
