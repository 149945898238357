import { lazy } from "react";

const Message = lazy(() => import("./Home"));
const SnsSupportMessage = lazy(() => import("./Other/SnsSupportMessage"));
const SnsCommentMessage = lazy(() => import("./Other/SnsCommentMessage"));
const DoctorChatMessage = lazy(() => import("./Other/DoctorChatMessage"));
const SnsChatMessage = lazy(() => import("./Other/SnsChatMessage"));
const SystemNoticeMessage = lazy(() => import("./Other/SystemNoticeMessage"));




// const Notice = lazy(() => import("./Notice"));
// const SystemNotice = lazy(() => import("./Notice/SystemNotice"));
// const Interaction = lazy(() => import("./Interaction"));

const routes: DDHY.Route[] = [
  // 消息主页面
  { path: "/message", title: "消息", component: Message, private: true },
  // 点赞消息
  { path: "/support-message", title: "点赞", component: SnsSupportMessage, private: true },
  // 评论消息
  { path: "/comment-message", title: "评论", component: SnsCommentMessage, private: true },
  // 私信消息
  { path: "/chat-message", title: "私信", component: SnsChatMessage, private: true },
  // 咨询/预约消息
  { path: "/doctor-message/:message_type", title: "医生消息", component: DoctorChatMessage, private: true },
  // 系统消息
  { path: "/notice-message/:from_id/:from_name", title: "消息提醒", component: SystemNoticeMessage, private: true },




  // { path: "/notice", title: "消息提醒", component: Notice, private: true },
  // {
  //   path: "/system-notice",
  //   title: "系统通知",
  //   component: SystemNotice,
  //   private: true,
  // },
  // {
  //   path: "/interactive",
  //   title: "互动消息",
  //   component: Interaction,
  //   private: true,
  // },
];

export { routes };
