import mainApi from "api/main";
import * as constants from "./constants";
import { Toast } from "antd-mobile";
import {
  SearchArticleState,
  SearchPostState,
  SearchDoctorState,
  SearchProjectState,
  SearchHospitalState,
} from "./reducer";

// Search
export const changeTopSearch = (data: string[]) => ({
  type: constants.SET_TOP_SEARCH,
  data,
});
export const changeHistorySearch = (data: string[]) => ({
  type: constants.SET_HISTORY_SEARCH,
  data,
});
export const changeSearchKey = (data: string) => ({
  type: constants.SET_SEARCH_KEY,
  data,
});
export const changeSearched = (data: boolean) => ({
  type: constants.SET_SEARCHED,
  data,
});
export const changeSearchTabIndex = (data: number) => ({
  type: constants.SET_TAB_INDEX,
  data,
});
/**
 * 清除所有缓存数据
 *
 * @param onlyResult 仅清除搜索结果
 * @component Search
 */
export const cleanSearch = (onlyResult: boolean = false) => {
  return (dispatch: any) => {
    if (!onlyResult) {
      dispatch(changeSearchKey(""));
      dispatch(changeSearchTabIndex(0));
      dispatch(changeSearched(false));
    }
    dispatch(cleanSearchArticle());
    dispatch(cleanSearchDoctor());
    dispatch(cleanSearchPost());
    dispatch(cleanSearchProject());
  };
};

// SearchArticle
export const changeSearchArticleRows = (data?: Array<DDHY.Article>) => ({
  type: constants.SET_ARTICLE_LIST,
  data,
});
export const changeSearchArticlePage = (data: number) => ({
  type: constants.SET_ARTICLE_PAGE,
  data,
});
export const changeSearchArticleHasMore = (data: boolean) => ({
  type: constants.SET_ARTICLE_HASMORE,
  data,
});

/**
 * 清除缓存
 *
 * @component SearchArticle
 */
export const cleanSearchArticle = () => {
  return (d: any, s: any) => {
    d(changeSearchArticleRows());
    d(changeSearchArticlePage(1));
  };
};

/**
 * 搜索文章数据
 *
 * @component SearchArticle
 */
export const searchArticles = (callback?: Function) => {
  return (dispatch: any, getState: any) => {
    const limit = 10;
    const searchKey = getState().getIn(["search", "searchKey"]);
    let state: SearchArticleState = getState().getIn(["search", "article"]);
    const { page, articleList } = state;
    const pms = mainApi.search({
      keywords: searchKey,
      type: "article",
      page,
      limit,
    });
    pms.then((result: any) => {
      if (page === 1 || !articleList) {
        dispatch(changeSearchArticleRows(result));
      } else {
        dispatch(changeSearchArticleRows([...articleList, ...result]));
      }
      dispatch(changeSearchArticleHasMore(result.length === limit));
    });
    pms.catch((error: any) => {
      Toast.info(error);
    });
    pms.finally(() => {
      callback && callback();
    });
  };
};

// SearchPost
export const changeSearchPostRows = (data?: Array<DDHY.SNSPost>) => ({
  type: constants.SET_POST_LIST,
  data,
});
export const changeSearchPostPage = (data: number) => ({
  type: constants.SET_POST_PAGE,
  data,
});
export const changeSearchPostHasMore = (data: boolean) => ({
  type: constants.SET_POST_HASMORE,
  data,
});

/**
 * 清除缓存
 *
 * @component SearchPost
 */
export const cleanSearchPost = () => {
  return (d: any, s: any) => {
    d(changeSearchPostRows());
    d(changeSearchPostPage(1));
  };
};

/**
 * 搜索帖子数据
 *
 * @component PostResult
 */
export const searchPosts = (callback?: Function) => {
  return (dispatch: any, getState: any) => {
    const searchKey = getState().getIn(["search", "searchKey"]);
    const limit = 10;
    let state: SearchPostState = getState().getIn(["search", "post"]);
    const { page, postList } = state;

    const pms = mainApi.search({
      keywords: searchKey,
      type: "post",
      page,
      limit,
    });
    pms.then((result: any) => {
      if (page === 1 || !postList) {
        dispatch(changeSearchPostRows(result));
      } else {
        dispatch(changeSearchPostRows([...postList, ...result]));
      }
      dispatch(changeSearchPostHasMore(result.length === limit));
    });
    pms.catch((error: any) => {
      Toast.info(error);
    });
    pms.finally(() => {
      callback && callback();
    });
  };
};


// SearchHospital
export const changeSearchHospitalRecommendRows = (data?: Array<DDHY.hospitals>) => ({
  type: constants.SET_DOCTOR_RECOMMEND_LIST,
  data,
});
export const changeSearchHospitalRows = (data?: Array<DDHY.hospitals>) => ({
  type: constants.SET_DOCTOR_LIST,
  data,
});
export const changeSearchHospitalPage = (data: number) => ({
  type: constants.SET_DOCTOR_PAGE,
  data,
});
export const changeSearchHospitalHasMore = (data: boolean) => ({
  type: constants.SET_DOCTOR_HASMORE,
  data,
});

/**
 * 清除缓存
 * @component searchHospital
 */
export const cleanSearchHospital = () => {
  return (d: any, s: any) => {
    d(changeSearchHospitalRecommendRows());
    d(changeSearchHospitalRows());
    d(changeSearchHospitalPage(1));
  };
};

/**
 * 搜索医生数据
 * @component HospitalResult
 */
export const searchHospitals = (callback?: Function) => {
  return (dispatch: any, getState: any) => {
    const searchKey = getState().getIn(["search", "searchKey"]);
    const limit = 10;
    let state: SearchHospitalState = getState().getIn(["search", "hospital"]);
    const { page, hospitalList } = state;
    const pms = mainApi.search({
      keywords: searchKey,
      type: "hospital",
      page,
      limit,
    });
    pms.then((result: any) => {
      let { search_list, common_list } = result;
      if (page === 1 || !hospitalList) {
        dispatch(changeSearchHospitalRows(search_list));
      } else {
        dispatch(changeSearchHospitalRows([...hospitalList, ...search_list]));
      }
      const hasMore = search_list.length === limit;
      if (!hasMore) {
        dispatch(changeSearchHospitalHasMore(false));
        dispatch(changeSearchHospitalRecommendRows(common_list));
      }
    });
    pms.catch((error: any) => {
      Toast.info(error);
    });
    pms.finally(() => {
      callback && callback();
    });
  };
};


// SearchDoctor
export const changeSearchDoctorRecommendRows = (data?: Array<DDHY.Doctor>) => ({
  type: constants.SET_DOCTOR_RECOMMEND_LIST,
  data,
});
export const changeSearchDoctorRows = (data?: Array<DDHY.Doctor>) => ({
  type: constants.SET_DOCTOR_LIST,
  data,
});
export const changeSearchDoctorPage = (data: number) => ({
  type: constants.SET_DOCTOR_PAGE,
  data,
});
export const changeSearchDoctorHasMore = (data: boolean) => ({
  type: constants.SET_DOCTOR_HASMORE,
  data,
});

/**
 * 清除缓存
 *
 * @component SearchDoctor
 */
export const cleanSearchDoctor = () => {
  return (d: any, s: any) => {
    d(changeSearchDoctorRecommendRows());
    d(changeSearchDoctorRows());
    d(changeSearchDoctorPage(1));
  };
};

/**
 * 搜索医生数据
 *
 * @component DoctorResult
 */
export const searchDoctors = (callback?: Function) => {
  return (dispatch: any, getState: any) => {
    const searchKey = getState().getIn(["search", "searchKey"]);
    const limit = 10;
    let state: SearchDoctorState = getState().getIn(["search", "doctor"]);
    const { page, doctorList } = state;
    const pms = mainApi.search({
      keywords: searchKey,
      type: "doctor",
      page,
      limit,
    });
    // return ;
    pms.then((result: any) => {
      let { search_list, common_list } = result;
      if (page === 1 || !doctorList) {
        dispatch(changeSearchDoctorRows(search_list));
      } else {
        dispatch(changeSearchDoctorRows([...doctorList, ...search_list]));
      }
      const hasMore = search_list.length === limit;
      if (!hasMore) {
        dispatch(changeSearchDoctorHasMore(false));
        dispatch(changeSearchDoctorRecommendRows(common_list));
      }
    });
    pms.catch((error: any) => {
      Toast.info(error);
    });
    pms.finally(() => {
      callback && callback();
    });
  };
};

// SearchProject
export const changeSearchProjectRecommendRows = (
  data?: Array<DDHY.Project>
) => ({
  type: constants.SET_PROJECT_RECOMMEND_LIST,
  data,
});
export const changeSearchProjectRows = (data?: Array<DDHY.Project>) => ({
  type: constants.SET_PROJECT_LIST,
  data,
});
export const changeSearchProjectPage = (data: number) => ({
  type: constants.SET_PROJECT_PAGE,
  data,
});
export const changeSearchProjectHasMore = (data: boolean) => ({
  type: constants.SET_PROJECT_HASMORE,
  data,
});

/**
 * 清除缓存
 *
 * @component SearchProject
 */
export const cleanSearchProject = () => {
  return (d: any, s: any) => {
    d(changeSearchProjectRecommendRows());
    d(changeSearchProjectRows());
    d(changeSearchProjectPage(1));
  };
};

/**
 * 搜索服务项目数据
 *
 * @component SearchProject
 */
export const searchProjects = (callback?: Function) => {
  return (dispatch: any, getState: any) => {
    const limit = 10;
    const searchKey = getState().getIn(["search", "searchKey"]);
    let state: SearchProjectState = getState().getIn(["search", "project"]);
    const { page, projectList } = state;
    const pms = mainApi.search({
      keywords: searchKey,
      type: "reserve",
      page,
      limit,
    });
    pms.then((result: any) => {
      let { search_list, common_list } = result;
      if (page === 1 || !projectList) {
        dispatch(changeSearchProjectRows(search_list));
      } else {
        dispatch(changeSearchProjectRows([...projectList, ...search_list]));
      }
      const hasMore = search_list.length === limit;
      if (!hasMore) {
        dispatch(changeSearchProjectHasMore(false));
        dispatch(changeSearchProjectRecommendRows(common_list));
      }
    });
    pms.catch((error: any) => {
      Toast.info(error);
    });
    pms.finally(() => {
      callback && callback();
    });
  };
};

/**
 * 获取热门搜索词
 *
 * @component Search
 */
export const getTopSearch = () => {
  return (dispatch: any) => {
    mainApi
      .topSearch()
      .then((result: any) => {
        dispatch(changeTopSearch(result));
      })
      .catch((error) => {
        Toast.info(error);
      });
  };
};

/**
 * 存储搜索历史
 *
 * @componenty Search
 * @param {String} searchKey
 */
export const setHistorySearch = (searchKey: string) => {
  return (dispatch: any, getState: any) => {
    const keys = getLocalSearchHistory();
    // 判断当前的搜索内容是否已经在搜索历史中，如果在，则移到第一位
    let pos = keys.indexOf(searchKey as never);
    if (pos > -1) {
      keys.splice(pos as never, 1);
    }
    var length = keys.unshift(searchKey as never);
    // 如果搜索历史长度超过限定长度，则移除最后一个
    if (length >= 10) {
      keys.pop();
    }
    dispatch(changeHistorySearch(keys));
    const searchHistoryStr = JSON.stringify(keys);
    localStorage.setItem("SEARCH_HIOSTORY", searchHistoryStr);
  };
};

/**
 * 清除本地保存的搜索历史
 *
 * @component Search
 */
export const cleanHistorySearch = () => {
  return (dispatch: any) => {
    localStorage.removeItem("SEARCH_HIOSTORY");
    dispatch(changeHistorySearch([]));
  };
};
/**
 * 获取本地保存的搜索历史
 *
 * @component Search
 */
export const getLocalSearchHistory = () => {
  let searchHistoryStr: string | null = localStorage.getItem("SEARCH_HIOSTORY");
  let searchHistory: string[] = searchHistoryStr
    ? JSON.parse(searchHistoryStr)
    : [];
  return searchHistory;
};

/**
 * 搜索处理
 *
 * @component Search
 */
export const search = (keywords: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(setHistorySearch(keywords));
    const tabIndex = getState().getIn(["search", "tabIndex"]);
    switch (tabIndex) {
      case 0:
        dispatch(cleanSearchArticle());
        break;
      case 1:
        dispatch(cleanSearchPost());
        break;
      case 2:
        dispatch(cleanSearchDoctor());
        break;
      case 3:
        dispatch(cleanSearchProject());
        break;
      // case 2:
      //   dispatch(cleanSearchHospital());
      //   break;
      // case 3:
      //   dispatch(cleanSearchDoctor());
      //   break;
      // case 4:
      //   dispatch(cleanSearchProject());
      //  break;
      default:
        break;
    }
  };
};
