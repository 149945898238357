import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Flex } from "antd-mobile";
import { richTextFilter } from "utils/util";
import "./index.scss";
import { Avatar } from "components/basic";

interface ProjectProps {
  project: any;
  hideDoctor?: boolean;
}

/**
 * 帖子列表项
 */
const Project: React.FC<ProjectProps> = (props) => {
  const { project, hideDoctor } = props;
  if (!project || !project.doctor_info) return null;
  return (
    <Link className="Component-Project" to={`/project/${project.item_id}`}>
      <div className="Project-Header">{project.item_name}</div>
      <div className="Project-Body">
        {!hideDoctor && (
          <Flex className="Doctor">
            <Avatar
              src={project.doctor_info.doctor_avatar}
              style={{ width: 28, height: 28 }}
            />

            <div className="Doctor-Name">{project.doctor_info.real_name}</div>
            <div>{project.doctor_info.doctor_level}</div>
            <Flex.Item className="Doctor-Location">
              {project.item_location_text}
            </Flex.Item>
          </Flex>
        )}
        <div
          className="Project-Description Text-Line__2"
          style={{ wordBreak: "break-all" }}
        >
          {richTextFilter(project.item_description)}
        </div>
      </div>
      <Flex className="Project-Footer" align="center">
        {/* {project.is_branch === 0 ? (
          <div className="Hospital-Tag">执业医院</div>
        ) : (
          <div className="Branch-Tag">多点执业网点</div>
        )} */}
        <Flex.Item
          style={{ fontSize: "13px", height: "20px", lineHeight: "20px" }}
          className="Text-Line__1"
        >
          {project.hospital_name}
        </Flex.Item>
        <div className="price">
          预约费：
          <span style={{ color: "red" }}>¥{project.item_price}</span>
        </div>
      </Flex>
    </Link>
  );
};

export default memo(Project);
