import React from "react";
import { Flex } from "antd-mobile";
import "./index.scss";

interface ErrorProps {
  error?: string;
  tips?: string;
}

const Error: React.FC<ErrorProps> = ({ error, tips }) => {
  return (
    <div
      className={`Component-Error`}
      onClick={() => {
        window.location.reload();
      }}
    >
      <Flex direction="column">
        <Flex.Item>
          <svg
            className="ErrorImage"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M512 960c-247.039484 0-448-200.960516-448-448S264.960516 64 512 64 960 264.960516 960 512 759.039484 960 512 960zM512 128.287273c-211.584464 0-383.712727 172.128262-383.712727 383.712727 0 211.551781 172.128262 383.712727 383.712727 383.712727 211.551781 0 383.712727-172.159226 383.712727-383.712727C895.712727 300.415536 723.551781 128.287273 512 128.287273z"></path>
            <path d="M557.05545 513.376159l138.367639-136.864185c12.576374-12.416396 12.672705-32.671738 0.25631-45.248112s-32.704421-12.672705-45.248112-0.25631l-138.560301 137.024163-136.447897-136.864185c-12.512727-12.512727-32.735385-12.576374-45.248112-0.063647-12.512727 12.480043-12.54369 32.735385-0.063647 45.248112l136.255235 136.671523-137.376804 135.904314c-12.576374 12.447359-12.672705 32.671738-0.25631 45.248112 6.271845 6.335493 14.496116 9.504099 22.751351 9.504099 8.12794 0 16.25588-3.103239 22.496761-9.247789l137.567746-136.064292 138.687596 139.136568c6.240882 6.271845 14.432469 9.407768 22.65674 9.407768 8.191587 0 16.352211-3.135923 22.591372-9.34412 12.512727-12.480043 12.54369-32.704421 0.063647-45.248112L557.05545 513.376159z"></path>
          </svg>
        </Flex.Item>
        <div className="Prompt">
          <h3 className="Title">{error || "页面发生错误"}</h3>
          <p className="Tips">{tips || ""}</p>
        </div>
      </Flex>
    </div>
  );
};

export default Error;
