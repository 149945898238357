import { getResult } from "utils/request";

export interface QueryParams {
  page?: string | number;
  limit?: string | number;
  doctor_name?: string;
  tag_id?: number;
  city_name?: string;
  doctor_address_id?: string | number;
  doctor_level?: string | number;
  order?: string | number;
}

const doctorApi = {
  /**
   * 查询医生
   */
  getDoctorList: (params: QueryParams) => {
    return getResult({
      url: `/v2/doctor/list`,
      params,
    });
  },
  // 获取推荐医生列表
  getRecommendDoctorList: (params: QueryParams) => {
    return getResult({
      url: `/v2/doctor/recomment_list`,
      params,
    });
  },

  /**
   *  获取医生详情信息
   * @param {int} doctor_id 要查询的医生ID
   */
  getDetails: (access_id: string | number) => {
    return getResult({
      url: `/v2/doctor/${access_id}`,
    });
  },



  /**
   * 关注/取关医生处理
   */
  followHandle: (params: {
    active_id: string | number; //账号ID
    type: 'doctor';
    active_type: 'subscribe'
  }) => {
    return getResult({
      needLogin: true,
      needRealInfo: true,
      url: `/v2/member/collection`,
      params,
      method: "post",
    });
  },

  /**
   * 获取当前用户关注的医生列表
   *
   * @param {Object} params 查询条件
   */
  getUserFollowedDoctors: (params: { page?: number; limit?: number }) => {
    return getResult({
      needLogin: true,
      url: `/v2/member/collection/doctor_list`,
      params,
    });
  },
};

export default doctorApi;
