import { lazy } from "react";

const VipList = lazy(() => import("./index"));
const VipOrder = lazy(() => import("./order"));


const routes: DDHY.Route[] = [
  { path: "/vip/order", title: "订单详情", component: VipOrder },
  { path: "/vip", title: "丁丁会员", component: VipList },
];

export { routes };
