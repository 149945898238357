import { getResult } from "utils/request";

export interface QueryParams {
  tag_id?: string | number;
  city_name?: string;
  access_id?: string | number;
  page?: number;
  limit?: number;
}

/**
  * 获取有服务项目的分类
  *
  * @param order string 排序字段 默认"tag_sort"
  * @param order_type number 排序类型 0 升序 1 降序
  */
const getCateWithProject = (order?: "tag_sort" | "tag_id", order_type?: 0 | 1) => {
  return getResult({
    url: "/v2/cate/item",
    params: {
      order,
      order_type,
    },
  });
};

/**
 * 查询项目列表
 *
 * @param params
 */
const query = (params: QueryParams) => {
  return getResult({
    url: "/v2/reservation/item_list",
    params,
  });
};
/**
 * 查询推荐手术项目
 * @param params
 * @returns
 */
const queryRecommend = (params: QueryParams) => {
  return getResult({
    url:'/v2/reservation/recomment_item_list',
    params,
  });
};

/**
 * 查询医生的服务项目列表
 *
 * @param  doctor_id
 */
const queryByDoctor = (params: QueryParams) => {
  return getResult({
    url: "/v2/reservation/item_list",
    params,
  });
};

/**
 * 查询医生的服务项目详细信息
 * @param {int} item_id
 */
const getDetails = (item_id: string | number) => {
  return getResult({
    // url: "/api/ask/reservation/item/" + item_id,
    url: "/v2/reservation/" + item_id,
  });
};

const projectApi = {
  getCateWithProject,
  query,
  queryRecommend,
  queryByDoctor,
  getDetails,
};
export default projectApi;
