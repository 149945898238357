import * as actionTypes from "./constants";

export interface Category {
  cate_id: number | string;
  cate_name: string;
  cate_parent_id: number | string;
  children?: Array<Category>;
}

export interface PopularScienceState {
  tabIndex: number;
  categoryList?: Array<Category>; // 一级分类列表
  currentCategory?: Category; // 当前一级分类
  currentSubCategory?: Category; // 当前二级分类
  articles?: Array<DDHY.Article>;
  articlesPage: number;
  articlesHasMore?: boolean;
}

const defaultState: PopularScienceState = {
  tabIndex: 0,
  articlesPage: 1,
  articlesHasMore: true,
};

export default (state = defaultState, action: any): PopularScienceState => {
  switch (action.type) {
    case actionTypes.SET_TAB_INDEX:
      return { ...state, tabIndex: action.data };
    case actionTypes.SET_CATEGORY_LIST:
      return { ...state, categoryList: action.data };
    case actionTypes.SET_CURRENT_CATEGORY:
      return { ...state, currentCategory: action.data };
    case actionTypes.SET_CURRENT_SUB_CATEGORY:
      return { ...state, currentSubCategory: action.data };
    case actionTypes.SET_ARTICLES:
      return { ...state, articles: action.data };
    case actionTypes.SET_ARTICLES_PAGE:
      return { ...state, articlesPage: action.data };
    case actionTypes.SET_ARTICLES_HASMORE:
      return { ...state, articlesHasMore: action.data };

    default:
      return state;
  }
};
