import { useEffect } from "react";
import { useHistory } from "react-router-dom";

// 封装一下push历史记录的方法
function pushHistory() {
  window.history.pushState("", "", window.location.href);
}
/**
 * 监听返回上一页
 * @param callback 回调函数
 *
 * 使用：
 * ```js
 *  useBack(() => {
 *  });
 * ```
 */
function useBack(callback: Function) {
  const history = useHistory();

  // 在需要监听的页面执行该方法
  useEffect(() => {
    pushHistory();
    window.addEventListener("popstate", popListener);
    return () => {
      window.removeEventListener("popstate", popListener);
    };

    function popListener() {
      if (history.action === "POP") {
        window.history.back();
        setTimeout(() => {
          callback();
        }, 300);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
export default useBack;
