import { getResult } from "utils/request";
const baseUrl = "/api/classroom";

export interface QueryParams {
  category_id?: string | number; // 分类编号
  page?: number;
  limit?: number;
}

const specialApi = {
  /**
   * 获取专栏数据
   */
  query(params?: QueryParams) {
    return getResult({
      url: `${baseUrl}/special/list`,
      params,
    });
  },

  /**
   * 获取专栏详情
   * @param {int} special_id 专栏ID
   */
  getDetails(special_id: number | string) {
    return getResult({
      url: `${baseUrl}/special/detail/${special_id}`,
    });
  },

  /**
   * 用户对专栏的操作
   * * 添加/删除收藏、添加专栏点击、添加专栏分享
   *
   * @param special_id 要操作的专栏ID
   * @param operation_type 操作动作 1-点击 2-分享 3收藏/取消收藏
   *
   */
  userOperation(special_id: number, operation_type: number) {
    return getResult({
      url: `${baseUrl}/collection/handle`,
      needLogin: true,
      needRealInfo: true,
      params: {
        obj_id: special_id,
        active_type: operation_type,
        obj_type: 2,
      },
    });
  },

  /**
   * 获取已收藏专栏列表
   *
   * @param 请求参数
   */
  getCollectionSpecials(params: { page?: number; limit?: number }) {
    return getResult({
      url: `/v2/member/collect_list`,
      needLogin: true,
      params: {
        ...params,
        type: "special",
      },
    });
  },

  /**
   * 获取已购买的专栏列表
   *
   * @param 请求参数
   */
  getPurchasedSpecials(params: { page?: number; limit?: number }) {
    return getResult({
      needLogin: true,
      url: `${baseUrl}/purchased`,
      params: {
        ...params,
        obj_type: 2,
      },
    });
  },
};

export default specialApi;
