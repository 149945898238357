import { lazy } from "react";

const DistributorShare = lazy(() => import("./Share"));

const routes: DDHY.Route[] = [
    {
        path: "/distributor/share/:invite_code",
        title: "丁丁好医",  // 分销列表
        component: DistributorShare,
        private: true
      },
];

export { routes };