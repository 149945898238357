import * as actionTypes from "./constants";

// 医生详情页数据结构
export interface DoctorState {
  doctorDetails?: DDHY.DoctorDetails;
  consults?: Array<DDHY.Consult>;
  consultsPage: number;
  consultsHasMore: boolean;
  articles?: Array<DDHY.Article>;
  projects?: Array<DDHY.Project>;
}

const defaultState: DoctorState = {
  consultsPage: 1,
  consultsHasMore: true,
};

export default (state = defaultState, action: any): DoctorState => {
  switch (action.type) {
    case actionTypes.SET_DOCTOR_DETAILS:
      return { ...state, doctorDetails: action.data };
    case actionTypes.SET_CONSULTS:
      return { ...state, consults: action.data };
    case actionTypes.SET_CONSULTS_PAGE:
      return { ...state, consultsPage: action.data };
    case actionTypes.SET_CONSULTS_HASMORE:
      return { ...state, consultsHasMore: action.data };

    case actionTypes.SET_ARTICLES:
      return { ...state, articles: action.data };

    case actionTypes.SET_PROJECTS:
      return { ...state, projects: action.data };
    default:
      return state;
  }
};
