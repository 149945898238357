import React from "react";
import "./index.scss";

/**
 * 语音播放动画
 *
 */
const VoicePlay: React.FC<{
  play?: boolean; // 是否播放
}> = ({ play }) => {
  return (
    <div className="Component-VoicePlay">
      <svg
        viewBox="0 0 1024 1024"
        id="VoicePlaySvg"
        className={play ? "Playing" : ""}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
      >
        <path d="M230.1952 512m-81.92 0a81.92 81.92 0 1 0 163.84 0 81.92 81.92 0 1 0-163.84 0Z"></path>
        <path
          d="M422.72 819.2c-9.6 0-19.2-3.84-25.92-11.52-13.44-14.4-13.44-37.44 0.96-50.88 69.12-65.28 107.52-152.64 107.52-244.8s-38.4-179.52-107.52-244.8c-14.4-13.44-15.36-36.48-0.96-50.88 13.44-14.4 36.48-15.36 50.88-0.96 83.52 79.68 129.6 185.28 129.6 297.6s-46.08 217.92-129.6 297.6c-6.72 5.76-15.36 8.64-24.96 8.64z"
          id="VoicePlaySvg-Second"
        ></path>
        <path
          d="M606.08 982.4c-9.6 0-19.2-3.84-25.92-11.52-13.44-14.4-13.44-37.44 0.96-50.88C696.32 810.56 759.68 665.6 759.68 512S696.32 213.44 581.12 104c-14.4-13.44-15.36-36.48-0.96-50.88 13.44-14.4 36.48-15.36 50.88-0.96 129.6 122.88 200.64 286.08 200.64 460.8 0 173.76-71.04 337.92-200.64 460.8-6.72 5.76-16.32 8.64-24.96 8.64z"
          id="VoicePlaySvg-Last"
        ></path>
      </svg>
    </div>
  );
};
export default VoicePlay;
