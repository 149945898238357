interface WindowMetrics {
  windowHeight: number;
  windowWidth: number;
}

interface ContainerMetrics extends WindowMetrics {
  contentHeight: number; // 内容高度
  contentWidth: number; // 内容宽度
  topOffset: number; // 顶部偏移量
  leftOffset?: number; // 左边偏移量
}

/**
 * 获取容器的滚动数据
 *
 * @return
 */
export function getContainerMetrics(container?: HTMLElement): ContainerMetrics {
  // 区分滚动容器，传入的container | window
  if (container) {
    return {
      ...getWindowMetrics(),
      contentHeight: container.offsetHeight,
      contentWidth: container.offsetWidth,
      topOffset: container.scrollTop,
      leftOffset: container.scrollLeft,
    };
  }
  // In chrome61 `document.body.scrollTop` is invalid,
  // and add new `document.scrollingElement`(chrome61, iOS support).
  // In old-android-browser and iOS `document.documentElement.scrollTop` is invalid.
  const scrollNode = document.scrollingElement || document.body;
  return {
    ...getWindowMetrics(),
    contentHeight: scrollNode ? scrollNode.scrollHeight : 0,
    contentWidth: scrollNode ? scrollNode.scrollWidth : 0,
    topOffset: scrollNode.scrollTop,
  };
}

/**
 * 获取窗口高度
 */
function getWindowMetrics(): WindowMetrics {
  return {
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
  };
}
