import * as consts from "./constants";
import mainApi from "api/main";
import messageApi from "api/message";

export const changeWxSDK = (data: any) => ({
  type: consts.SET_WX_JSSDK,
  data: data,
});

export const changeBanners = (data: any) => ({
  type: consts.SET_BANNERS,
  data: data,
});
export const changeBannersLoading = (data: any) => ({
  type: consts.SET_BANNERS_LOADING,
  data: data,
});

export const changeUnreadCount = (data: any) => ({
  type: consts.SET_UNREAD_COUNT,
  data: data,
});

export const changeAdInfo = (data: any) => ({
  type: consts.SET_AD_VIEW_INFO,
  data: data,
});

export const changeBottomBar = (data: any) => ({
  type: consts.SET_BOTTOM_BAR,
  data: data,
});


/**
 * 获取广告位及广告信息
 * @param apCode
 * @param callback
 * @returns
 */
export const getAdInfo = (apCode : string, callback?: Function) => {
  return async (dispatch: any) => {
    try {
      const result = await mainApi.getAdvInfoByCode(apCode);
      dispatch(changeAdInfo(result));
      callback && callback();
    } catch (error) {
      callback && callback(error);
    } finally {
      callback && callback();
    }
  };
};

/**
 * 初始化未读消息数据
 */
export const initUnreadMessageCount = () => {
  return (dispatch: any, getState: any) => {
    const accountInfo = getState().getIn(["account", "accountInfo"]);
    if (!accountInfo) return;
    const pms = messageApi.countAllUnread();
    pms.then((result: any) => {
      dispatch(changeUnreadCount({ ...result }));
    });
  };
};
