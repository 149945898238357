import React from "react";
import Main from "./components/Main";
import Footer from "./components/Footer";
import "./index.scss";

const NavLayout: React.FC = () => {
  return (
    <>
      <Main withFooter />
      <Footer />
    </>
  );
};

export default NavLayout;
