import React, { memo, useState, useCallback } from "react";
import "./index.scss";
import Icon from "../Icon";

interface RateProps {
  max?: number;
  rate?: number;
  size?: number;
  readonly?: boolean;
  color?: string;
  onChange?(v: number): void;
}

const Rate: React.FC<RateProps> = props => {
  const { max = 5, readonly = false, size = 16, rate = 1, color = "red", onChange } = props;
  const [value, setValue] = useState(rate);
  const [rates] = useState(() => {
    return new Array(Number(max)).fill("");
  });

  const handleSelectRate = useCallback(
    (rate: number) => {
      if (readonly) {
        return;
      }
      setValue(rate);
      onChange && onChange(rate);
    },
    [readonly, onChange]
  );

  return (
    <div className="Component-Rate">
      {rates.map((item: any, index: number) => (
        <div
          key={`rate_${index}`}
          className="Rate-Item"
          onClick={() => handleSelectRate(index + 1)}
        >
          {value > index ? (
            <Icon size={size} color={color}>
              biaoxingfill
            </Icon>
          ) : (
            <Icon size={size}>biaoxing</Icon>
          )}
        </div>
      ))}
    </div>
  );
};

export default memo(Rate);
