import { getResult } from "utils/request";

const aggregationApi = {
    /**
     * 获取特色专题内容详细
     * @returns
     */
    queryComplexData(id: string){
      return getResult({
        url: `/v2/subject/detail`,
        params:{
          id
        }
      });
    },

    /**
     * 获取特色专题列表
     */
    queryAggregationList(){
      return getResult({
        url: `/v2/subject/list`,
      })
    },
}

export default aggregationApi;
