import { lazy } from "react";

const Article = lazy(() => import("./Article"));
const Author = lazy(() => import("./Author"));
const Classroom = lazy(() => import("./PopularScience"));
const Special = lazy(() => import("./Special"));
const Specials = lazy(() => import("./Special/Specials"));

const routes: DDHY.Route[] = [
  {
    path: "/classroom",
    title: "科普",
    component: Classroom,
  },
  {
    path: "/special/:id",
    title: "专栏详情",
    component: Special,
  },
  {
    path: "/specials",
    title: "专栏",
    component: Specials,
  },
  {
    path: "/article/:id",
    title: "文章详情",
    component: Article,
  },
  {
    path: "/author/:author_id",
    title: "作者详情",
    component: Author,
  },
];

export { routes };
