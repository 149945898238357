import React from "react";
import { useHistory } from "react-router-dom";
import { Flex } from "antd-mobile";
import { Icon } from "components/basic";
import { getImageUrl } from "utils/util";
import "./index.scss";
import LazyLoad from "react-lazyload";

const Special: React.FC<{
  special: any;
}> = ({ special }) => {
  const history = useHistory();
  return (
    <Flex
      className="Component-Special"
      onClick={() => {
        history.push(`/special/${special.special_id}`);
      }}
    >
      <LazyLoad height={68}>
        <img
          className="SpecialCover"
          src={getImageUrl(special.special_image)}
          alt=""
        />
      </LazyLoad>

      <Flex.Item className="SpecialInfo">
        <Flex
          direction="column"
          justify="start"
          align="start"
          style={{ width: "100%" }}
        >
          <div className="title">{special.special_title || "标题为空"}</div>

          <Flex className="SpecialFooter" style={{ width: "100%" }}>
            <Flex.Item>
              <Flex className="click">
                <Icon size="16">eye</Icon>
                <span>{special.special_click}次浏览</span>
              </Flex>
            </Flex.Item>
            <div className="price">
              {special.special_is_free !== 1 && `¥${special.special_price}`}
              {/* {special.special_is_free === 1
                ? "免费"
                : `¥${special.special_price}`} */}
            </div>
          </Flex>
        </Flex>
      </Flex.Item>
    </Flex>
  );
};

export default Special;
