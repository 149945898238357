interface formatNumberOption {
  min?: number; // 触发格式化的最小值
  fixed?: number; // 保留小数位
}

/**
 * 大数字格式化
 *
 * @param num 要格式化的数字/数字字符串
 * @param options 可选，配置项
 */
const formatNumber = (num?: number, options?: formatNumberOption) => {
  if (!num || isNaN(num)) return 0;

  let min = 100000,
    fixed = 1;
  if (options) {
    if (options.min) min = options.min;
    if (options.fixed) fixed = options.fixed;
  }
  if (num < min) return num;
  let result = (num / 10000).toFixed(fixed);
  return result + "w";
};

export { formatNumber };
