import * as constants from "./constants";

export const changeUseState = (data: boolean) => ({
  type: constants.SET_USE_STATE,
  data: data,
});
export const changeHospitals = (data?: Array<DDHY.hospitals>) => ({
  type: constants.SET_HOSPIATLS,
  data: data,
});
export const changePage = (data: number) => ({
  type: constants.SET_PAGE,
  data: data,
});
export const changeHasMore = (data: boolean) => ({
  type: constants.SET_HASMORE,
  data: data,
});
export const changrecommend = (data: boolean) => ({
  type: constants.SET_RECOMMEND,
  data: data,
});
export const changeRegions = (data?: any[]) => ({
  type: constants.SET_REGIONS,
  data: data,
});
export const changeSkills = (data?: any[]) => ({
  type: constants.SET_SKILLS,
  data: data,
});
export const changeFilter = (data?: any) => ({
  type: constants.SET_FILTER,
  data: data,
});

// export const cleanHospital = () => {
//   return (dispatch: any) => {
//     // dispatch(changeUseState());
//     // dispatch(changeHospitals());
//     // dispatch(changePage(1));
//     // dispatch(changeHasMore(true));
//     // dispatch(changeRegions());
//     // dispatch(changeSkills());
//     // dispatch(changeFilter());
//   };
// }