import * as actions from "./store/actionCreators";
import { Toast } from "antd-mobile";
import doctorApi, { QueryParams } from "api/doctor";
import mainApi from "api/main";
import { FindDoctorState } from "./store/reducer";

/**
 * 初始化
 *
 * @component FindDoctor
 */
export const initFindDoctor = (callback: Function) => {
  return (dispatch: any, getState: any) => {
    dispatch(actions.changeUseState(true));
    const pms = Promise.all([initTags(), initRegion()]);

    pms.then(() => {
      callback();
    });
    pms.catch((error: string) => {
      callback(error);
    });

    async function initTags() {
      return new Promise((resolve: any, reject: any) => {
        mainApi
          .getCateTree()
          .then((result: any) => {
            const { filter } = getState().getIn(["doctor", "findDoctor"]);
            const newFilter = {
              tag: [1000, null],
            };
            dispatch(actions.changeFilter({ ...filter, ...newFilter }));
            dispatch(actions.changeTags(result));
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    }

    async function initRegion() {
      // 获取定位信息
      return new Promise((resolve: any, reject: any) => {
        mainApi
          .getRegionsTreeWithDoctor()
          .then((result: any) => {
            dispatch(actions.changeRegions(result));
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  };
};

/**
 * 获取医生数据
 *
 * @component FindDoctor
 */
export const loadingDoctorListData = (callback?: Function) => {
  return async (dispatch: any, getState: any) => {
    const limit = 10;
    try {
      loadingData();
    } catch (error) {
      Toast.info(error);
    } finally {
      callback && callback();
    }

    function loadingData(){
      const state: FindDoctorState = getState().getIn(["doctor", "findDoctor"]);
      const {hasMore, doctorsHasMore, recommendHasMore} = state;
      if (hasMore) {
        let params: QueryParams = {};
        params.limit = limit;
        if (doctorsHasMore) {
          getDoctorList(params);
        }else if(recommendHasMore){
          getDoctorList(params, true);
        }else{
          dispatch(actions.changeHasMore(false));
        }
      }
    }

    async function getDoctorList(params: QueryParams, isRecommend = false) {
      const state: FindDoctorState = getState().getIn(["doctor", "findDoctor"]);
      const { doctorList, doctorsPage, recommendPage, hasRecommend, filter } = state;

      // 过滤筛选
      if (filter) {
        const { region, tag } = filter;
        // 将标签筛选条件转为查询条件
        if (tag) {
          if (tag[1] != null) {
            params.tag_id = tag[1];
          } else if (tag[0] !== parseInt("1000")) {
            params.tag_id = tag[0];
          }
        }

        // 将地区筛选条件转为查询条件
        if (region) {
          let doctor_address_id = undefined;
          if (region[1] != null) {
            doctor_address_id = region[1];
          } else if (region[0] !== parseInt("1000") && region[0] !== 999) {
            doctor_address_id = region[0];
          }

          if (isNaN(doctor_address_id)) {
            params.doctor_address_id = doctor_address_id;
          } else {
            params.doctor_address_id = doctor_address_id;
            params.city_name = undefined;
          }
        }
      }

      let doctorsData = [];
      let doctorLength = 0;
      if(isRecommend){
        if(hasRecommend && recommendPage === 1){
          return;
        }
        params.page = recommendPage;
        doctorsData = await doctorApi.getRecommendDoctorList(params);
        doctorLength = doctorsData.length;
        if(recommendPage === 1 && doctorLength > 0){
          let showLable = {"show_lable":true};
          doctorsData.unshift(showLable)
          dispatch(actions.changeHasRecommend(true));
        }
        dispatch(actions.changeRecommendHasMore(doctorLength === limit));

      } else {
        params.page = doctorsPage;
        doctorsData = await doctorApi.getDoctorList(params);
        doctorLength = doctorsData.length;
        dispatch(actions.changeDocotrHasMore(doctorLength === limit));

      }

      if (!doctorList) {
        dispatch(actions.changeDoctorList(doctorsData));
      }else{
        dispatch(actions.changeDoctorList([...doctorList, ...doctorsData]));
      }

      // 数据量少于5个自动加载推荐数据
      if(!isRecommend && doctorLength < limit){
        loadingData();
      }

    }

  };
};

/**
 * 筛选医生
 *
 * @component
 */
export const handleFilterChange = (filter: any, callback: Function) => {
  return (dispatch: any, getState: any) => {
    const oldFilter = getState().getIn(["doctor", "findDoctor", "filter"]);
    dispatch(actions.changeFilter({ ...oldFilter, ...filter }));
    dispatch(actions.changeHasMore(true));
    dispatch(actions.changeDoctorList());
    dispatch(actions.changeDocotrPage(1));
    dispatch(actions.changeDocotrHasMore(true));
    dispatch(actions.changeRecommendPage(1));
    dispatch(actions.changeRecommendHasMore(true));
    dispatch(actions.changeHasRecommend(false));
    setTimeout(() => {
      callback();
    }, 100);
  };
};
