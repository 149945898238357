import { lazy } from "react";

const TrainingList = lazy(() => import("./Training"));
const TrainingDetails = lazy(() => import("./Details"));
const TrainingOrder = lazy(() => import("./Order"));

const routes: DDHY.Route[] = [
  {
    path: "/training/order",
    title: "订单详情",
    component: TrainingOrder,
    private: true
  },
  {
    path: "/training/:id",
    title: "训练课程",
    component: TrainingDetails,
    private: true
  },
  {
    path: "/trains",
    title: "训练课程",
    component: TrainingList,
    private: true
  },
];

export { routes };
