import * as consts from "./constants";
import { Toast } from "antd-mobile";
import postApi from "api/post";
import memberApi from "api/member";
import { MemberState } from "./reducer";
import { handleResponseError } from "utils/request";

export const changeMemberDetails = (data?: DDHY.SNSUserInfo) => ({
  type: consts.SET_MEMBER_DETAILS,
  data: data,
});
export const changePosts = (data?: Array<DDHY.SNSPost>) => ({
  type: consts.SET_POSTS,
  data: data,
});
export const changePage = (data: any) => ({
  type: consts.SET_PAGE,
  data: data,
});
export const changeHasMore = (data: any) => ({
  type: consts.SET_HASMORE,
  data: data,
});

export const cleanMember = () => {
  return (dispatch: any) => {
    dispatch(changeMemberDetails());
    dispatch(changePosts());
    dispatch(changePage(1));
    dispatch(changeHasMore(true));
  };
};

/**
 * 初始化Member页面数据
 *
 * @param id  { string | number }  用户ID
 */
export const initMember = (id: string | number, cb?: Function) => {
  return async (dispatch: any) => {
    const pms = memberApi.getDetails(id);
    pms.then((result: any) => {
      dispatch(changeMemberDetails(result));
      cb && cb();
    });

    pms.catch((error: any) => {
      cb && cb(error);
    });
  };
};

/**
 * 加载帖子
 */
export const loadPosts = (callback?: Function) => {
  return (dispatch: any, getState: any) => {
    const state: MemberState = getState().getIn(["community", "member"]);
    const { page = 1, posts, memberDetails } = state;
    const limit = 10;
    if (!memberDetails) {
      throw Error("loadPosts Error: member is undefined");
    }
    postApi
      .queryPostList({
        recommend_position: 4,
        type: "personal",
        user_id: memberDetails.user_id,
        page,
        limit,
      })
      .then((result) => {
        let data = result.data;
        if (page === 1 || !posts) {
          dispatch(changePosts(data));
        } else {
          dispatch(changePosts([...posts, ...data]));
        }
        dispatch(changeHasMore(data.length === limit));
      })
      .catch((error) => Toast.info(error))
      .finally(() => {
        callback && callback();
      });
  };
};

/**
 * 重新加载帖子列表
 *
 */
export const reloadPosts = () => {
  return (dispatch: any) => {
    dispatch(changePage(1));
    dispatch(changeHasMore(true));
    setTimeout(() => {
      dispatch(loadPosts());
    });
  };
};

export const handleSubscriptionMember = () => {
  return (dispatch: any, getState: any) => {
    const member = getState().getIn(["community", "member", "memberDetails"]);
    if (!member) {
      throw Error("handleSubscriptionMember Error: member is undefined");
    }
    memberApi
      .operation(member.user_id, "subscribe")
      .then(() => {
        dispatch(initMember(member.user_id));
      })
      .catch((error) => {
        handleResponseError(error);
      });
  };
};
