import * as actionTypes from "./constants";

export interface ReservationState {
  reservationDetails?: any;
  refundProgress?: any;
}

export default (state = {}, action: any): ReservationState => {
  switch (action.type) {
    case actionTypes.SET_DETAILS:
      return { ...state, reservationDetails: action.data };
    case actionTypes.SET_REFUND_PROGRESS:
      return { ...state, refundProgress: action.data };
    default:
      return state;
  }
};
