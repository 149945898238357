import articleApi, { QueParams } from "api/article";
import * as constatnts from "./constants";
import { Category, PopularScienceState } from "./reducer";
import mainApi from "api/main";

// PopularScience
export const changeTabIndex = (data: number) => ({
  type: constatnts.SET_TAB_INDEX,
  data,
});
export const changeCategotyList = (data?: Array<Category>) => ({
  type: constatnts.SET_CATEGORY_LIST,
  data,
});
export const changeCurrentCategory = (data?: Category) => ({
  type: constatnts.SET_CURRENT_CATEGORY,
  data,
});
export const changeCurrentSubCategory = (data?: Category) => ({
  type: constatnts.SET_CURRENT_SUB_CATEGORY,
  data,
});
export const changeArticles = (data?: Array<DDHY.Article>) => ({
  type: constatnts.SET_ARTICLES,
  data,
});
export const changeArticlesPage = (data: number) => ({
  type: constatnts.SET_ARTICLES_PAGE,
  data,
});
export const changeArticlesHasMore = (data: boolean) => ({
  type: constatnts.SET_ARTICLES_HASMORE,
  data,
});

/**
 * 初始化
 *
 * @param cb 回调函数
 */
export const initPopularScience = (callback?: Function) => {
  return async (dispatch: any) => {
    try {
      const data = await mainApi.getPlatCateList();

      const defaultTabs: Category[] = [
        {
          cate_id: "doctor",
          cate_name: "医生科普",
          cate_parent_id: 0,
        },
        {
          cate_id: "all",
          cate_name: "全部",
          cate_parent_id: 0,
        },
      ];
      dispatch(changeCategotyList([...defaultTabs, ...data]));
      dispatch(changeCurrentCategory(defaultTabs[0]));
      callback && callback();
    } catch (error) {
      callback && callback(error);
    }
  };
};

/**
 * 一级分类切换
 *
 * @param category tab下标
 * @param index tab下标
 * @param cb 回调函数
 *
 * @component PopularScience
 */
export const handleChangeCategory = (index: number, callback?: Function) => {
  return async (dispatch: any, getState: any) => {
    try {
      const state: PopularScienceState = getState().getIn([
        "classroom",
        "popularScience",
      ]);
      const { categoryList } = state;
      if (!categoryList) return;
      const currentCategory = categoryList[index];
      dispatch(changeArticles());
      dispatch(changeArticlesPage(1));
      dispatch(changeArticlesHasMore(true));
      dispatch(changeTabIndex(index));
      dispatch(changeCurrentCategory(currentCategory));
      dispatch(
        loadSubCategory(currentCategory, (error?: string) => {
          callback && callback(error);
        })
      );
    } catch (error) {
      callback && callback(error);
    }
  };
};

/**
 * 加载二级分类
 *
 * @param category 一级分类
 * @param cb 回调函数
 *
 * @component PopularScience
 */
export const loadSubCategory = (category: Category, callback: Function) => {
  return async (dispatch: any, getState: Function) => {
    try {
      if (typeof category.cate_id !== "number") {
        dispatch(changeCurrentSubCategory(undefined));
        callback();
      } else {
        if (category.children) {
          dispatch(changeCurrentSubCategory(category.children[0]));
          callback();
        } else {
          const result = await articleApi.getCategoryChildren(category.cate_id);

          const defaultSubCategory: Category = {
            cate_id: "all",
            cate_name: "全部",
            cate_parent_id: category.cate_id,
          };
          const subCategoryList = [defaultSubCategory, ...result];
          let newCategory: Category = {
            children: subCategoryList,
            ...category,
          };
          const state: PopularScienceState = getState().getIn([
            "classroom",
            "popularScience",
          ]);
          if (state.categoryList) {
            let newCategoryList = state.categoryList;
            for (let i = 0; i < state.categoryList.length; i++) {
              if (category.cate_id === state.categoryList[i].cate_id) {
                newCategoryList[i] = newCategory;
                break;
              }
            }
            dispatch(changeCategotyList(newCategoryList));
          }

          dispatch(changeCurrentCategory(newCategory));
          dispatch(changeCurrentSubCategory(defaultSubCategory));

          callback();
        }
      }
    } catch (error) {
      callback(error);
    }
  };
};

/**
 * 切换分类
 *
 * @component PopularScience
 * @param category 当前选中分类
 * @param cb 回调函数
 */
export const handleChangeSubCategory = (category: any, callback?: Function) => {
  return async (dispatch: any) => {
    dispatch(changeCurrentSubCategory(category));
    dispatch(changeArticlesPage(1));
    dispatch(changeArticlesHasMore(true));
    dispatch(changeArticles());

    callback && callback();
  };
};

/**
 * 获取文章数据
 *
 * @component
 */
export const loadArticles = (callback?: Function) => {
  return async (dispatch: any, getState: any) => {
    const limit = 10;

    try {
      const state: PopularScienceState = getState().getIn([
        "classroom",
        "popularScience",
      ]);
      const {
        currentCategory,
        currentSubCategory,
        articlesPage,
        articles,
      } = state;

      // 拼装请求参数
      const query: QueParams = {
        page: articlesPage,
        limit,
      };
      if (
        currentSubCategory &&
        typeof currentSubCategory.cate_id === "number"
      ) {
        query.cate_id = currentSubCategory.cate_id;
      } else if (currentCategory) {
        if (typeof currentCategory.cate_id === "number") {
          query.cate_id = currentCategory.cate_id;
        } else if (currentCategory.cate_id === "doctor") {
          query.from = "doctor";
        }
      }
      const result = await articleApi.queryArticleList(query);

      let { current_page, data, last_page} = result;
      if (Array.isArray(data)) {
        if(current_page === 1){
          dispatch(changeArticles(data));
        }else{
          dispatch(changeArticles([...articles, ...data]));
        }
        dispatch(changeArticlesHasMore(current_page < last_page));
        callback && callback();
      } else {
        callback && callback("数据错误");
      }
    } catch (error) {
      callback && callback(error);
    }
  };
};

/**
 * 清除State
 *
 * @component PopularScience
 */
export const cleanPopularScience = () => {
  return function (d: any) {
    d(changeTabIndex(0));
    d(changeCategotyList());
    d(changeCurrentCategory());
    d(changeCurrentSubCategory());
    d(changeArticles());
    d(changeArticlesPage(1));
    d(changeArticlesHasMore(true));
  };
};
