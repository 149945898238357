import * as actionTypes from "./constants";

export interface MessageState {
  use?: boolean;
  consults?: any[];
  reservations?: any[];
  interval?: number; // 消息定时器
}

export default (state = {}, action: any): MessageState => {
  switch (action.type) {
    case actionTypes.SET_USE_STATE:
      return { ...state, use: action.data };
    case actionTypes.SET_CONSULTS:
      return { ...state, consults: action.data };
    case actionTypes.SET_RESERVATIONS:
      return { ...state, reservations: action.data };
    case actionTypes.SET_INTERVAL:
      return { ...state, interval: action.data };

    default:
      return state;
  }
};
