// FindDoctor
export const SET_USE_STATE = "doctors/SET_USE_STATE";
export const SET_TAGS = "doctors/SET_TAGS";
export const SET_REGIONS = "doctors/SET_REGIONS";
export const SET_FILTER = "doctors/SET_FILTER";

export const SET_DOCTOR_LIST = "doctors/SET_DOCTOR_LIST";
export const SET_HASMORE = "doctors/SET_HASMORE";
export const SET_DOCTORPAGE = "doctors/SET_DOCTORPAGE";
export const SET_DOCTORHASMORE = "doctors/SET_DOCTORHASMORE";
export const SET_RECOMMENDPAGE = "doctors/SET_RECOMMENDPAGE";
export const SET_RECOMMENDHASMORE = "doctors/SET_RECOMMENDHASMORE";
export const SET_HASRECOMMEND = "doctors/SET_HASRECOMMEND";


