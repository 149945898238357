import React from "react";
import { useHistory } from "react-router-dom";
import { NavBar, Popover } from "antd-mobile";
import { Icon } from "../../basic";
import "./index.scss";

const Header: React.FC<{
  menus?: { text: string; onClick(): void }[];
  children?: JSX.Element;
}> = props => {
  const { menus, children } = props;
  const history = useHistory();

  return (
    <NavBar
      icon={<Icon size="20">xiangzuo1</Icon>}
      className="Component-ChatHeader"
      rightContent={
        menus && menus.length > 0 ? (
          <Popover
            mask
            placement="left"
            overlay={menus.map((menu: any, index: number) => (
              <Popover.Item key={index}>{menu.text}</Popover.Item>
            ))}
            onSelect={(item, index) => {
              if (index !== undefined) {
                const menu = menus[index];
                menu.onClick && menu.onClick();
              }
            }}
          >
            <div className="Right-Icon">
              <Icon size="20">gengduo</Icon>
            </div>
          </Popover>
        ) : null
      }
      onLeftClick={() => {
        history.goBack();
      }}
    >
      {children}
    </NavBar>
  );
};

export default Header;
