import { lazy } from "react";
import MyMedicine from "./MyMedicine";

const Profile = lazy(() => import("./Home"));
const MyRead = lazy(() => import("./MyRead"));
const MyCommunity = lazy(() => import("./MyCommunity"));
const MyConsult = lazy(() => import("./MyConsult"));
const MyReservation = lazy(() => import("./MyReservation"));
const MyDoctor = lazy(() => import("./MyDoctor"));
const MyTest = lazy(() => import("./MyTest"));
const MyDiary = lazy(() => import("./MyDiary"));
const MyOrder = lazy(() => import("./MyOrder"));
const PersonalInfo = lazy(() => import("./PersonalInfo"));

const routes: DDHY.Route[] = [
  // 个人中心
  { path: "/profile", title: "我的", component: Profile, private: true },
  {
    path: "/personal-info",
    title: "个人信息",
    component: PersonalInfo,
    private: true,
  },
  {
    path: "/my/consult",
    title: "我的咨询",
    component: MyConsult,
    private: true,
  },
  {
    path: "/my/reservation",
    title: "我的预约",
    component: MyReservation,
    private: true,
  },
  {
    path: "/my/doctor",
    title: "我的医生",
    component: MyDoctor,
    private: true,
  },
  {
    path: "/my/diary",
    title: "我的训练",
    component: MyDiary,
    private: true,
  },
  {
    path: "/my/test",
    title: "自测记录",
    component: MyTest,
    private: true,
  },
  {
    path: "/my/community",
    title: "我的社区",
    component: MyCommunity,
    private: true,
  },
  {
    path: "/my/readed",
    title: "我的阅读",
    component: MyRead,
    private: true,
  },
  {
    path: "/my/order",
    title: "我的订单",
    component: MyOrder,
    private: true,
  },
  {
    path: "/my/medicine",
    title: "丁丁好医",
    component: MyMedicine,
    private: true,
  },
];

export { routes };
