import React from "react";
import { Flex } from "antd-mobile";
import AnimationLoading from "../Animation/Loading";
import "./index.scss";

interface LoadingProps {
  text?: string;
}
const Loading: React.FC<LoadingProps> = ({ text }) => {
  return (
    <div className="Component-Loading">
      <Flex justify="center">
        <AnimationLoading />
        <div className="Text">{text || "加载中..."}</div>
      </Flex>
    </div>
  );
};

export default Loading;
