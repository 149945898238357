import wx from "weixin-js-sdk";
import mainApi from "api/main";
import store from "../reduxStore";
import {
  LOGO
} from "common/constant";
import {
  Toast
} from "antd-mobile";
import {
  device
} from "./util";

//公众号唯一标识
const appid = "wxe561d39c5e739695";

// 丁丁好医微信服务号首页地址
const wxAccountPath = `https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzIzODg4MTI3Ng==#wechat_redirect`;

//返回类型，请填写code
const response_type = "code";

//应用授权作用域
//      snsapi_base （不弹出授权页面，直接跳转，只能获取用户openid）
//      snsapi_userinfo （弹出授权页面，可通过openid拿到昵称、性别、所在地。并且， 即使在未关注的情况下，只要用户授权，也能获取其信息 ）
const scope = "snsapi_userinfo";

//重定向后会带上state参数，可以填写a-zA-Z0-9的参数值，最多128字节
const state = "ddinghy";

// 需要用到的接口
const jsApiList = [
  "chooseImage",
  "chooseWXPay",
  "uploadImage",
  "getLocation",
  "previewImage",
  "scanQRCode",
  "onMenuShareAppMessage",
  "updateAppMessageShareData",
  "updateTimelineShareData",
  "onMenuShareTimeline",
  "checkJsApi"
];

// 开放标签接口
const openTagList = [
  'wx-open-launch-app',
  'wx-open-launch-weapp',
  'wx-open-subscribe',
  //'wx-open-audio'
];

/**
 * 微信网页授权
 * @params { string | undefined } defaultRedirectUri 默认重定向页面地址
 */
function wxOauth2(defaultRedirectUri) {
  const redirectUri = encodeURIComponent(defaultRedirectUri || window.location.href);
  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUri}&response_type=${response_type}&scope=${scope}&state=${state}#wechat_redirect`;
}

async function SDKRegister() {
  // 进入程序时进行js-sdk签名
  return new Promise((s, j) => {
    const state = store.getState();
    const flag = state.getIn(["main", "wxSDK"]);
    if(!flag){
      let url = window.location.href;
      mainApi
        .jssdk({
          url
        })
        .then((result) => {
          wx.config({
            debug: false,
            appId: appid,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList,
            openTagList,
          });

          wx.ready(() => {
            console.log('微信注册成功');
            s("微信注册成功！");
          });

          wx.error(function (res) {
            console.log(res)
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            //j(`[WeiXin] JS SDK注册失败！错误信息：${JSON.stringify(res)}`);
          });
        })
        .catch(error => {
          console.log(`[JSSDK Register] status: ${error}`)
          //j(`[JSSDK Register] status: ${error}`);
        })
    }
  });
}

function getWeiXinSDKState() {
  const state = store.getState();
  const flag = state.getIn(["main", "wxSDK"]);
  if (flag) {
    return true;
  } else {
    Toast.info("微信接口调用失败， 请稍后重试");
    SDKRegister();
    return false;
  }
}

function QRCode() {
  return new Promise((resolve, reject) => {
    const flag = getWeiXinSDKState();
    if (flag) {
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          resolve(res);
        }
      });
    } else {
      reject()
    }
  });
}

function customShare(config) {
  if (!device.versions.wx) return;
  loop();

  /**
   * 每隔500毫秒递归调用一次自定义分享，直到JSSDK注册成功为止
   */
  function loop() {

    const state = store.getState();
    const wxSDK = state.getIn(["main", "wxSDK"]);
    if (wxSDK) {
      wx.onMenuShareAppMessage({
        title: config.title, // 分享标题
        desc: config.desc, // 分享描述
        link: getLocationPath(), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: config.imgUrl || LOGO, // 分享图标
        // success: function () {}
      });

      wx.onMenuShareTimeline({
        title: config.title, // 分享标题
        link: getLocationPath(), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: config.imgUrl || LOGO, // 分享图标

      });
    } else {
      setTimeout(() => {
        loop()
      }, 500);
    }
  }
}

function getLocationPath() {
  // let path;
  // let origin = window.location.origin;
  // let hash = window.location.hash;
  // // let path = location.pathname
  // path = origin + "/" + hash;
  // return path;
  return window.location.href;
}

function chooseImage(count) {
  return new Promise((resolve, reject) => {
    wx.chooseImage({
      count: count, // 默认9
      sizeType: ["original", "compressed"], // 可以指定是原图还是压缩图，默认二者都有
      sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
      success: function (res) {
        var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片

        resolve(localIds);
      },
      fail: function (res) {
        reject(res.errMsg);
      }
    });
    // loop()
    // /**
    //  * 每隔500毫秒递归调用一次自定义分享，直到JSSDK注册成功为止
    //  */
    // function loop(){
    //   const flag = getWeiXinSDKState();
    //   if (flag) {
    //     wx.ready(function(){

    //     });
    //   }else{
    //     setTimeout(() => {
    //       loop()
    //     }, 500);
    //   }
    // }
  });
}

/**
 * 图片预览
 * @param {String} current 当前显示的图片url
 * @param {Array} urls 图片url数组
 */
function previewImage(current, urls) {
  const flag = getWeiXinSDKState();
  if (flag) {
    wx.previewImage({
      current: current, // 当前显示图片的http链接
      urls: urls // 需要预览的图片http链接列表
    });
  }
}

function uploadImage(localId) {
  return new Promise((resolve, reject) => {
    wx.uploadImage({
      localId: localId.toString(), // 需要上传的图片的本地ID，由chooseImage接口获得
      isShowProgressTips: 1, // 默认为1，显示进度提示
      success: function (res) {
        var serverId = res.serverId; // 返回图片的服务器端ID
        resolve(serverId);
      }
    });
  });
}
/**
 * 异步上传微信本地图片到微信服务器
 * @param {*} localIds
 */
function syncUploadImages(localIds) {
  return new Promise((resolve, reject) => {
    if (localIds && localIds.length > 0) {
      var serviceIdList = [];

      let localId = localIds.pop();
      upload(localId);

      function upload(localId) {
        wx.uploadImage({ // 一次只能上传一张并且两次上传必须间隔一段时间
          localId: localId.toString(), // 需要上传的图片的本地ID，由chooseImage接口获得
          isShowProgressTips: 1, // 默认为1，显示进度提示
          success: function (res) {
            var serverId = res.serverId; // 返回图片的服务器端ID
            serviceIdList.push(serverId);
            if (localIds.length > 0) {
              setTimeout(() => {
                upload(localIds.pop())
              }, 100);
            } else {
              resolve(serviceIdList);
            }
          }
        });
      }
    } else {
      reject("localId List is null")
    }
  });
}

//微信支付
function chooseWXPay(obj) {
  return new Promise((resolve, reject) => {
    wx.chooseWXPay({
      timestamp: obj.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
      nonceStr: obj.nonceStr, // 支付签名随机串，不长于 32 位
      package: obj.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
      signType: obj.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
      paySign: obj.paySign, // 支付签名
      success: function (res) {
        if (res.errMsg === "chooseWXPay:ok") {
          resolve(res);
        } else {
          reject("支付失败！");
        }
      },
      fail: function (res) {
        reject(res.errMsg);
      }
    });
  });
}

/**
 * 获取当前位置信息
 */
function getLocation() {
  return new Promise((resolve, reject) => {
    try {

      // 百度地图处理
      const {
        BMap
      } = window;
      var geolocation = new BMap.Geolocation();

      // 开启SDK辅助定位
      geolocation.enableSDKLocation();
      geolocation.getCurrentPosition(
        r => {
          resolve(r);
        },
        error => {
          reject(error);
        }
      );
    } catch (error) {
      reject(error)
    }
  })
}

export {
  appid,
  wxAccountPath,
  wxOauth2,
  SDKRegister,
  QRCode,
  customShare,
  getLocationPath,
  chooseImage,
  uploadImage,
  syncUploadImages,
  previewImage,
  chooseWXPay,
  getLocation
};
