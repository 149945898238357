import * as actionTypes from "./constants";

export interface HospitalState {
  use?: boolean;
  skills?: Array<DDHY.Skill>; // 筛选栏的特色技术数据
  regions?: any[]; // 筛选栏的区域列表数据
  filter?: any; // 筛选条件组合
  hospitals?: Array<DDHY.hospitals>;
  hospitalsPage: number;
  hospitalsHasMore: boolean;
  hospitalsRECOMMEND:boolean;
}

const defaultlState: HospitalState = {
  hospitalsPage: 1,
  hospitalsHasMore: true,
  hospitalsRECOMMEND: false,
};


export default (state = defaultlState, action: any): HospitalState => {
  switch (action.type) {
    case actionTypes.SET_USE_STATE:
      return { ...state, use: action.data };
    case actionTypes.SET_HOSPIATLS:
      return { ...state, hospitals: action.data };
    case actionTypes.SET_PAGE:
      return { ...state, hospitalsPage: action.data };
    case actionTypes.SET_HASMORE:
      return { ...state, hospitalsHasMore: action.data };
      case actionTypes.SET_RECOMMEND:
      return { ...state, hospitalsRECOMMEND: action.data };
    case actionTypes.SET_SKILLS:
      return { ...state, skills: action.data };
    case actionTypes.SET_REGIONS:
      return { ...state, regions: action.data };
    case actionTypes.SET_FILTER:
      return { ...state, filter: action.data };
    default:
      return state;
  }
};
