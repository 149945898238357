import aggregationApi from "api/aggregation";
import * as actionTypes from "./constants";
import { AggregationProps, HomeAnnouncementListState, HomeArticleListState, HomePostListState, HomeRecommendListState } from "./reducer";
import { Toast } from "antd-mobile";
import mainApi from "api/main";

// 更新页面数据状态
export const changeUseState = (data: boolean) => ({
  type: actionTypes.SET_USE_STATE,
  data: data,
});

// 更新特色专题列表
export const changeAggregation = (data?: Array<AggregationProps>) => ({
  type: actionTypes.SET_AGGREGATION_DATA,
  data: data,
});

// 更新已选中Tab 索引
export const changeTabIndex = (data: number) => ({
  type: actionTypes.SET_TABBAR_INDEX,
  data: data,
});

// 加载首页基础数据
export const initHome = (callback?: Function) =>{
  return (dispatch: any) => {
      aggregationApi.queryAggregationList().then((result)=>{
        dispatch(changeAggregation(result));
      }).catch((error) => {
        Toast.info(error);
      })
      .finally(() => {
        callback && callback();
      });
  };
};

// 更新推荐数据列表
export const changeRecommendList = (data?: any[]) => ({
  type: actionTypes.SET_RECOMMEND_LIST_DATA,
  data: data,
});
export const changeRecommendPage = (data: number) => ({
  type: actionTypes.SET_RECOMMEND_PAGE_DATA,
  data,
});
export const changeRecommendHasMore = (data: boolean) => ({
  type: actionTypes.SET_RECOMMEND_HAS_MORE_DATA,
  data,
});
export const changeRecommendTimestamp = (data: number) => ({
  type: actionTypes.SET_RECOMMEND_TIMESTAMP_DATA,
  data,
});

export const loadRecommendData = (callback?: Function) => {
  return (dispatch: any, getState: any) => {
    const limit = 10;
    let state: HomeRecommendListState = getState().getIn(["home", "recommendInfo"]);
    const { page, recommendList, timestamp } = state;

    // 防重复时间戳
    let newTs = timestamp;
    if(page === 1 && !timestamp){
      newTs = Math.floor(new Date().getTime() / 1000);
      dispatch(changeRecommendTimestamp(newTs));
    }

    const pms = mainApi.queryHomeContentList({
      type: 'recommend',
      page,
      timestamp: newTs,
    });

    pms.then((result: any) => {
      if (page === 1 || !recommendList) {
        dispatch(changeRecommendList(result));
      } else {
        dispatch(changeRecommendList([...recommendList, ...result]));
      }
      dispatch(changeRecommendHasMore(result.length === limit));

    });
    pms.catch((error: any) => {
      Toast.info(error);
    });
    pms.finally(() => {
      callback && callback();
    });
  };
};


// 更新文章列表数据
export const changeArticleList = (data: any[]) => ({
  type: actionTypes.SET_ARTICLES_LIST_DATA,
  data: data,
});
export const changeArticlePage = (data: number) => ({
  type: actionTypes.SET_ARTICLES_PAGE_DATA,
  data,
});
export const changeArticleHasMore = (data: boolean) => ({
  type: actionTypes.SET_ARTICLES_HAS_MORE_DATA,
  data,
});
export const changeArticleTimestamp = (data: number) => ({
  type: actionTypes.SET_ARTICLES_TIMESTAMP_DATA,
  data,
});

export const loadArticleData = (callback?: Function) => {
  return (dispatch: any, getState: any) => {
    const limit = 10;
    let state: HomeArticleListState = getState().getIn(["home", "articleInfo"]);
    const { page, articleList, timestamp } = state;

    // 防重复时间戳
    let newTs = timestamp;
    if(page === 1 && !timestamp){
      newTs = Math.floor(new Date().getTime() / 1000);
      dispatch(changeArticleTimestamp(newTs));
    }

    const pms = mainApi.queryHomeContentList({
      type: 'article',
      page,
      timestamp: newTs,
    });

    pms.then((result: any) => {
      if (page === 1 || !articleList) {
        dispatch(changeArticleList(result));
      } else {
        dispatch(changeArticleList([...articleList, ...result]));
      }
      dispatch(changeArticleHasMore(result.length === limit));

    });
    pms.catch((error: any) => {
      Toast.info(error);
    });
    pms.finally(() => {
      callback && callback();
    });
  };
};

// 更新帖子列表数据
export const changePostList = (data: any[]) => ({
  type: actionTypes.SET_POSTS_LIST_DATA,
  data: data,
});
export const changePostPage = (data: number) => ({
  type: actionTypes.SET_POSTS_PAGE_DATA,
  data,
});
export const changePostHasMore = (data: boolean) => ({
  type: actionTypes.SET_POSTS_HAS_MORE_DATA,
  data,
});
export const changePostTimestamp = (data: number) => ({
  type: actionTypes.SET_POSTS_TIMESTAMP_DATA,
  data,
});
export const loadPostData = (callback?: Function) => {
  return (dispatch: any, getState: any) => {
    const limit = 10;
    let state: HomePostListState = getState().getIn(["home", "postInfo"]);
    const { page, postList, timestamp } = state;
    // 防重复时间戳
    let newTs = timestamp;
    if(page === 1 && !timestamp){
      newTs = Math.floor(new Date().getTime() / 1000);
      dispatch(changePostTimestamp(newTs));
    }
    const pms = mainApi.queryHomeContentList({
      type: "posts",
      page,
      timestamp: newTs,
    });
    pms.then((result: any) => {
      if (page === 1 || !postList) {
        dispatch(changePostList(result));
      } else {
        dispatch(changePostList([...postList, ...result]));
      }
      dispatch(changePostHasMore(result.length === limit));

    });
    pms.catch((error: any) => {
      Toast.info(error);
    });
    pms.finally(() => {
      callback && callback();
    });
  };
};

// 更新公告列表数据
export const changeAnnouncementList = (data: any[]) => ({
  type: actionTypes.SET_ANNOUNCEMENT_LIST_DATA,
  data: data,
});
export const changeAnnouncementPage = (data: number) => ({
  type: actionTypes.SET_ANNOUNCEMENT_PAGE_DATA,
  data,
});
export const changeAnnouncementHasMore = (data: boolean) => ({
  type: actionTypes.SET_ANNOUNCEMENT_HAS_MORE_DATA,
  data,
});
export const changeAnnouncementTimestamp = (data: number) => ({
  type: actionTypes.SET_ANNOUNCEMENT_TIMESTAMP_DATA,
  data,
});
export const loadAnnouncementData = (callback?: Function) => {
  return (dispatch: any, getState: any) => {
    const limit = 10;
    let state: HomeAnnouncementListState = getState().getIn(["home", "announcementInfo"]);
    const { page, announcementList, timestamp } = state;
    // 防重复时间戳
    let newTs = timestamp;
    if(page === 1 && !timestamp){
      newTs = Math.floor(new Date().getTime() / 1000);
      dispatch(changeAnnouncementTimestamp(newTs));
    }
    const pms = mainApi.queryHomeContentList({
      type: 'topic',
      topic_id: 8,
      page,
      timestamp: newTs,
    });
    pms.then((result: any) => {
      if (page === 1 || !announcementList) {
        dispatch(changeAnnouncementList(result));
      } else {
        dispatch(changeAnnouncementList([...announcementList, ...result]));
      }
      dispatch(changeAnnouncementHasMore(result.length === limit));

    });
    pms.catch((error: any) => {
      Toast.info(error);
    });
    pms.finally(() => {
      callback && callback();
    });
  };
};

