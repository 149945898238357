import DProject from "typings/project";
import * as actionTypes from "./constants";

export interface ProjectState {
  navHeight: number;
  tags?: Array<DProject.Tag>;
  currentTag?: DProject.Tag;
  cates?: Array<DProject.Cate>;
  currentCate?: DProject.Cate;
  city?: string;
  moreTag: boolean;
  projectList?: Array<DProject.Project>;
  page: number;
  hasMore: boolean;
}

const defaultState: ProjectState = {
  navHeight: 0,
  moreTag: false,
  page: 1,
  hasMore: true,
};

export default (
  state: ProjectState = defaultState,
  action: any
): ProjectState => {
  switch (action.type) {
    case actionTypes.SET_NAV_HEIGHT:
      return { ...state, navHeight: action.data };
    case actionTypes.SET_TAGS:
      return { ...state, tags: action.data };
    case actionTypes.SET_CURRENT_TAG:
      return { ...state, currentTag: action.data };
      case actionTypes.SET_CATES:
        return { ...state, cates: action.data };
      case actionTypes.SET_CURRENT_CATE:
        return { ...state, currentCate: action.data };
    case actionTypes.SET_MORE_TAG:
      return { ...state, moreTag: action.data };
    case actionTypes.SET_CITY:
      return { ...state, city: action.data };
    case actionTypes.SET_PAGE:
      return { ...state, page: action.data };
    case actionTypes.SET_HASMORE:
      return { ...state, hasMore: action.data };
    case actionTypes.SET_PROJECT_LIST:
      return { ...state, projectList: action.data };
    default:
      return state;
  }
};
