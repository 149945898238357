import * as actionTypes from "./constants";

export interface MyOrderState {
  currentState?: number;
  orderList?: DDHY.Order[];
  page?: number;
  hasMore?: boolean;
}

const defaultState: MyOrderState = {
  page: 1,
  hasMore: true,
};

export default (state = defaultState, action: any): MyOrderState => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_STATE:
      return { ...state, currentState: action.data };
    case actionTypes.SET_ORDER_LIST:
      return { ...state, orderList: action.data };
    case actionTypes.SET_ORDER_PAGE:
      return { ...state, page: action.data };
    case actionTypes.SET_ORDER_HASMORE:
      return { ...state, hasMore: action.data };

    default:
      return state;
  }
};
