import * as consts from "./constants";
import { Toast } from "antd-mobile";
import doctorApi from "api/doctor";
import { MyDoctorState } from "./reducer";

export const changeDoctorList = (data?: DDHY.Doctor[]) => ({
  type: consts.SET_DOCTOR_LIST,
  data: data,
});
export const changePage = (data: number) => ({
  type: consts.SET_DOCTOR_PAGE,
  data: data,
});
export const changeHasMore = (data: boolean) => ({
  type: consts.SET_DOCTOR_HASMORE,
  data: data,
});
/**
 * 清除state
 *
 * @comopnent FollowedDoctor
 */
export const cleanFollowedDoctor = () => {
  return (dispatch: any, getState: any) => {
    dispatch(changeDoctorList());
    dispatch(changePage(1));
    dispatch(changeHasMore(true));
  };
};
/**
 * 加载我关注的医生数据
 *
 * @component FollowedDoctor
 */
export const loadDoctors = (callback?: Function) => {
  return async (dispatch: any, getState: any) => {
    const limit = 10;

    try {
      const state: MyDoctorState = getState().getIn(["profile", "myDoctor"]);
      const { doctorList, page } = state;
      const result = await doctorApi.getUserFollowedDoctors({
        page,
        limit,
      });
      if (page === 1 || !doctorList) {
        dispatch(changeDoctorList(result.data));
      } else {
        dispatch(changeDoctorList([...doctorList, ...result.data]));
      }
      dispatch(changeHasMore(result.last_page > page));
    } catch (error) {
      Toast.info(error);
    } finally {
      callback && callback();
    }
  };
};
