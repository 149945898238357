import * as consts from "./constants";
import { Toast } from "antd-mobile";
import { ProjectState } from "./reducer";

import projectApi from "api/project";

export const changeNavHeight = (data: number) => ({
  type: consts.SET_NAV_HEIGHT,
  data: data,
});
export const changeTags = (data?: DProject.Tag[]) => ({
  type: consts.SET_TAGS,
  data: data,
});
export const changeCurrentTag = (data?: DProject.Tag) => ({
  type: consts.SET_CURRENT_TAG,
  data: data,
});
export const changeCates = (data?: DProject.Cate[]) => ({
  type: consts.SET_CATES,
  data: data,
});
export const changeCurrentCate = (data?: DProject.Cate) => ({
  type: consts.SET_CURRENT_CATE,
  data: data,
});
export const changeMoreTag = (data: boolean) => ({
  type: consts.SET_MORE_TAG,
  data: data,
});
export const changeCity = (data?: string) => ({
  type: consts.SET_CITY,
  data: data,
});
export const changePage = (data: number) => ({
  type: consts.SET_PAGE,
  data: data,
});
export const changeHasMore = (data: boolean) => ({
  type: consts.SET_HASMORE,
  data: data,
});
export const changeProjectList = (data?: DProject.Project[]) => ({
  type: consts.SET_PROJECT_LIST,
  data: data,
});
export const cleanHome = () => {
  return (dispatch: any) => {
    dispatch(changeTags());
    dispatch(changeCurrentTag());
    dispatch(changeCates());
    dispatch(changeCurrentCate());
    dispatch(changeMoreTag(false));
    dispatch(changeCity());
    dispatch(changePage(1));
    dispatch(changeProjectList());
  };
};

/**
 * ProjectHome 初始化
 * 1. 获取标签列表
 * 2. 获取项目列表
 *
 * @component ProjectHome
 */
export const init = (callback: Function) => {
  return (dispatch: any) => {

    // 获取标签列表
    const getCateList = () => {
      return new Promise((resolve, reject) => {
        const pms = projectApi.getCateWithProject();
        pms.then((result: any) => {
          const tags: any[] = result;
          let projectCates: DProject.Cate[] = [];
          for (let i = 0; i < tags.length; i++) {
            const item = tags[i];
            if (item) {
              projectCates.push({
                cate_id: item.item_cate_id,
                cate_name: item.item_cate_name,
                cate_image: item.item_cate_image,
                cate_sort: item.item_cate_sort
              });
            }
          }
          resolve(projectCates);
        });
        pms.catch((error) => {
          reject(error);
        });
      });
    };

    getCateList()
      .then((cates: any) => {
        dispatch(changeCates(cates));
        if (cates.length > 0) {
          dispatch(changeCurrentCate(cates[cates.length -1]));
        }
      })
      .catch((error) => {
        Toast.info(error);
      })
      .finally(() => {
        callback();
      });
  };
};

/**
 * 加载项目列表数据
 *
 * @component ProjectHome
 */
export const loadProject = (callback?: Function) => {
  return async (dispatch: any, getState: any) => {
    const limit = 10;
    try {
      const state: ProjectState = getState().getIn(["project"]);
      const { page, projectList, city, currentTag, currentCate } = state;
      let params: any = { page, limit };
      if (currentTag && currentTag.tag_id) {
        params.tag_id = currentTag.tag_id;
      }
      if (currentCate && currentCate.cate_id) {
        params.item_cate_id = currentCate.cate_id;
      }
      if (city) {
        params.city_name = city;
      }
      const result = await projectApi.query(params);
      let data = result;
      if (!data) data = [];
      if (page === 1 || !projectList) {
        dispatch(changeProjectList(data));
      } else {
        dispatch(changeProjectList([...projectList, ...data]));
      }
      dispatch(changeHasMore(data.length === limit));
    } catch (error) {
      Toast.fail(error, 2);
    } finally {
      callback && callback();
    }
  };
};

/**
 * 切换当前选择标签
 *
 * @param tag
 */
export const handleChangeCurrentTag = (tag: DProject.Tag) => {
  return (dispatch: any) => {
    dispatch(changeCurrentTag(tag));
    dispatch(changePage(1));
    dispatch(changeProjectList());
    dispatch(loadProject());
  };
};

/**
 * 切换当前选择分类
 *
 * @param cate
 */
 export const handleChangeCurrentCate = (cate: DProject.Cate) => {
  return (dispatch: any) => {
    dispatch(changeCurrentCate(cate));
    dispatch(changePage(1));
    dispatch(changeProjectList());
    dispatch(loadProject());
  };
};
