import * as actionTypes from "./constants";

export interface ArticleState {
  articleDetails: any;
  comments?: Array<any>;
  page: number;
  hasMore: boolean;
}

const defaultState: ArticleState = {
  articleDetails: undefined,
  comments: [],
  page: 1,
  hasMore: false,
};

export default (state = defaultState, action: any) => {
  switch (action.type) {
    // Article
    case actionTypes.SET_DETAILS:
      return { ...state, articleDetails: action.data };
    // 文章评论
    case actionTypes.SET_COMMENTS:
      return { ...state, comments: action.data };
    case actionTypes.SET_PAGE:
      return { ...state, page: action.data };
    case actionTypes.SET_HASMORE:
      return { ...state, hasMore: action.data };
    default:
      return state;
  }
};
