import React from "react";
import "./index.scss";

interface IconProps {
  size?: string | number;
  color?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const Icon: React.FC<IconProps> = ({ size, color, style, children }) => {
  return (
    <svg
      className="icon"
      style={{
        fontSize: size + "px",
        color: color,
        ...style
      }}
      aria-hidden="true"
    >
      <use xlinkHref={`#${children}`}></use>
    </svg>
  );
};

export default Icon;
