import { lazy } from "react";

const ProjectHome = lazy(() => import("./ProjectHome"));
const ProjectDetails = lazy(() => import("./ProjectDetails"));

const routes: DDHY.Route[] = [
  {
    path: "/project/:id",
    title: "项目详情",
    component: ProjectDetails,
    private: true,
  },
  { path: "/projects", title: "服务项目", component: ProjectHome },
];

export { routes };
