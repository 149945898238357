import { lazy } from "react";

const Diagnostic = lazy(() => import("./Diagnostic"));

const routes: DDHY.Route[] = [
  {
    path: "/agreement/diagnostic",
    title: "用户隐私协议",
    component: Diagnostic,
  },
];

export { routes };
